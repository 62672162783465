import { Button, Toggle } from "@patientmpower/spiro";
import { useEffect, useState } from "react";

import { ISurvey } from "../../../../../@types/Surveys";
import { IWardOption } from "../../../../../@types/User";
import { PageTitle } from "../../../../../layout/components/PageTitle";
import { surveysService } from "../../../../../services/surveysService";
import { CancelButton } from "../../../../Settings/components/SecurityModal/SecurityModal.styles";
import { HandleQuestionFormContainer } from "../../../CreateSurvey/Components/SurveyBuilder/Components/AddQuestionModal/AddQuestionModal.styles";
import { AssignHospitalModalContainer } from "./AssignToHospital.styles";
import { HospitalListTable } from "./HospitalListTable/HospitalListTable";

interface IAssignToHospitalModalProps {
  onClose(): void;
  survey: ISurvey;
  refetch(): void;
}

export function AssignToHospitalModal({
  onClose,
  survey,
  refetch,
}: IAssignToHospitalModalProps) {
  const [hospitalsToAssignList, setHospitalsToAssign] = useState<number[]>([]);
  const [hospitalObjectList, setHospitalObjectList] = useState<IWardOption[]>();
  const [hosptalsLoading, setHospitalsLoading] = useState<boolean>(true);
  const [surveyId, setSurveyId] = useState<string>("");
  const [tableContent, setTableContent] = useState<any>();

  const parseDataToHospitalTable = (data: any) => {
    const formatttedDataTable: any[] = [];
    data.map((hospital: IWardOption, index: number) =>
      formatttedDataTable.push({
        hospitalName: hospital.hospitalName,
        hospitalId: hospital.hospitalId,
        action: (
          <div>
            <Toggle
              defaultChecked={hospitalsToAssignList.includes(
                hospital.hospitalId
              )}
              onCheckedChange={(isChecked) => {
                const updatedHospitalsToAssign = [...hospitalsToAssignList];
                if (isChecked) {
                  updatedHospitalsToAssign.push(data[index].hospitalId);
                } else {
                  const indexToRemove = updatedHospitalsToAssign.findIndex(
                    (element) => element === hospital.hospitalId
                  );
                  updatedHospitalsToAssign.splice(indexToRemove, 1);
                }
                setHospitalsToAssign(updatedHospitalsToAssign);
              }}
            />
          </div>
        ),
      })
    );
    return formatttedDataTable;
  };
  useEffect(() => {
    (async () => {
      const { data } = await surveysService.getAllHospitals();
      setHospitalObjectList(data);

      if (survey.hospitalIds) setHospitalsToAssign(survey.hospitalIds);
      if (survey.id) setSurveyId(survey.id);

      setHospitalsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (hospitalObjectList && hospitalsToAssignList) {
      setTableContent(parseDataToHospitalTable(hospitalObjectList));
    }
  }, [hospitalObjectList, hospitalsToAssignList]);

  const handleHospitalAssignment = async () => {
    await surveysService.assignSurveyToHospital(
      surveyId,
      hospitalsToAssignList
    );
    refetch();
  };

  return (
    <AssignHospitalModalContainer>
      <PageTitle>Assign to a hospital...</PageTitle>
      <HospitalListTable isLoading={hosptalsLoading} content={tableContent} />
      <HandleQuestionFormContainer style={{ marginBottom: "0px" }}>
        <Button
          label="Save"
          type="button"
          onClick={() => {
            handleHospitalAssignment();
            onClose();
          }}
        />

        <CancelButton
          style={{
            border: "2px solid rgba(248, 248, 248, 0.8)",
            backgroundColor: "rgba(248, 248, 248, 0.8)",
          }}
          onClick={onClose}
        >
          Cancel
        </CancelButton>
      </HandleQuestionFormContainer>
    </AssignHospitalModalContainer>
  );
}
