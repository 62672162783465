import { AxiosResponse } from "axios";

import {
  IAppointment,
  ICreateAppointmentRequest,
  ICreateAppointmentResponse,
  IGetAppointmentsListRequest,
} from "../@types/Appointment";
import { api } from "./api";

export const appointmentsService = {
  async getUserAppointments(
    request: IGetAppointmentsListRequest
  ): Promise<AxiosResponse<IAppointment[]>> {
    const url = "/Shared/Appointments/GetAppointmentsList";

    const serverResponse = await api.post(url, request);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async createAppointment(
    request: ICreateAppointmentRequest
  ): Promise<AxiosResponse<ICreateAppointmentResponse>> {
    const url = "/Shared/Appointments";

    const serverResponse = await api.post(url, request);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async deleteAppointment(
    appointmentId: string
  ): Promise<AxiosResponse<ICreateAppointmentResponse>> {
    const url = `/Shared/Appointments/Delete/${appointmentId}`;

    const serverResponse = await api.put(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
