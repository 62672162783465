import { Button, IOption } from "@patientmpower/spiro";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import {
  IAddArticle,
  IArticle,
  IArticleImage,
} from "../../../../@types/Articles";
import { Notification } from "../../../../@types/Notification";
import { articlesSerivce } from "../../../../services/articlesService";
import { wardsService } from "../../../../services/wardsService";
import { getLongFormattedDate } from "../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  ArticleBuilderContainer,
  BodyContainer,
  CancelButton,
  ButtonsContainer,
  Header,
  ScrollableContainer,
  SectionButton,
  SectionsContainer,
  SectionsHeader,
  Underline,
  whiteButton,
  purpleButton,
} from "./ArticleBuilder.styles";
import { ContentSection } from "./components/ContentSection";
import { ImageLibrary } from "./components/ImageLibrary";
import { PreviewSection } from "./components/PreviewSection";
import { SettingsSection } from "./components/SettingsSection";

interface IArticleBuilderProps {
  content?: IArticle;
  isAddArticle: boolean;
  hospitalGrouId?: number;
  onClose: (notification?: Notification, refetchTable?: boolean) => void;
}

export function ArticleBuilder({
  content,
  isAddArticle,
  onClose,
  hospitalGrouId,
}: IArticleBuilderProps) {
  const [selectedSection, setSelectedSection] = useState<
    "content" | "settings" | "preview"
  >("content");
  const [isImageLibraryOpen, setIsImageLibraryOpen] = useState(false);
  const [tagOptions, setTagOptions] = useState<IOption[]>([]);
  const [wardOptions, setWardsOptions] = useState<IOption[]>([]);
  const [images, setImages] = useState<IArticleImage[]>([]);
  const [whiteButtonIsLoading, setWhiteButtonIsLoading] = useState(false);
  const [purpleButtonIsLoading, setPurpleButtonIsLoading] = useState(false);

  const [hasArticleLink, setHasArticleLink] = useState(false);

  let isPublished = false;

  const handleOnSubmitForm = async (values: IArticle) => {
    if (values.published) {
      setWhiteButtonIsLoading(false);
    } else {
      setPurpleButtonIsLoading(false);
    }

    const request: IAddArticle = {
      title: values.title,
      description: values.description,
      imageName: values.imageName,
      published: values.published,
      articleUrl: values.articleUrl,
      articleText: values.articleText,
      wards: values.wards?.map((ward) => {
        return parseInt(ward, 10);
      }),
      tags: values.tags,
    };

    const handleToastSuccessMessage = () => {
      if (isAddArticle || !content?.published) {
        return `Article has been ${request.published ? "published" : "saved"}.`;
      }

      return `Article has been ${
        request.published ? "published" : "unpublished"
      }.`;
    };

    const handleToastErrorMessage = () => {
      if (request.published) {
        return "There was an issue publishing your article. Please try again later.";
      }

      if (isAddArticle) {
        return "There was an issue saving your changes. Please keep note of your changes and try again later.";
      }

      return "There was an issue unpublishing your article. Please try again later.";
    };

    if (isAddArticle) {
      const result = await articlesSerivce.addArticle(request);

      setPurpleButtonIsLoading(false);
      setWhiteButtonIsLoading(false);

      if (result.status >= 200 && result.status < 300) {
        onClose(
          {
            show: true,
            message: handleToastSuccessMessage(),
            type: "success",
            width: "max-content",
          },
          true
        );
        return;
      }

      onClose(
        {
          show: true,
          message: handleToastErrorMessage(),
          type: "error",
          width: "400px",
          rightMargin: "400px !important",
        },
        false
      );
      return;
    }

    if (content?.id !== undefined) {
      const result = await articlesSerivce.updateArticle(content?.id, request);

      setPurpleButtonIsLoading(false);
      setWhiteButtonIsLoading(false);

      if (result.status >= 200 && result.status < 300) {
        onClose(
          {
            show: true,
            message: handleToastSuccessMessage(),
            type: "success",
            width: "max-content",
          },
          true
        );
        return;
      }
      onClose(
        {
          show: true,
          message: handleToastErrorMessage(),
          type: "error",
          width: "400px",
          rightMargin: "400px !important",
        },
        false
      );
    }
  };

  const formValidationSchema = yup.object().shape({
    title: yup.string().max(50).required("Title is required."),
    description: yup.string().max(90),
    imageName: yup.string().test("required", "Image is required.", (val) => {
      if (isPublished) {
        if (val && val.length > 0) {
          return true;
        }

        return false;
      }

      return true;
    }),
    wards: yup.array().min(1, "Ward is required."),
    articleText: yup.string().test("required", "Text is required.", (val) => {
      if (isPublished) {
        if ((val && val.length > 0) || hasArticleLink) {
          return true;
        }

        return false;
      }

      return true;
    }),
    articleUrl: yup
      .string()
      .test("valid", "Please enter a valid url.", (val) => {
        if (val === undefined) return true;

        const regex = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
        const isValid = new RegExp(regex).test(val);

        if (!isValid) {
          return false;
        }

        return true;
      }),
  });

  const formik = useFormik<IArticle>({
    initialValues: {
      title: "",
      description: "",
      imageName: undefined,
      published: false,
      articleText: "",
      articleUrl: undefined,
      tags: [] as string[],
      wards: [] as string[],
      numberOfReads: 0,
      createdBy: undefined,
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  useEffect(() => {
    setHasArticleLink(formik.values.articleUrl !== undefined);
  }, [formik.values.articleUrl]);

  useEffect(() => {
    if (hospitalGrouId) {
      articlesSerivce.getHospitalGroupTags(hospitalGrouId).then((response) => {
        const options: IOption[] = response.data.map((tag) => {
          return { label: tag.tag, value: tag.id, key: tag.id };
        });

        setTagOptions(options);
      });

      wardsService.getHospitalGroupWards(hospitalGrouId).then((response) => {
        const options: IOption[] = response.data.map((ward) => {
          return {
            label: ward.hospitalName,
            value: ward.hospitalId.toString(),
            key: uuidv4(),
          };
        });

        setWardsOptions(options);
      });
    } else {
      articlesSerivce.getHospitalTags().then((response) => {
        const options: IOption[] = response.data.map((tag) => {
          return { label: tag.tag, value: tag.id, key: tag.id };
        });

        setTagOptions(options);
      });

      wardsService.getAvailableWards().then((response) => {
        const options: IOption[] = response.data.map((ward) => {
          return {
            label: ward.hospitalName,
            value: ward.hospitalId.toString(),
            key: uuidv4(),
          };
        });

        setWardsOptions(options);
      });
    }

    articlesSerivce.getImageLibrary().then((response) => {
      setImages(response.data);
    });
  }, [hospitalGrouId]);

  useEffect(() => {
    if (content) {
      formik.setValues({
        title: content.title,
        description: content.description,
        imageName: content.imageName,
        published: content.published,
        articleText: content.articleText,
        articleUrl: content.articleUrl,
        numberOfReads: content.numberOfReads,
        createdBy: content.createdBy,
        lastEdited: content.updated ?? content.created,
        tags: content.tags ?? [],
        wards:
          content.wards?.map((wardId) => {
            return wardId.toString();
          }) ?? [],
      });
    }
  }, [content]);

  window.onpopstate = (e) => {
    e.preventDefault();

    window.history.forward();
    onClose();
  };

  const handleImageLibraryOnClose = (selectedImage?: string) => {
    formik.setFieldValue("imageName", selectedImage);
    setIsImageLibraryOpen(false);
  };

  const handleCancel = () => {
    mixpanelActions.track("UserAction: Cancel");
    onClose();
  };

  const handleFormSubmit = (published: boolean) => {
    isPublished = published;
    formik.setFieldValue("published", published);
    formik.submitForm();
  };

  if (isImageLibraryOpen) {
    return <ImageLibrary images={images} onClose={handleImageLibraryOnClose} />;
  }

  return (
    <ArticleBuilderContainer>
      <Header>
        {!isAddArticle ? (
          <p>
            * Last edited{" "}
            {formik.values.lastEdited !== undefined
              ? getLongFormattedDate(formik.values.lastEdited)
              : "-"}
          </p>
        ) : null}

        <ButtonsContainer>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          <Button
            label={content?.published ? "Unpublish" : "Save"}
            type="button"
            onClick={() => {
              if (content?.published) {
                mixpanelActions.track("UserAction: Unpublish");
              } else {
                mixpanelActions.track("UserAction: Save");
              }
              handleFormSubmit(false);
            }}
            className={purpleButton()}
            isLoading={purpleButtonIsLoading}
            disabled={whiteButtonIsLoading}
          />

          <Button
            label={content?.published ? "Publish changes" : "Publish"}
            type="button"
            onClick={() => {
              mixpanelActions.track("UserAction: Publish");
              handleFormSubmit(true);
            }}
            className={whiteButton()}
            isLoading={whiteButtonIsLoading}
            disabled={purpleButtonIsLoading}
          />
        </ButtonsContainer>
      </Header>

      <SectionsHeader>
        <SectionsContainer>
          <SectionButton
            onClick={() => {
              mixpanelActions.track(
                `UserAction: ContentTab${
                  content?.title !== undefined ? `[${content.title}]` : ""
                }`
              );
              setSelectedSection("content");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                mixpanelActions.track(
                  `UserAction: ContentTab${
                    content?.title !== undefined ? `[${content.title}]` : ""
                  }`
                );
                setSelectedSection("content");
              }
            }}
            tabIndex={0}
          >
            <p>Content</p>
            <Underline isActive={selectedSection === "content"} />
          </SectionButton>
          <SectionButton
            onClick={() => {
              mixpanelActions.track("UserAction: SettingsTab");
              setSelectedSection("settings");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                mixpanelActions.track("UserAction: SettingsTab");
                setSelectedSection("settings");
              }
            }}
            tabIndex={0}
          >
            <p>Settings</p>
            <Underline isActive={selectedSection === "settings"} />
          </SectionButton>
          <SectionButton
            onClick={() => {
              mixpanelActions.track("UserAction: PreviewTab");
              setSelectedSection("preview");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                mixpanelActions.track("UserAction: PreviewTab");
                setSelectedSection("preview");
              }
            }}
            tabIndex={0}
          >
            <p>Preview</p>
            <Underline isActive={selectedSection === "preview"} />
          </SectionButton>
        </SectionsContainer>
      </SectionsHeader>

      <ScrollableContainer>
        <BodyContainer>
          {selectedSection === "content" ? (
            <ContentSection
              formik={formik}
              tagOptions={tagOptions}
              wardOptions={wardOptions}
              openImageLibrary={() => setIsImageLibraryOpen(true)}
            />
          ) : null}
          {selectedSection === "settings" ? (
            <SettingsSection formik={formik} wardOptions={wardOptions} />
          ) : null}
          {selectedSection === "preview" ? (
            <PreviewSection formik={formik} images={images} />
          ) : null}
        </BodyContainer>
      </ScrollableContainer>
    </ArticleBuilderContainer>
  );
}
