import { createColumn } from "@patientmpower/spiro";

export const manageStaffTableColumns = [
  createColumn({
    keyName: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 180,
  }),
  createColumn({
    keyName: "email",
    header: () => "Email",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "isAdmin",
    header: () => "Admin",
    cell: (info) => info.renderValue(),
    size: 60,
  }),
  createColumn({
    keyName: "conditionTypes",
    header: () => "Condition types",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "isActive",
    header: () => "Active",
    cell: (info) => info.renderValue(),
    size: 60,
  }),
  createColumn({
    keyName: "addedBy",
    header: () => "Added by",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "wards",
    header: () => "Wards",
    cell: (info) => info.renderValue(),
    size: 60,
  }),
  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 26,
  }),
];
