import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  IPatientJournals,
  PatientJournalsTableContent,
} from "../../@types/Patient";
import { journalService } from "../../services/journalService";
import { getLongFormattedDate } from "../../utils/dateFormatter";

dayjs.extend(customParseFormat);

export function textTruncate(notesString: string) {
  if (!notesString) {
    return "No text associated with this entry";
  }
  const length = 90;
  const ending = "...";

  if (notesString && notesString.length > length) {
    return notesString.substring(0, length - ending.length) + ending;
  }
  return notesString;
}

export function addDash(tagsString: string) {
  if (!tagsString) {
    return "-";
  }
  return tagsString;
}

export function getPatientJournalsData(patientId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-journals-table-${patientId}`, patientId],
    async () => {
      if (!patientId) return {} as IPatientJournals;

      const { data } = await journalService.getJournals(patientId);
      return data as IPatientJournals;
    }
  );
  const patientJournals = data as IPatientJournals;

  return {
    patientJournalTable: patientJournals?.journals?.map((journalData) => {
      const date = getLongFormattedDate(journalData.date);
      return {
        ...journalData,
        tags: addDash(journalData.tags),
        notes: textTruncate(journalData.notes),
        date,
      } as PatientJournalsTableContent;
    }),
    journalsMaxDate: patientJournals?.maxDate,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function getJournalData(journalId: number) {
  const { data, isFetching, isError, refetch, isRefetching, remove } = useQuery(
    [`journal-${journalId}`, journalId],
    async () => {
      if (!journalId) return {} as PatientJournalsTableContent;

      const { data } = await journalService.getJournalById(journalId);

      return data as PatientJournalsTableContent;
    }
  );

  const patientJournalData = data as PatientJournalsTableContent;

  return {
    patientJournalData,
    isFetching,
    isError,
    refetch,
    isRefetching,
    remove,
  };
}
