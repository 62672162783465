import { styled } from "@patientmpower/spiro";

// Components

export const HospitalOptionsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
});

export const HospitalOptionsRow = styled("div", {
  display: "flex",
  marginBottom: "10px",
  justifyContent: "flex-start",
  alignItems: "center",

  p: {
    marginLeft: "10px",
  },
});

export const AssignHospitalModalContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "24px 26px",
});
