import { css, styled } from "@patientmpower/spiro";

export const ButtonContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "12px",

  padding: "32px 0 0 0",
});

export const ContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "flex-start",

  height: "100px",
});

export const confirmModalCss = css({
  fontFamily: "$openSans !important",
  animationDuration: "0s !important",

  ".ant-modal-content": {
    animation: "none !important",
    animationDuration: "0s !important",
    opacity: "1 !important",
    padding: "$12 $14 !important",
  },

  ".ant-modal-title": {
    fontSize: "$text-xl",
    color: "$black",
    fontWeight: "500",
    marginLeft: "5px",
  },

  ".ant-modal-footer": {
    display: "flex",
    gap: "12px",
  },
});
