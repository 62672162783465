import { createContext, ReactNode, useMemo, useState } from "react";

type ArrowsListennerHandlerData = {
  enablePatientListArrowNav: () => void;
  disablePatientListArrowNav: () => void;
  isPatientListArrowNavEnabled: boolean;
};

type ArrowsListennerHandlerProps = {
  children: ReactNode;
};

export const ArrowsListennerHandler = createContext(
  {} as ArrowsListennerHandlerData
);

export function ArrowsListennerHandlerProvider({
  children,
}: ArrowsListennerHandlerProps) {
  const [isPatientListArrowNavEnabled, setIsPatientListArrowNavEnabled] =
    useState(false);

  function enablePatientListArrowNav() {
    setIsPatientListArrowNavEnabled(true);
  }

  function disablePatientListArrowNav() {
    setIsPatientListArrowNavEnabled(false);
  }
  const ArrowsListennerHandlerValues = useMemo(() => {
    return {
      enablePatientListArrowNav,
      disablePatientListArrowNav,
      isPatientListArrowNavEnabled,
    };
  }, [isPatientListArrowNavEnabled]);

  return (
    <ArrowsListennerHandler.Provider value={ArrowsListennerHandlerValues}>
      {children}
    </ArrowsListennerHandler.Provider>
  );
}
