import { Button, Checkbox } from "@patientmpower/spiro";
import { useEffect, useState } from "react";

import { Notification } from "../../../../@types/Notification";
import { PatientListConfig } from "../../../../@types/Patient";
import { IUpdatePortalPreferences } from "../../../../@types/User";
import { userService } from "../../../../services/userService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  Subtitle,
  Title,
} from "../../../PatientPage/components/PatientLateralMenu/components/ConfirmChangesModal.styles";
import { CancelButton } from "../../../Settings/components/SecurityModal/SecurityModal.styles";
import { SubmitContainer } from "../AddPatientModal/AddPatientModal.styles";
import { ColumnTitle, Row, Container } from "./PatientListConfigModal.styles";

type PatientListConfigProps = {
  columnsConfig: PatientListConfig;
  onClose(
    listColumnConfig?: PatientListConfig,
    notification?: Notification,
    refreshTable?: boolean
  ): void;
};

export function PatientListConfigModal({
  columnsConfig,
  onClose,
}: PatientListConfigProps) {
  const [confirmChanges, setConfirmChanges] = useState(false);
  const [listColumnConfig, setListColumnConfig] =
    useState<PatientListConfig>(columnsConfig);

  useEffect(() => {
    mixpanelActions.track("Visited Screen: PatientListConfiguration");
  }, []);

  const handleValueChange = (value: boolean, propertyName: string) => {
    mixpanelActions.track(`User Action: Checkbox ${propertyName}`);

    const temporaryList = { ...listColumnConfig } as any;
    temporaryList[propertyName] = value;

    setListColumnConfig(temporaryList);
  };

  const handleOnConfirm = async () => {
    mixpanelActions.track("User Action: ConfigurePatientListConfirm");
    if (confirmChanges) {
      const tableConfigJson = JSON.stringify(listColumnConfig);

      const portalPreferences: IUpdatePortalPreferences = {
        patientListConfig: tableConfigJson,
      };

      const result = await userService.updatePatientListConfig(
        portalPreferences
      );

      if (result.status >= 200 && result.status < 300) {
        onClose(
          listColumnConfig,
          {
            show: true,
            message: "Configuration successfully saved",
            type: "success",
            width: "max-content",
          },
          true
        );
        return;
      }

      onClose(
        undefined,
        {
          show: true,
          message: "Sorry, something went wrong. Please try again",
          type: "error",
          width: "max-content",
        },
        true
      );
    }

    setConfirmChanges(true);
  };

  return (
    <Container>
      {!confirmChanges ? (
        <>
          <Row style={{ marginBottom: "26px" }}>
            <ColumnTitle>Column</ColumnTitle>
            <ColumnTitle>Show</ColumnTitle>
          </Row>

          <Row disabled>
            <p>Name</p>
            <Checkbox
              checked={listColumnConfig.name}
              onChange={() => {}}
              label=""
              labelPosition="before"
              disabled
            />
          </Row>

          <Row disabled>
            <p>Hospital ID</p>
            <Checkbox
              checked={listColumnConfig.hospitalId}
              onChange={() => {}}
              label=""
              labelPosition="before"
              disabled
            />
          </Row>

          <Row>
            <p>pMp ID</p>
            <Checkbox
              checked={listColumnConfig.pmpId}
              onChange={(isChecked) => handleValueChange(isChecked, "pmpId")}
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>Alerts</p>
            <Checkbox
              checked={listColumnConfig.alerts}
              onChange={(isChecked) => handleValueChange(isChecked, "alerts")}
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>Sign up date</p>
            <Checkbox
              checked={listColumnConfig.signUpDate}
              onChange={(isChecked) =>
                handleValueChange(isChecked, "signUpDate")
              }
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>Last used</p>
            <Checkbox
              checked={listColumnConfig.lastUsed}
              onChange={(isChecked) => handleValueChange(isChecked, "lastUsed")}
              label=""
              labelPosition="before"
            />
          </Row>

          <Row>
            <p>Last reviewed by </p>
            <Checkbox
              checked={listColumnConfig.lastReviewedBy}
              onChange={(isChecked) =>
                handleValueChange(isChecked, "lastReviewedBy")
              }
              label=""
              labelPosition="before"
            />
          </Row>

          {/* <Row>
        <p>Ward</p>
        <Checkbox
          checked={listColumnConfig.ward}
          onChange={(isChecked) => handleValueChange(isChecked, "ward")}
          label=""
          labelPosition="before"
        />
      </Row> */
          /* <Row>
        <p>Ox. Sat.</p>
        <Checkbox
          checked={listColumnConfig.oxSat}
          onChange={(isChecked) => handleValueChange(isChecked, "oxSat")}
          label=""
          labelPosition="before"
        />
      </Row> */}

          {listColumnConfig.surveyResults !== undefined ? (
            <Row>
              <p>Survey result (Beaumont)</p>
              <Checkbox
                checked={listColumnConfig.surveyResults}
                onChange={(isChecked) =>
                  handleValueChange(isChecked, "surveyResults")
                }
                label=""
                labelPosition="before"
              />
            </Row>
          ) : null}
        </>
      ) : (
        <>
          <Title>Confirm changes</Title>
          <Subtitle larger style={{ marginBottom: "-30px", fontWeight: "400" }}>
            These configuration changes are saved to only your profile. To
            change for all staff members, please contact your admin.
          </Subtitle>
        </>
      )}

      <SubmitContainer style={{ margin: "49px 0 8px 3px" }}>
        <Button
          label="Confirm"
          type="button"
          onClick={handleOnConfirm}
          isLoading={false}
        />

        <CancelButton
          onClick={() => {
            mixpanelActions.track("User Action: ConfigurePatientListCancel");
            onClose();
          }}
          style={{ marginLeft: "-34px" }}
        >
          Cancel
        </CancelButton>
      </SubmitContainer>
    </Container>
  );
}
