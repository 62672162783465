import { Button, RichTextEditor } from "@patientmpower/spiro";
import { useEffect, useState } from "react";

import { getNoteData, cleanHTML } from "../../../../../../hooks/queries/notes";
import { noteService } from "../../../../../../services/noteService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  CancelButton,
  Container,
  SubmitContainer,
  TextAreaContainer,
  Title,
} from "./EditNoteModal.styles";

type EditNoteModalProps = {
  noteId: number;
  setNotification: (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => void;
  onClose: () => void;
};

export function EditNoteModal({
  noteId,
  setNotification,
  onClose,
}: EditNoteModalProps) {
  const [noteContent, setNoteContent] = useState("");

  const { patientNoteData, isFetching, isError, refetch, remove } =
    getNoteData(noteId);

  useEffect(() => {
    if (patientNoteData && !isFetching) {
      setNoteContent(patientNoteData.notes);
    }
  }, [patientNoteData, isFetching]);

  const handleInputChange = (value: any) => {
    setNoteContent(value);
  };

  const handleSaveEditClick = async () => {
    const cleanNoteContent: string = await cleanHTML(noteContent);
    mixpanelActions.track("User Action: Save Edited Note");

    noteService
      .editNote(noteId, cleanNoteContent)
      .then(async (editedSuccessfully) => {
        const { data } = editedSuccessfully;
        // todo display the message from the backend
        if (data) {
          setNotification(true, "Note successfully edited", "success");
        } else {
          setNotification(true, "Error editing note", "error");
        }
        remove(); // Remove the entry from the cache for the next time the modal shows
        onClose();
      });
  };

  const handleDoneClick = () => {
    onClose();
  };

  const refreshData = () => {
    refetch();
  };

  if (isError) {
    return (
      <Container>
        <p>
          Error getting note data, please{" "}
          <button type="button" onClick={refreshData}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </Container>
    );
  }

  if (isFetching) {
    return (
      <Container>
        <Title>Edit Note</Title>
        <TextAreaContainer>
          <div style={{ height: "360px" }}> </div>
        </TextAreaContainer>
        <SubmitContainer>
          <Button label="Save" type="button" onClick={handleSaveEditClick} />
          <CancelButton onClick={handleDoneClick}>Cancel</CancelButton>
        </SubmitContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Edit Note</Title>
      <TextAreaContainer>
        <RichTextEditor
          value={noteContent}
          placeholderTxt="Enter your note here..."
          height={360}
          onValueChange={(value) => handleInputChange(value)}
        />
      </TextAreaContainer>
      <SubmitContainer>
        <Button label="Save" type="button" onClick={handleSaveEditClick} />
        <CancelButton onClick={handleDoneClick}>Cancel</CancelButton>
      </SubmitContainer>
    </Container>
  );
}
