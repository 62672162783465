/* eslint-disable no-debugger */
import { AxiosResponse } from "axios";

import { IPaginatedResponse } from "../@types/PaginatedResponse";
import { api } from "./api";

export const journalService = {
  async getJournals(patientId: number): Promise<AxiosResponse> {
    const url = "/Portal/Journals";

    const urlParamsParsed = new URLSearchParams();

    urlParamsParsed.append("patientId", patientId.toString());

    const journalsResponse = await api.get<IPaginatedResponse<any>>(url, {
      params: urlParamsParsed,
    });
    return journalsResponse;
  },

  async getJournalById(id: number): Promise<AxiosResponse> {
    const url = `/Portal/Journals/${id}`;
    const journal = await api.get(url);
    return journal;
  },
};
