export interface IValueLabel {
  value: string;
  label: string;
}

export const sexOptions: Array<IValueLabel> = [
  { value: "m", label: "Male" },
  { value: "f", label: "Female" },
];

export const ethnicityOptions: Array<IValueLabel> = [
  { value: "white", label: "White" },
  { value: "black", label: "Black" },
  { value: "asian", label: "Asian" },
  { value: "other", label: "Other" },
];
