import { styled } from "@patientmpower/spiro";

import { expandedPatientLateralMenuCardContainerWidth } from "../PatientLateralMenu/PatientLateralMenu.style";

export const DesktopContainer = styled("div", {
  zIndex: 900,
  boxSizing: "border-box",

  height: 62,
  width: `calc(100% - 88px)`,

  backgroundColor: "$white",
  borderBottom: "1px solid $gray-30",
  transition: "width .4s",

  position: "fixed",

  top: 62,
  left: 88,

  paddingRight: "76px",
  paddingLeft: "36px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  variants: {
    isLateralPatientMenuOpenOnDesktop: {
      true: {
        transition: "width .4s",

        width: `calc(100% - 88px - ${expandedPatientLateralMenuCardContainerWidth}px)`,
        paddingRight: "42px",

        "@media screen and (max-width: 1100px) and (min-width: 769px)": {
          width: `calc(100% - ${expandedPatientLateralMenuCardContainerWidth}px)`,
        },
      },
    },
  },

  "@media screen and (max-width: 1100px) and (min-width: 769px)": {
    left: 0,
    width: "100%",
  },

  "@sm": {
    display: "none",
  },
});

export const Container = styled("div", {
  display: "flex",

  alignItems: "center",
  justifyContent: "space-between",

  width: "100%",
  maxWidth: "1519px",

  "@md": {
    width: "1220px",
  },

  "@sm": {
    width: "100%",
    margin: 0,
  },
});

export const Title = styled("p", {
  fontFamily: "$nunito",

  fontWeight: 700,
  fontSize: "$text-xxl",

  color: "$gray-100",

  "@sm": {
    fontSize: "$text-lg",
    lineHeight: "19px",
  },
});

export const MobileTitleContainer = styled("div", {
  display: "flex",
  flexDirection: "flex-start",

  padding: "16px 16px 22px 16px",

  span: {
    margin: "0 4px",
  },
});

export const TitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",

  span: {
    fontSize: "18px",
    margin: "0 8px",

    fontFamily: "$openSans",
    color: "$gray-100",
  },
});

export const DateContainer = styled("div", {
  padding: "0 10px 0 2px",
  borderRadius: "8px",
  height: "26px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "8px",
});

export const Text = styled("p", {
  fontFamily: "$openSans",

  fontWeight: 600,
  lineHeight: "16px",
  fontSize: "$text-md",

  color: "$gray-100",
  padding: "5px",
});

export const AlertsContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  flexGrow: 1,

  gap: 8,
});

export const PatientCard = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1.5fr",
  alignItems: "center",

  fontFamily: "$openSans",
  fontSize: "$text-md",
  color: "$gray-90",

  paddingLeft: 38,
  paddingRight: 24,

  height: 58,

  borderBottom: "1px solid $gray-50",

  p: {
    "&:first-child": {
      fontWeight: 600,
    },

    "&:last-child": {
      fontWeight: 400,
    },
  },
});

export const DownloadButton = styled("button", {
  color: "#FFF",
  borderRadius: "8px",
  background: "#212121",
  fontFamily: "$openSans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  display: "flex",
  width: "91px",
  height: "26px",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  border: "0px",
  marginLeft: "8px",

  "&:hover": {
    backgroundColor: "$charcoal",
  },
});

export const RightHeadingContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "center",
  alignItems: "center",
  width: "100%",
});

export const PatientNavigationContainer = styled("div", {
  display: "flex",
  justifyContent: "inline-flex",
  marginLeft: "16px",
});

export const tooltipStyles = {
  borderRadius: "4px !important",
  fontSize: "12px",
  padding: "12px 16px",
  display: "flex",
  backgroundColor: "$charcoal",
};

export const ChevronButton = styled("button", {
  width: 26,
  height: 26,

  paddingLeft: "6px",
  paddingRight: "6px",
  paddingTop: "2px",
  paddingBottom: "2px",
  marginLeft: "4px",
  marginRight: "4px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  border: "0px",
  backgroundColor: "transparent",
  borderRadius: "20px !important",

  "&:hover": {
    backgroundColor: "$gray-40",
  },

  variants: {
    disabled: {
      true: {
        cursor: "auto",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
});
