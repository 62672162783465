import { styled } from "@patientmpower/spiro";

export const LeftPanelUserSettingsContainer = styled("aside", {
  display: "none",

  "@media screen and (max-width: 1100px)": {
    display: "block",

    zIndex: 1200,

    backgroundColor: "$white",

    position: "fixed",
    top: 62,

    overflow: "hidden",

    width: 0,
    height: "100%",
    border: 0,
    paddingTop: "30px",
    paddingLeft: "0px",
    paddingRight: "0px",

    transition: "ease 500ms",
  },

  variants: {
    isOpen: {
      true: {
        "@media screen and (max-width: 1100px)": {
          width: "100%",

          paddingLeft: "60px",
          paddingRight: "60px",
        },
      },
    },
  },
});

export const UserSettingOption = styled("button", {
  fontFamily: "$openSans",
  fontSize: "$text-lg",
  fontWeight: "400",
  color: "$black",

  backgroundColor: "transparent",
  border: "unset",

  width: "100%",
  height: "40px",

  textAlign: "left",
});
