import { useQuery } from "@tanstack/react-query";

import { IWardOption } from "../../@types/User";
import { wardsService } from "../../services/wardsService";

export function useAvailableWards() {
  const { data, isError, isLoading, isFetching, refetch, isRefetching } =
    useQuery(
      ["available-wards-options"],
      async () => {
        const { data } = await wardsService.getAvailableWards();

        return data;
      },
      {
        refetchOnMount: true,
      }
    );

  return {
    availableWards: data as IWardOption[],
    isWardsFetching: isFetching,
    isWardsError: isError,
    refetchWards: refetch,
    isWardsRefetching: isRefetching,
    isWardsLoading: isLoading,
  };
}

export function useWards() {
  const { data, isError, isLoading, isFetching, refetch, isRefetching } =
    useQuery(
      ["wards-options"],
      async () => {
        const { data } = await wardsService.getAllWards();

        return data;
      },
      {
        refetchOnMount: true,
      }
    );

  return {
    wards: data as IWardOption[],
    isWardsFetching: isFetching,
    isWardsError: isError,
    refetchWards: refetch,
    isWardsRefetching: isRefetching,
    isWardsLoading: isLoading,
  };
}

export function useHospitalGroupWards(hospitalGroupId: number) {
  const { data, isError, isLoading, isFetching, refetch, isRefetching } =
    useQuery(
      [`hospitalGroup-wards-options-${hospitalGroupId}`],
      async () => {
        const { data } = await wardsService.getHospitalGroupWards(
          hospitalGroupId
        );

        return data;
      },
      {
        refetchOnMount: true,
      }
    );

  return {
    hospitalGroupWards: data as IWardOption[],
    isWardsFetching: isFetching,
    isWardsError: isError,
    refetchWards: refetch,
    isWardsRefetching: isRefetching,
    isWardsLoading: isLoading,
  };
}
