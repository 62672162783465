import { useQuery } from "@tanstack/react-query";
import * as DOMPurify from "dompurify";

import { PatientNotesTableContent } from "../../@types/Patient";
import { noteService } from "../../services/noteService";

function removeTags(str: string) {
  if (str === null || str === "") return "";

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, " ");
}

export function textTruncate(htmlString: string) {
  const str: string = removeTags(htmlString);
  const length = 70;
  const ending = "...";

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
}

export function getPatientNotesData(patientId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-notes-table-${patientId}`, patientId],
    async () => {
      if (!patientId) return [] as PatientNotesTableContent[];

      const { data } = await noteService.getNotes(patientId);

      return data.notes as PatientNotesTableContent[];
    }
  );

  const patientNoteTableData = data as PatientNotesTableContent[];

  return {
    patientNoteTable: patientNoteTableData?.map((noteData) => {
      return {
        ...noteData,
        notes: textTruncate(noteData.notes),
        time: noteData.time,
      } as PatientNotesTableContent;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function getNoteData(noteId: number) {
  const { data, isFetching, isError, refetch, isRefetching, remove } = useQuery(
    [`note-${noteId}`, noteId],
    async () => {
      if (!noteId) return {} as PatientNotesTableContent;

      const { data } = await noteService.getNoteById(noteId);

      return data as PatientNotesTableContent;
    }
  );

  const patientNoteData = data as PatientNotesTableContent;

  return {
    patientNoteData,
    isFetching,
    isError,
    refetch,
    isRefetching,
    remove,
  };
}

export const cleanHTML = (str: string) => {
  return DOMPurify.sanitize(str);
};
