import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { NotificationSuccess } from "../../assets/icons/NotificationSuccess";

export interface IToastNotificationProps {
  message: string;
  type: "success" | "info" | "warning" | "error";
  width?: string;
  rightMargin?: string;
}

export function ToastNotification({
  message,
  type,
  width = "400px",
  rightMargin = "30%",
}: IToastNotificationProps) {
  const notificationStyle = {
    width,
    minHeight: "50px",
    backgroundColor: "#212121",
    borderRadius: "8px",
    right: rightMargin,
    color: "#FFFFFF",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontFamily: '"Open Sans", sans-serif',
  };

  useEffect(() => {
    const toastInfo = () =>
      toast.info(message, {
        style: notificationStyle,
      });
    const toastSuccess = () =>
      toast.success(message, {
        style: notificationStyle,
        icon: NotificationSuccess,
      });
    const toastWarn = () =>
      toast.warn(message, {
        style: notificationStyle,
      });
    const toastError = () =>
      toast.error(message, {
        style: notificationStyle,
      });

    if (type === "success") {
      toastSuccess();
    }
    if (type === "info") {
      toastInfo();
    }
    if (type === "warning") {
      toastWarn();
    }
    if (type === "error") {
      toastError();
    }
  }, [type, message]);

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={2500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      closeButton={false}
    />
  );
}
