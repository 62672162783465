import {
  Button,
  Dropdown,
  DropdownTextItem,
  TextField,
} from "@patientmpower/spiro";
import { useState } from "react";

import {
  ButtonContainer,
  CancelButton,
  ContentContainer,
  ModalContainer,
  ModalTitle,
  Row,
} from "./DownloadReportModal.styles";

type DownloadReportModalProps = {
  onClose: (success?: boolean, format?: string) => void;
};

export function DownloadReportModal(props: DownloadReportModalProps) {
  const [format, setFormat] = useState("CSV");
  const { onClose } = props;

  const handleCancelClick = () => {
    onClose();
  };

  const handleDownloadClick = () => {
    onClose(true, format);
  };

  return (
    <ModalContainer>
      <ModalTitle>Download report</ModalTitle>
      <ContentContainer>
        <Row>
          <p>Format</p>
          <Dropdown
            trigger={format}
            onValueChange={(e) => {
              setFormat(e);
            }}
            backgroundColor="#E6E6E6"
            fontColor="#212121"
            size="small"
          >
            <DropdownTextItem text="CSV" value="CSV" />
            <DropdownTextItem text="PDF" value="PDF" />
          </Dropdown>
        </Row>
        <Row>
          <p>Start date</p>
          <TextField
            placeholder="dd/mm/yyyy"
            label=""
            onChange={(value) => console.log(value)}
            backgroudColor="white"
          />
        </Row>
        <Row>
          <p>End date</p>
          <TextField
            placeholder="dd/mm/yyyy"
            label=""
            onChange={(value) => console.log(value)}
            backgroudColor="white"
          />
        </Row>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={handleDownloadClick} type="submit" label="Download" />
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      </ButtonContainer>
    </ModalContainer>
  );
}
