export function ChevronLeft(props: any) {
  const { opacity } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M10.278 12.1996C10.5741 12.478 10.5741 12.9294 10.278 13.2079C9.98182 13.4863 9.50167 13.4863 9.20552 13.2079L3.13886 7.50415C2.84271 7.22572 2.84271 6.7743 3.13886 6.49587L9.20552 0.792166C9.50167 0.513736 9.98182 0.513736 10.278 0.792166C10.5741 1.07059 10.5741 1.52202 10.278 1.80045L4.74759 6.99995L10.278 12.1996Z"
          fill="#939393"
        />
      </g>
    </svg>
  );
}
