export function CircleTickPlus({ ...rest }) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M480-71.87q-84.674 0-159.109-32.097-74.435-32.098-129.63-87.294-55.196-55.195-87.294-129.63T71.869-480q0-84.674 32.098-159.109t87.294-129.63q55.195-55.196 129.63-87.294T480-888.131q45.609 0 89.315 9.924 43.707 9.924 84.272 29.011 16.913 8 22.13 26.152 5.218 18.153-5.739 34.066-10.956 16.152-29.848 21.108-18.891 4.957-37.282-3.043-29.131-12.609-60.283-19.413T480-797.13q-132.565 0-224.848 92.282Q162.87-612.565 162.87-480t92.282 224.848Q347.435-162.87 480-162.87q23.13 0 45.141-3.402t44.141-9.967q19.392-5 37.663.935 18.272 5.934 27.99 23.087 8.717 16.152 3.021 34.065-5.695 17.913-23.608 23.913-32.326 11.565-66.294 16.968Q514.087-71.87 480-71.87ZM754.5-274.5H680q-19.152 0-32.326-13.174T634.5-320q0-19.152 13.174-32.326T680-365.5h74.5V-440q0-19.152 13.174-32.326T800-485.5q19.152 0 32.326 13.174T845.5-440v74.5H920q19.152 0 32.326 13.174T965.5-320q0 19.152-13.174 32.326T920-274.5h-74.5v74.5q0 19.152-13.174 32.326T800-154.5q-19.152 0-32.326-13.174T754.5-200v-74.5ZM423.283-416.37l370.804-371.804q12.435-12.435 31.348-12.435 18.913 0 31.348 12.435 12.435 12.435 12.435 31.348 0 18.913-12.435 31.348L455.348-323.283q-13.674 13.674-32.065 13.674-18.392 0-32.066-13.674L280.804-433.696q-12.434-12.434-12.434-31.228 0-18.793 12.434-31.228 12.435-12.435 31.348-12.435 18.913 0 31.348 12.435l79.783 79.782Z" />
    </svg>
  );
}
