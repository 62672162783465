export function LineDot(props: any) {
  const { cx, cy, fill } = props;

  return (
    <svg
      x={cx && cx - 8}
      y={cy && cy - 8}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      cursor="pointer"
    >
      <rect
        x="4"
        y="7"
        width="8"
        height="4"
        rx="1.2"
        fill={fill ?? "#7562A8"}
      />
    </svg>
  );
}
