import { css, styled } from "@patientmpower/spiro";

export const ModalContainer = styled("div", {
  width: "100%",
  height: "100%",

  display: "flex",
  flexDirection: "column",

  padding: "32px 26px 30px 26px",
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "600",

  marginBottom: "32px",
  paddingLeft: "16px",
});

export const ContentContainer = styled("div", {
  width: "100%",
  height: "fit-content",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: "32px",

  borderRadius: "8px",
  backgroundColor: "rgba(248, 248, 248, 0.8)",

  padding: "28px 48px",
});

export const Row = styled("div", {
  width: "100%",
  position: "relative",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  p: {
    color: "$charcoal",
    fontSize: "14px",
    fontWeight: "500",

    width: "100px",
    marginRight: "15px",
  },

  ".ant-select": {
    height: "42px !important",
  },

  ".ant-select-selection-search-input": {
    height: "42px !important",
    fontFamily: "$openSans !important",
  },

  ".ant-picker-input": {
    fontFamily: "$openSans !important",
  },
});

export const ErrorMessage = styled("p", {
  fontSize: "12px !important",
  fontWeight: "600 !important",

  lineHeight: "16px !important",

  color: "$error-light !important",

  width: "100% !important",
  position: "absolute",
  top: "45px",
  right: "-135px",
});

export const SubmitContainer = styled("div", {
  height: "70px",
  display: "flex",
  gap: "40px",
  padding: "26px 12px 0px 0px",
  alignItems: "center",
});

// CSS

export const selectCss = css({
  ".ant-select-selector": {
    height: "42px !important",
    alignItems: "center",
    border: "solid 2px $gray-50 !important",
    boxShadow: "0 0 0 2px $gray-50 !important",
    fontFamily: "$openSans !important",

    "&:focus-within": {
      borderColor: "$primary-30 !important",
    },

    "&:hover": {
      borderColor: "$primary-30 !important",
    },

    ".ant-select-selection-overflow-item": {
      span: {
        "&.ant-select-selection-item": {
          borderRadius: "14px !important",
        },
      },
    },

    ".ant-select-selection-search-input": {
      height: "100% !important",
    },
  },
});

export const popupOptionsCss = css({
  zIndex: 3001,
  pointerEvents: "auto",

  ".ant-select-item-option-selected": {
    backgroundColor: "$primary-20 !important",

    svg: {
      color: "$primary",
    },
  },

  ".ant-select-item-option-content": {
    fontFamily: "$openSans",
    color: "$black !important",
    fontWeight: "500 !important",
  },
});

export const submitButtonCss = css({
  width: "138px",

  button: {
    width: "100%",

    p: {
      margin: "auto",
    },
  },
});

export const cancelButtonCss = css({
  width: "168px",

  button: {
    width: "100%",

    p: {
      margin: "auto",
    },
  },
});

export const urlInputCss = css({
  width: "240px",
});
