export function Note({ ...rest }) {
  return (
    <svg
      {...rest}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        d="M19.4196 13.6946L17.3054 11.5805L18.0304 10.8555C18.216 10.675 18.4489 10.5848 18.7289 10.5848C19.0089 10.5848 19.2391 10.675 19.4196 10.8555L20.1446 11.5805C20.325 11.7661 20.4152 11.9989 20.4152 12.2789C20.4152 12.559 20.325 12.7892 20.1446 12.9696L19.4196 13.6946ZM10.7598 20.2403V18.1261L16.2304 12.6555L18.3446 14.7696L12.8739 20.2403H10.7598ZM1.75977 15.1457V13.1511H9.90979V15.1457H1.75977ZM1.75977 10.6816V8.70112H13.9902V10.6816H1.75977ZM1.75977 6.23155V4.2511H13.9902V6.23155H1.75977Z"
        fill="#727272"
      />
    </svg>
  );
}
