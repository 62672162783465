import {
  ISurveyQuestionAttributes,
  ISurveyQuestionOption,
} from "../../../../../@types/Surveys";

const vasOptions = [
  {
    order: 1,
    label: "No pain",
    value: 1,
  },
  {
    order: 2,
    label: "Unbearable pain",
    value: 10,
  },
];

const vasAttributes = [
  { name: "Step", value: "1" },
  { name: "Orientation", value: "Vertical" },
];

interface IVasConfig {
  options: ISurveyQuestionOption[];
  attributes: ISurveyQuestionAttributes[];
}

export const vasConfig: IVasConfig = {
  options: vasOptions,
  attributes: vasAttributes,
};
