import { AxiosResponse } from "axios";

import { api } from "./api";

export const spirometryService = {
  async getArtiqSessionData(spirometryId: number): Promise<AxiosResponse> {
    const url = `/Portal/SpirometryTest/GetSessionBySpirometryId/${spirometryId}/`;
    const spirometryArtiqSpirometryData = await api.get(url);

    return spirometryArtiqSpirometryData;
  },
};
