export function Pill({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        d="M5.90622 17.4443C4.41524 17.4443 3.15072 16.9253 2.11269 15.8873C1.07467 14.8493 0.555664 13.5847 0.555664 12.0938C0.555664 11.3803 0.692202 10.6977 0.965277 10.0458C1.23834 9.39384 1.62756 8.81443 2.13295 8.30751L8.30748 2.12001C8.8138 1.62327 9.39254 1.23837 10.0437 0.965307C10.6949 0.692232 11.3782 0.555695 12.0937 0.555695C13.5847 0.555695 14.8492 1.0747 15.8873 2.11272C16.9253 3.15075 17.4443 4.41527 17.4443 5.90625C17.4443 6.61967 17.3077 7.30233 17.0347 7.95425C16.7616 8.60617 16.3767 9.18558 15.88 9.6925L9.69247 15.867C9.18615 16.3724 8.6074 16.7616 7.95623 17.0347C7.30508 17.3078 6.62174 17.4443 5.90622 17.4443ZM12.1495 10.8403L14.5956 8.40596C14.9198 8.07197 15.173 7.69142 15.3554 7.26432C15.5377 6.8372 15.6288 6.38402 15.6288 5.90479C15.6288 4.92069 15.2859 4.08572 14.6001 3.39988C13.9143 2.71405 13.0793 2.37113 12.0952 2.37113C11.616 2.37113 11.1628 2.46229 10.7357 2.64462C10.3086 2.82696 9.92801 3.08023 9.59402 3.40442L7.15967 5.85045L12.1495 10.8403ZM5.90622 15.6159C6.36411 15.6159 6.81171 15.5269 7.249 15.3489C7.6863 15.1709 8.07194 14.9198 8.40593 14.5956L10.8403 12.1366L5.86337 7.15971L3.40439 9.59405C3.08019 9.92804 2.82909 10.3099 2.65107 10.7395C2.47306 11.1692 2.38405 11.6206 2.38405 12.0938C2.38405 13.0788 2.7248 13.9121 3.40631 14.5937C4.08784 15.2752 4.92114 15.6159 5.90622 15.6159Z"
        fill="#727272"
      />
    </svg>
  );
}
