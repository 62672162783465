import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import {
  IQuestionDisplayProps,
  ISurveyAnswer,
} from "../../../../../../../@types/Surveys";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import {
  Title,
  Description,
} from "../TextQuestionDisplay/TextQuestionDisplay.styles";
import {
  dateInputCss,
  datePopUptCss,
  DateAnswerField,
} from "./DateQuestiondisplay.styles";

export function DateQuestionDisplay({
  title,
  description,
  questionId,
}: IQuestionDisplayProps) {
  const [answer, setAnswer] = useState<string>();
  const [renderDatePicker, setRenderDatePicker] = useState<boolean>(false);

  const { surveyAnswerArray, updateSurveyAnswerArray } = useDynamicsurveys();

  const updateAnswerArray = (newDate: string) => {
    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "",
      answerValue: newDate?.toString() ?? "",
    };
    updateSurveyAnswerArray(answerUpdate);
  };

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (answerValue) => answerValue.surveyQuestionId === questionId
    );
    if (storedAnswer?.answerValue !== "") {
      const dateUpdate = new Date(
        dayjs(storedAnswer?.answerValue).toDate()
      ).toDateString();
      setAnswer(dateUpdate);
    }
    setRenderDatePicker(true);
  }, []);
  return (
    <DateAnswerField>
      <Title> {title}</Title>
      <Description> {description}</Description>

      {renderDatePicker && (
        <DatePicker
          className={dateInputCss()}
          enterKeyHint="done"
          id="Picker"
          inputReadOnly
          placeholder="Choose date"
          placement="bottomRight"
          value={answer ? dayjs(answer) : null}
          popupClassName={datePopUptCss()}
          onChange={(date) => {
            if (date) {
              const newDate = new Date(date.toDate()).toISOString();
              setAnswer(newDate);
              updateAnswerArray(newDate);
            } else {
              setAnswer(undefined);
              updateAnswerArray("");
            }
          }}
        />
      )}
    </DateAnswerField>
  );
}
