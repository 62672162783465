export function BlackMinus({ ...rest }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.58594 11.6875V10.3125H17.4193V11.6875H4.58594Z"
        fill="#212121"
      />
    </svg>
  );
}
