import React from "react";

type ScrollToSectionPatientPageProps = {
  overviewSectionRef: React.MutableRefObject<null | HTMLElement>;
  measurementsSectionRef: React.MutableRefObject<null | HTMLElement>;
  spirometrySectionRef: React.MutableRefObject<null | HTMLElement>;
  labsSectionRef: React.MutableRefObject<null | HTMLElement>;
  alertsSectionRef: React.MutableRefObject<null | HTMLElement>;
  promsSectionRef: React.MutableRefObject<null | HTMLElement>;
  notesSectionRef: React.MutableRefObject<null | HTMLElement>;
  medicationSectionRef: React.MutableRefObject<null | HTMLElement>;
  journalSectionRef: React.MutableRefObject<null | HTMLElement>;
  messagesSectionRef: React.MutableRefObject<null | HTMLElement>;
  devicesSectionRef: React.MutableRefObject<null | HTMLElement>;
};

export function scrollToSectionPatientPage({
  overviewSectionRef,
  measurementsSectionRef,
  spirometrySectionRef,
  labsSectionRef,
  alertsSectionRef,
  promsSectionRef,
  notesSectionRef,
  medicationSectionRef,
  journalSectionRef,
  messagesSectionRef,
  devicesSectionRef,
}: ScrollToSectionPatientPageProps) {
  const { pathname } = window.location;

  if (pathname.includes("overview") && overviewSectionRef.current) {
    overviewSectionRef.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  if (pathname.includes("measurements") && measurementsSectionRef.current) {
    measurementsSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("spirometry") && spirometrySectionRef.current) {
    spirometrySectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("labs") && labsSectionRef.current) {
    labsSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("alerts") && alertsSectionRef.current) {
    alertsSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("proms") && promsSectionRef.current) {
    promsSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("notes") && notesSectionRef.current) {
    notesSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("medication") && medicationSectionRef.current) {
    medicationSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("journal") && journalSectionRef.current) {
    journalSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("messages") && messagesSectionRef.current) {
    messagesSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("devices") && devicesSectionRef.current) {
    devicesSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }
}
