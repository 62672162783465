import { Tag } from "@patientmpower/spiro";
import dayjs from "dayjs";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxisDomain } from "recharts/types/util/types";

import {
  MeasurementTypes,
  SelectMeasurementPayloadType,
} from "../../../../@types/Measurements";
import { IUserPreferences } from "../../../../@types/Preferences";
import { ChartConfig } from "../../../../components/ChartCard/ChartCard.types";
import { ModalChart } from "../../../../components/ModalChart";
import { AUTH_UNIT_PREFERENCES } from "../../../../constants/localStorageKeys";
import { spirometryMeasurementsValues } from "../../../../constants/measurements";
import { useMultipleMeasurements } from "../../../../hooks/queries/measurements";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { userService } from "../../../../services/userService";
import { getSourceLegends } from "../../../../utils/chartSourceUtils";
import { getShortFormattedDate } from "../../../../utils/dateFormatter";
import { randomIntFromInterval } from "../../../../utils/random";
import {
  LocationMenuOption,
  LocationMenuOptionProps,
} from "../LocationMenuOption";
import {
  CustomOptionsBar,
  DateFilterOptionType,
} from "../MeasurementModal/components/CustomOptionsBar";
import { dateFilterOptionsMobile } from "../MeasurementModal/components/CustomOptionsBar/dateFilterOptions";
import { ModalSkeletonMobile } from "../MeasurementModal/components/ModalSkeleton";
import { ModalOptions } from "../MeasurementModal/components/OptionsPopover/Options.types";
import { Subtitle } from "../MeasurementModal/MeasurementModal.styles";
import { RoutesPatientPageMobile } from "../SpirometrySection/SpirometrySection.styles";
import { MeasurementsModalMobileTable } from "./components/MeasurementModalMobileTable/MeasurementsModalMobileTable";
import { SessionDetailsMobile } from "./components/SessionDetailsMobile/SessionDetailsMobile";
import {
  ChartCoantiner,
  Container,
  OptionsContainer,
  Title,
} from "./MeasurementModalMobile.styles";

type MeasurementModalMobileProps = {
  title: string;
  measurementType: MeasurementTypes;
  minValue?: number;
  maxValue?: number;
  patientId?: string;
};

export function MeasurementModalMobile({
  title,
  measurementType,
  minValue,
  maxValue,
  patientId,
}: MeasurementModalMobileProps) {
  const currentDate = dayjs();
  const navigate = useNavigate();

  const componentRef = useRef<null | HTMLDivElement>(null);

  const chartConfigs: ChartConfig[] = [];
  const allMeasurements: Map<string, any[]> = new Map<string, any[]>();
  let mainValues: any = { min: 0, max: 0, average: 0 };

  const getGraphPreferences = (): string => {
    const preferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences) as IUserPreferences;

      if (parsedPreferences.dataDisplayPreference !== undefined) {
        return parsedPreferences.dataDisplayPreference;
      }
    }

    return "";
  };

  const locationConfigs: LocationMenuOptionProps[] = [
    {
      keyLabel: `patient-list-${randomIntFromInterval(1, 10000)}`,
      label: "Patient List",
      onClick: () => {
        navigate("/");
      },
      active: true,
    },
    {
      label: "Patient Page",
      active: true,
      keyLabel: `patient-page-${randomIntFromInterval(1, 10000)}`,
      onClick: () => {},
    },
    {
      label: title.replace(/ *\([^)]*\) */g, ""),
      active: false,
      keyLabel: `spirometry-${randomIntFromInterval(1, 10000)}`,
      onClick: () => {},
    },
  ];

  const [filter, setFilter] = useState(getGraphPreferences);
  const {
    preferences,
    changeUserPreferences,
    portalPreferences,
    changePortalPreferences,
  } = useUserPreferences();
  const [isSessionDetails, setIsSessionDetails] = useState(false);
  const [locationConfigState, setLocationConfigState] =
    useState(locationConfigs);
  const [showMeasurementsTable, setShowMeasurementsTable] =
    useState<boolean>(false);

  const [selectedFilterDate, setSelectedFilterDate] =
    useState<DateFilterOptionType>({
      endDate: currentDate,
      label: dateFilterOptionsMobile[2].label,
      startDate: dateFilterOptionsMobile[2].value(currentDate),
    });

  const [
    selectedSpirometryMeasurementValue,
    setSelectedSpirometryMeasurementValue,
  ] = useState<SelectMeasurementPayloadType | null>(null);

  const { hideFvcRejected, hideFev1Rejected, hideFvcUsable, hideFev1Usable } =
    preferences;

  const measurementTypes: MeasurementTypes[] = [measurementType];
  if (measurementType === "bp_systolic") {
    measurementTypes.push("bp_diastolic");
  }

  const { data, refetch, isRefetching, isFetching } = useMultipleMeasurements({
    patientId,
    zoomedVersion: true,
    types: measurementTypes,
    toDate: selectedFilterDate.endDate.format(),
    fromDate: selectedFilterDate.startDate.format(),
    filter,
    hideFvcRejected,
    hideFev1Rejected,
    hideFvcUsable,
    hideFev1Usable,
  });

  if (data && data.length > 0) {
    data.forEach((d: any, index: number) => {
      if (!d) return;

      const { type, measurements, mainMeasurementValues } = d;
      if (measurements && measurements.size > 0) {
        measurements.forEach((measurementsMap: any, key: string) => {
          const source = (key || "unknown").toLowerCase();
          const sourceLegend = getSourceLegends(type, source);
          const { color, shape, lineOpacity, isDotClickable } = sourceLegend;

          allMeasurements.set(`${type}|${source}`, measurementsMap);
          chartConfigs.push({
            dot: false,
            dotShape: shape,
            lineType: "monotone",
            yKey: type,
            color,
            lineOpacity,
            isDotClickable,
            min: minValue,
            max: maxValue,
            source,
          });

          if (index === 0) mainValues = mainMeasurementValues;
        });
      }
    });
  }

  const customChartConfig = chartConfigs.map((currentChartConfig) => ({
    ...currentChartConfig,
    dot: spirometryMeasurementsValues.indexOf(measurementType) !== -1,
  }));

  const domainByGlobalPreferences = useMemo(() => {
    if (portalPreferences.scaleYAxis) {
      return [
        (dataMin: number) => Math.floor(dataMin),
        (dataMax: number) => Math.ceil(dataMax),
      ] as AxisDomain;
    }

    if (chartConfigs.length > 0 && chartConfigs[0].min && chartConfigs[0].max) {
      return [
        () => chartConfigs[0].min,
        () => chartConfigs[0].max,
      ] as AxisDomain;
    }

    return [0, (dataMax: number) => Math.ceil(dataMax)] as AxisDomain;
  }, [portalPreferences.scaleYAxis, chartConfigs]);

  const formattedDateRange = `${getShortFormattedDate(
    selectedFilterDate.startDate.format()
  )} - ${getShortFormattedDate(selectedFilterDate.endDate.format())}`;

  const highestOfDayLabel = filter ? " (Highest of the day) " : "";

  const acceptabilityHideUsableLabel =
    hideFev1Usable || hideFvcUsable ? " (Usable hidden) " : "";

  const acceptabilityHideRejectedLabel =
    hideFev1Rejected || hideFvcRejected ? " (Rejected hidden) " : "";

  const subTitle = `${formattedDateRange}${highestOfDayLabel}${acceptabilityHideUsableLabel}${acceptabilityHideRejectedLabel}`;

  const handleOnChangeModalOptions = (options: ModalOptions) => {
    changeUserPreferences(options);
  };

  const handledDisplayChange = (filter: string) => {
    setFilter(filter);

    const preferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

    if (preferences) {
      const parsedPreferences = JSON.parse(preferences) as IUserPreferences;
      parsedPreferences.dataDisplayPreference = filter;

      localStorage.setItem(
        AUTH_UNIT_PREFERENCES,
        JSON.stringify(parsedPreferences)
      );
    }
  };

  const handleOnChangeScaleYAxis = (scaleYAxis: boolean) => {
    const newPortalPreferences = { ...portalPreferences, scaleYAxis };
    changePortalPreferences(newPortalPreferences);
    userService.updateScaleYAxis(scaleYAxis);
  };

  useEffect(() => {
    refetch();
  }, [
    filter,
    selectedFilterDate,
    hideFvcRejected,
    hideFev1Rejected,
    hideFvcUsable,
    hideFev1Usable,
  ]);

  const handleRowClick = (payload: SelectMeasurementPayloadType) => {
    locationConfigs[2].active = true;
    locationConfigs[2].onClick = () => {
      locationConfigs[2].active = false;
      locationConfigs[2].onClick = () => {};
      locationConfigs.pop();
      setIsSessionDetails(false);
      setLocationConfigState(locationConfigs);
    };

    locationConfigs[3] = {
      label: "Session",
      active: false,
      keyLabel: `session-${randomIntFromInterval(1, 10000)}`,
      onClick: () => {},
    };

    setLocationConfigState(locationConfigs);
    setSelectedSpirometryMeasurementValue(payload);
    setIsSessionDetails(true);

    if (componentRef.current) {
      componentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return isFetching || isRefetching ? (
    <ModalSkeletonMobile />
  ) : (
    <Container ref={componentRef}>
      <RoutesPatientPageMobile>
        {locationConfigState.map((config: LocationMenuOptionProps, index) => {
          if (index !== 0) {
            return (
              <Fragment key={config.keyLabel}>
                <p> - </p>
                <LocationMenuOption
                  keyLabel={config.keyLabel}
                  label={config.label}
                  onClick={config.onClick}
                  active={config.active}
                />
              </Fragment>
            );
          }
          return (
            <LocationMenuOption
              key={config.keyLabel}
              keyLabel={config.keyLabel}
              label={config.label}
              onClick={config.onClick}
              active={config.active}
            />
          );
        })}
      </RoutesPatientPageMobile>
      {!isSessionDetails ? (
        <>
          <Title>{title}</Title>
          <Subtitle>{subTitle}</Subtitle>
          <OptionsContainer>
            <CustomOptionsBar
              onChange={(selectedDateFilter) => {
                const { label } = selectedDateFilter;

                if (label === "custom" || label !== selectedFilterDate.label) {
                  setSelectedFilterDate(selectedDateFilter);
                }
              }}
              onTableViewChange={(enableTableView) => {
                setShowMeasurementsTable(enableTableView);
              }}
              selectedFilterDate={selectedFilterDate}
              cardTitle={title}
              initialOptions={preferences}
              initialScaleYAxis={portalPreferences.scaleYAxis ?? false}
              initialDisplay={getGraphPreferences()}
              onChangeOption={handleOnChangeModalOptions}
              onChangeScaleYAxis={handleOnChangeScaleYAxis}
              onDisplayChange={handledDisplayChange}
              measurementType={measurementType}
              mobile
            />
          </OptionsContainer>
          {allMeasurements.size === 0 ? (
            <div style={{ marginTop: "10px" }}>
              <Tag variant="tertiary">No data available for this range</Tag>
            </div>
          ) : null}
          {!showMeasurementsTable ? (
            <ChartCoantiner>
              <ModalChart
                onRowClick={handleRowClick}
                height="95%"
                data={allMeasurements || new Map<string, any[]>()}
                mainValues={mainValues}
                width="100%"
                chartConfig={customChartConfig}
                yConfig={{ domain: domainByGlobalPreferences }}
                dateInterval={selectedFilterDate}
                isSpirometry
                patientBaselines={[]}
              />
            </ChartCoantiner>
          ) : (
            <MeasurementsModalMobileTable data={allMeasurements} />
          )}
        </>
      ) : (
        selectedSpirometryMeasurementValue?.spirometryTestIds && (
          <SessionDetailsMobile
            selectedMeasurement={selectedSpirometryMeasurementValue}
          />
        )
      )}
    </Container>
  );
}
