export function ArtiQUsableAccepted({ ...rest }) {
  return (
    <svg
      {...rest}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.44653" cy="9.1579" r="7.1579" fill="#FBD06F" />
      <path
        d="M7.73156 9.96825L6.03023 8.25958L4.94489 9.35225L7.73156 12.1462L13.1142 6.74892L12.0289 5.65625L7.73156 9.96825Z"
        fill="white"
      />
      <circle
        cx="9.44489"
        cy="9.15625"
        r="8.1"
        stroke="#FBD06F"
        strokeWidth="0.8"
        strokeDasharray="1.6 1.6"
      />
    </svg>
  );
}
