import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",

  width: "100%",
  minHeight: "calc(100vh - 62px)",
  paddingTop: "30px",
  position: "relative",

  "@sm": {
    minHeight: "100%",
  },

  variants: {
    extraPadding: {
      true: {
        paddingTop: "96px !important",
      },
    },
  },
});

export const PageContainer = styled("div", {
  margin: "14px 18px 48px 36px",

  width: "1519px",
  minHeight: "calc(100% - 96px)",

  "@md": {
    width: "1220px",
  },

  "@media(max-width: 1420px)": {
    width: "100%",
  },

  "@sm": {
    width: "100%",
    margin: 0,
  },
});
