import { AxiosResponse } from "axios";

import { IWardOption } from "../@types/User";
import { api } from "./api";

export const wardsService = {
  async getAllWards(): Promise<AxiosResponse<IWardOption[]>> {
    const url = "/Portal/Wards/GetAllWards";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getAvailableWards(): Promise<AxiosResponse<IWardOption[]>> {
    const url = "/Portal/Wards/GetAvailableWards";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getHospitalGroupWards(
    hospitalGroupId: number
  ): Promise<AxiosResponse<IWardOption[]>> {
    const url = `/Portal/Wards/GetHospitalGroupWards${
      hospitalGroupId ? `/${hospitalGroupId}` : ""
    }`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
