import { AxiosResponse } from "axios";

import { IAddDeviceRequest, IDevice, IDeviceType } from "../@types/Devices";
import { api } from "./api";

export const devicesService = {
  async getAllDevices(patientId: number): Promise<AxiosResponse<IDevice[]>> {
    const url = `/Portal/Devices/${patientId}`;
    const devices = await api.get(url);

    if (devices !== undefined) {
      return devices;
    }

    throw Error;
  },

  async getDeviceTypes(): Promise<AxiosResponse<IDeviceType[]>> {
    const url = `/Portal/Devices/types`;
    const devices = await api.get(url);

    if (devices !== undefined) {
      return devices;
    }

    throw Error;
  },

  async addDevice(request: IAddDeviceRequest): Promise<AxiosResponse> {
    const url = `/Portal/Devices`;
    const devices = await api.post(url, request);

    if (devices !== undefined) {
      return devices;
    }

    throw Error;
  },

  async deleteDevice(identifier: string): Promise<AxiosResponse> {
    const url = `/Portal/Devices/${identifier}`;

    const devices = await api.delete(url);

    if (devices !== undefined) {
      return devices;
    }

    throw Error;
  },
};
