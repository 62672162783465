import { useQuery } from "@tanstack/react-query";

import { protocolService } from "../../services/protocol.service";

export function useProtocols() {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery(
    ["protocols-list"],
    async () => {
      const { data } = await protocolService.getProtocolList();

      return data;
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    protocols: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
