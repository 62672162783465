import { styled } from "@patientmpower/spiro";

export const RoutesPatientPageMobile = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",

  width: "100%",
  marginBottom: "10px",

  color: "$gray-80",
  fontSize: "12px",
  fontWeight: "400",

  p: {
    margin: "0 2px 0 2px",
  },
});

export const CardsContainer = styled("div", {
  width: "100%",
  height: "100%",
  gap: "4px",
  display: "flex",
  flexWrap: "wrap",

  "@sm": {
    justifyContent: "center",
  },
});

export const AddMeasurementButton = styled("div", {
  position: "absolute",
  top: -24,
  right: 10,

  color: "$charcoal",
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "$openSans",
  lineHeight: "16px",

  cursor: "pointer",

  "&:hover": {
    color: "$primary",
  },
});

export const SpirometrySectionTitle = styled("div", {
  fontFamily: "$nunito",

  fontWeight: "700",
  lineHeight: "34px",
  fontSize: "$text-xxl",

  color: "$gray-100",

  "@sm": {
    lineHeight: "28px",
  },
});

export const TitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const NewDataCOntainer = styled("div", {
  marginLeft: "5px",
  color: "$gray-80",

  fontFamily: "$nunito",
  fontWeight: "600px",
  fontSize: "16px",
  lineHeight: "34px",
});
