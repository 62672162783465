export const AUTH_TOKEN_STORAGE = "tokenResponse";
export const AUTH_USER_DETAILS = "userDetails";
export const AUTH_UNIT_PREFERENCES = "userUnitPreferences";
export const AUTH_GRAPH_PREFERENCES = "userGraphPreferences";
export const AUTH_INTERCOM_DETAILS = "intercomDetails";
export const AUTH_STORED_ROUTE = "storedRoute";
export const AUTH_PORTAL_PREFERENCES = "portalPreferences";
export const AUTH_HOSPITAL_CONFIGURATION = "hospitalConfiguration";
export const IS_MOBILE_APP = "isMobileApp";
export const SELECTED_WARDS = "selectedWards";
export const PATIENT_LIST_VARIANT = "patientListVariant";
export const PATIENT_IDS = "patientIds";
export const VIEWED_FIRST_PATIENT = "viewedFirstPatient";
export const NOTIFICATION = "notification";
export const AUTH_STAFF_PREFERENCES = "staffPreferences";
