export function Calendar({ ...rest }) {
  return (
    <svg
      {...rest}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0833 11.8334C10.725 11.8334 11.25 11.3084 11.25 10.6667V2.50002C11.25 1.85835 10.725 1.33335 10.0833 1.33335H9.5V0.166687H8.33333V1.33335H3.66667V0.166687H2.5V1.33335H1.91667C1.26917 1.33335 0.755833 1.85835 0.755833 2.50002L0.75 10.6667C0.75 11.3084 1.26917 11.8334 1.91667 11.8334H10.0833ZM4.25 5.41669H3.08333V6.58335H4.25V5.41669ZM1.91667 3.66669H10.0833V2.50002H1.91667V3.66669ZM10.0833 4.83335V10.6667H1.91667V4.83335H10.0833ZM8.91667 6.58335V5.41669H7.75V6.58335H8.91667ZM6.58333 6.58335H5.41667V5.41669H6.58333V6.58335Z"
        fill="#939393"
      />
    </svg>
  );
}
