import { createColumn, Table } from "@patientmpower/spiro";
import { useState } from "react";

import { ToastNotification } from "../../../../components/ToastNotification";
import { useModal } from "../../../../hooks/useModal";
import { DownloadReportModal } from "../DownloadReportModal";
import { Container, DownloadReportTitle } from "./BillableSection.styles";

export function BillableSection() {
  const [showNotification, setShowNotification] = useState(false);
  const [format, setFormat] = useState("");

  const { openModal, closeModal } = useModal();

  const desktopTableColumns = [
    createColumn({
      keyName: "name",
      header: () => "Name",
      cell: (info) => info.renderValue(),
      size: 90,
    }),
    createColumn({
      keyName: "dob",
      header: () => "DOB",
      cell: (info) => info.renderValue(),
      size: 90,
    }),
    createColumn({
      keyName: "hospital_id",
      header: () => "Hospital ID",
      cell: (info) => info.renderValue(),
      size: 90,
    }),
    createColumn({
      keyName: "billing_month",
      header: () => "Billing month",
      cell: (info) => info.renderValue(),
      size: 90,
    }),
    createColumn({
      keyName: "billing_year",
      header: () => "Billing year",
      cell: (info) => info.renderValue(),
      size: 90,
    }),
  ];

  const data = [
    {
      name: "Ellis Redding",
      dob: "Nov 01, 1968",
      hospital_id: "543234",
      billing_month: "June",
      billing_year: "2023",
    },
    {
      name: "Domhnall Miller",
      dob: "Mar 22, 1959",
      hospital_id: "543234",
      billing_month: "June",
      billing_year: "2023",
    },
    {
      name: "Patricia Kelly",
      dob: "Jun 13, 1963",
      hospital_id: "543234",
      billing_month: "June",
      billing_year: "2023",
    },
    {
      name: "Rachel Keane",
      dob: "Jan 08, 1973",
      hospital_id: "543234",
      billing_month: "May",
      billing_year: "2023",
    },
    {
      name: "Steve McQueen",
      dob: "Apr 09, 1979",
      hospital_id: "543234",
      billing_month: "May",
      billing_year: "2023",
    },
    {
      name: "Rachel Keane",
      dob: "Jun 22, 1966",
      hospital_id: "543234",
      billing_month: "May",
      billing_year: "2023",
    },
  ];

  const handleModalClose = (success?: boolean, format?: string) => {
    closeModal();

    if (success && format) {
      setFormat(format);
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 4000);
    }
  };

  const handleDownloadClick = () => {
    openModal(<DownloadReportModal onClose={handleModalClose} />, {
      width: "611px",
      height: "100%",
      showCloseButton: false,
    });
  };

  return (
    <Container>
      <DownloadReportTitle onClick={handleDownloadClick}>
        Download report
      </DownloadReportTitle>
      <Table data={data} emptyStateText="Empty" columns={desktopTableColumns} />

      {showNotification ? (
        <ToastNotification
          message={`${format} successfully downloaded`}
          type="success"
        />
      ) : null}
    </Container>
  );
}
