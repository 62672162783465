import { styled, css } from "@patientmpower/spiro";

export const MainContainer = styled("div", {
  paddingLeft: "300px",

  "@media (max-width: 1100px)": {
    paddingLeft: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
  },

  "@sm": {
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
  },
});

export const TitleMessage = styled("h1", {
  color: "$gray-100",
  marginBottom: "32px",
  marginTop: "42px",
  lineHeight: "49px",
  fontSize: "36px",
  fontFamily: "$openSans",
  fontWeight: "bold",
});

export const InfoMessage = styled("p", {
  marginBottom: "32px",
  fontSize: "16px",
  color: "$gray-100",
  lineHeight: "28px",
  fontFamily: "$openSans",
  fontWeight: "400",
});

export const portalButtonCss = css({
  borderRadius: "5px",
  marginBottom: "20px",

  button: {
    borderRadius: "5px",
    backgroundColor: "$primary-40",
    border: "none",
    padding: "0 60px",

    "&:hover": {
      borderRadius: "5px",
      backgroundColor: "$primary-40",
      border: "none",
    },
    "&:focus": {
      borderRadius: "5px",
      backgroundColor: "$primary-40",
      border: "none",
    },
  },
});

export const StoreImagesRow = styled("div", {
  display: "flex",
  flexWrap: "wrap",

  justifyContent: "flex-start",
  alignItems: "center",
  columnGap: "12px",
  rowGap: "12px",
});

export const StoreImage = styled("img", {
  width: "100px",
  height: "33px",

  display: "flex",

  cursor: "pointer",
});
