import { useAuth } from "../../../hooks/useAuth";
import { useIntercomCustom } from "../../../hooks/useIntercomCustom";
import { useLeftPanel } from "../../../hooks/useLeftPanel";
import {
  LeftPanelUserSettingsContainer,
  UserSettingOption,
} from "./LeftPanelUserSettings.style";

export function LeftPanelUserSettings() {
  const { isMobileUserSettingsMenuOpen, setIsMobileUserSettingsMenuOpen } =
    useLeftPanel();
  const { signOut } = useAuth();
  const { setIsIntercomOpen } = useIntercomCustom();

  const handleSignOutClick = () => signOut();

  const handleSupportClick = () => {
    setIsMobileUserSettingsMenuOpen(false);
    setIsIntercomOpen((prevState) => !prevState);
  };

  return (
    <LeftPanelUserSettingsContainer isOpen={isMobileUserSettingsMenuOpen}>
      <UserSettingOption
        type="button"
        onClick={() => window.location.assign("/settings")}
      >
        Settings
      </UserSettingOption>
      <UserSettingOption type="button" onClick={handleSupportClick}>
        Support
      </UserSettingOption>
      <UserSettingOption type="button" onClick={handleSignOutClick}>
        Sign out
      </UserSettingOption>
    </LeftPanelUserSettingsContainer>
  );
}
