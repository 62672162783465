import { useQuery } from "@tanstack/react-query";

import { ITriggersTableContent } from "../../@types/Alerts";
import { PatientAlertsTableContent } from "../../@types/Patient";
import { alertsService } from "../../services/alertsService";
import { patientService } from "../../services/patientService";
import {
  getLongFormattedDate,
  getLongFormattedDateISO,
} from "../../utils/dateFormatter";

export function getPatientAlertsData(id: string | number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-alerts-table-${id}`, id],
    async () => {
      if (!id) return [] as PatientAlertsTableContent[];

      const { data } = await patientService.getPatientAlerts(id);

      return data?.result as PatientAlertsTableContent[];
    }
  );

  const patientAlertTableData = data as PatientAlertsTableContent[];

  return {
    patientAlertTable: patientAlertTableData?.map((alertData) => {
      return {
        ...alertData,
        timeOfAlert: getLongFormattedDateISO(alertData.timeOfAlert),
      } as PatientAlertsTableContent;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function getTriggersData(id: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`triggers-table-${id}`, id],
    async () => {
      if (!id) return [] as ITriggersTableContent[];

      const { data } = await alertsService.getTriggersByPatientId(id);

      return data as ITriggersTableContent[];
    }
  );

  return {
    triggersTableData: data
      ? data?.map((triggerData) => {
          return {
            ...triggerData,
            created: getLongFormattedDate(triggerData.created),
          } as ITriggersTableContent;
        })
      : null,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
