import { createColumn } from "../../../../components/Table";

export const desktopTableColumns = [
  createColumn({
    keyName: "tags",
    header: () => "Tags",
    cell: (info) => info.renderValue(),
    size: 150,
  }),
  createColumn({
    keyName: "date",
    header: () => "Date",
    cell: (info) => info.renderValue(),
    size: 100,
  }),
  createColumn({
    keyName: "notes",
    header: () => "Text",
    cell: (info) => info.renderValue(),
    size: 250,
  }),
];
