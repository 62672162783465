import { Tile, styled, css } from "@patientmpower/spiro";

// Components
export const Container = styled("div", {
  height: "100%",

  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",

  backgroundColor: "$gray-20",

  "@sm": {
    width: "100%",
  },
});

export const LoadingOverlay = styled("div", {
  width: "542px",
  height: "502px",
  borderRadius: "$4",

  zIndex: 200,
  opacity: 0.64,
  position: "absolute",

  backgroundColor: "$white",

  "@md": {
    width: "448px",
    height: "490px",
  },

  "@sm": {
    width: "100%",
    height: "100%",
  },
});

export const StyledTile = styled(Tile, {
  width: "547px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: "$white",

  "@sm": {
    width: "100%",
    height: "100%",

    padding: "0px 42px",

    justifyContent: "flex-start",
    flexDirection: "column",
  },
});

export const Logo = styled("img", {
  width: "228px",
  height: "54px",

  marginTop: "40px",
  marginBottom: "48px",

  "@sm": {
    margin: "$13 0 $14 0",

    width: "169px",
    height: "$13",
  },
});

export const Form = styled("form", {
  width: "410px",
  height: "100%",

  "@sm": {
    width: "100%",
    height: "70%",
  },
});

export const InfoMessage = styled("p", {
  fontFamily: "$openSans",
  fontWeight: "400",
  fontSize: "$text-xl",
  fontStyle: "normal",
  lineHeight: "22px",
  color: "#212121",
  marginBottom: "18px",
  maxWidth: "351px",
});

export const PasswordRequirementContainer = styled("div", {
  color: "var(--grey-80, #939393)",
  fontFamily: "$openSans",
  fontSize: "$text-xl",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "22px",
  marginBottom: "48px",
});

export const ErrorMessage = styled("p", {
  fontFamily: "$openSans",
  fontWeight: "600",
  fontSize: "$text-md",

  margin: "38px 0 34px 0",
  color: "$error-light",
});

// // CSS Styles
export const listStyles = css({
  paddingLeft: "25px",
});

export const passwordTextFieldStyles = css({
  marginBottom: "$12",
});

export const confirmPasswordTextFieldStyles = css({
  marginBottom: "$15",
});

export const createPasswordButtonStyles = css({
  zIndex: 300,
  position: "relative",
  marginBottom: "68px",
});

export const spinCss = css({
  backgroundColor: "rgba(0, 0, 0, 0.05) !important",

  ".ant-spin-dot": {
    ".ant-spin-dot-item": {
      backgroundColor: "#7562A8",
    },
  },
});
