import { Table } from "@patientmpower/spiro";

import { PatientJournalsTableContent } from "../../../../@types/Patient";
import { ReactComponent as NoJournalsImage } from "../../../../assets/images/man-reading.svg";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { useModal } from "../../../../hooks/useModal";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { desktopTableColumns } from "../JournalsSection/patientJournalsTableColumns";
import { ViewJournalModal } from "./components/ViewJournalModal";
import {
  ImageContainer,
  JournalsTableContainer,
  JournalsTableInnerContainer,
  NoContentMessage,
} from "./PatientJournalsTable.style";

type PatientJournalsTableProps = {
  content: PatientJournalsTableContent[];
  isLoading?: boolean;
  isError?: boolean;
  highlightedRows: boolean[];
  refreshTable: () => void;
};

export function PatientJournalsTable({
  isLoading,
  isError,
  content,
  highlightedRows,
  refreshTable,
}: PatientJournalsTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={4} />;
  }

  if (isError) {
    return (
      <ImageContainer>
        <p>
          Error getting journals, please{" "}
          <button type="button" onClick={refreshTable}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </ImageContainer>
    );
  }

  const { openModal } = useModal();

  const handleRowClick = (
    patientJournalContent: PatientJournalsTableContent
  ) => {
    mixpanelActions.track(`User Action: ClickedJournalEntry`);
    openModal(<ViewJournalModal journalId={patientJournalContent.id} />, {
      width: "100%",
      height: "450px",
      showCloseButton: true,
    });
  };

  if (content && content.length > 0) {
    return (
      <JournalsTableContainer>
        <JournalsTableInnerContainer>
          <Table
            data={content}
            columns={desktopTableColumns}
            highlightedRows={highlightedRows}
            onRowClick={handleRowClick}
            highlightColor="rgba(233, 230, 244, .48)"
          />
        </JournalsTableInnerContainer>
      </JournalsTableContainer>
    );
  }

  return (
    <ImageContainer>
      <NoJournalsImage width={181} height={158} />
      <NoContentMessage>
        Your patient has not added any journal entries yet. Entries will appear
        here.
      </NoContentMessage>
    </ImageContainer>
  );
}
