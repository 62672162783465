import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain, AxisInterval } from "recharts/types/util/types";

import {
  ChartConfig,
  ChartData,
} from "../../../../../../components/ChartCard/ChartCard.types";
import { CustomModalChartDot } from "../../../../../../components/ModalChart/CustomModalChartDot/CustomModalChartDot";
import { DateFilterOptionType } from "../CustomOptionsBar";

type MeasurementPayloadType = {
  fvc: number;
  time: string;
  unformattedTime: string;
  spirometryTestIds: number[];

  cx: number;
  cy: number;
};

type SpirometrySectionChartProps = {
  xConfig?: {
    label?: any;
    dataKey?: string;
    axisSize?: number;
    minTickGap?: number;
    interval?: AxisInterval;
    allowDecimals?: boolean;
    type?: "number" | "category";
    ticks?: Array<number | string>;
    tickFormatter?: (value: string) => string;
  };
  yConfig: {
    axisSize?: number;
    domain?: AxisDomain;
    label?: any;
  };
  data: ChartData[];
  width: string | number;
  height: string | number;
  chartConfig: ChartConfig[];
  onRowClick?: (payload: MeasurementPayloadType) => void;
  dateInterval?: DateFilterOptionType;
  linesToShow?: boolean[];
  highlightedLine?: number;
  onMouseEnter?: (index: number) => void;
  onMouseLeave?: () => void;
};

export function SpirometrySectionChart({
  data,
  width,
  height,
  xConfig,
  yConfig,
  onRowClick,
  chartConfig,
  dateInterval,
  linesToShow,
  highlightedLine,
  onMouseEnter,
  onMouseLeave,
}: SpirometrySectionChartProps) {
  const [selectedPoint, setSelectedPoint] =
    useState<MeasurementPayloadType | null>(null);

  const [updateGraph, setUpdateGraph] = useState(false);
  const [highlighetdLineIndex, setHighlighetdLineIndex] =
    useState(highlightedLine);

  const handleOnClickOverActiveDots = (selectedPointInfo: any) => {
    if (onRowClick) {
      setSelectedPoint(selectedPointInfo);
      onRowClick(selectedPointInfo as MeasurementPayloadType);
    }
  };

  const handleXDomain = () => {
    if (dateInterval === (null || undefined) || data === (null || undefined))
      return [0, 1];

    const currentDate = dateInterval.startDate;
    const { endDate } = dateInterval;

    return [currentDate.valueOf(), endDate.valueOf()];
  };

  const handleYDomain = () => {
    if (
      chartConfig[0].min === (null || undefined) ||
      chartConfig[0].max === (null || undefined)
    ) {
      return undefined;
    }

    return [chartConfig[0].min, chartConfig[0].max];
  };

  const handleMultipleLines = (currentChartConfig: any) => {
    const lines: any = [];
    const linesColors = [
      "#8974D4",
      "#69B6E1",
      "#DAA676",
      "#89D7AB",
      "#CE75C5",
      "#F5B0C0",
      "#FDDF94",
      "#939393",
    ];

    const handleLineOpacity = (index: number) => {
      if (highlighetdLineIndex === undefined) {
        return 1;
      }

      if (highlighetdLineIndex === index) {
        return 1;
      }

      return 0.28;
    };

    data?.forEach((line, index) => {
      if (linesToShow && linesToShow[index]) {
        lines.push(
          <Line
            key={`${currentChartConfig.yKey}_${index}_${currentChartConfig.color}`}
            connectNulls
            isAnimationActive={false}
            type={currentChartConfig?.lineType || "monotone"}
            strokeWidth={currentChartConfig.stroke || 1.4}
            dot={
              currentChartConfig.dot && (
                <CustomModalChartDot
                  selectedValue={selectedPoint}
                  color={currentChartConfig.color}
                  onDotClick={handleOnClickOverActiveDots}
                />
              )
            }
            label={index}
            style={{ cursor: "pointer" }}
            opacity={handleLineOpacity(index)}
            dataKey={currentChartConfig.yKey}
            stroke={linesColors[index]}
            data={line}
            onMouseEnter={() => {
              if (onMouseEnter) {
                onMouseEnter(index);
              }
              setHighlighetdLineIndex(index);
            }}
            onMouseLeave={() => {
              if (onMouseLeave) {
                onMouseLeave();
              }
              setHighlighetdLineIndex(undefined);
            }}
          />
        );
      }
    });

    return lines;
  };

  useEffect(() => {
    setUpdateGraph(true);
    setUpdateGraph(false);
  }, [linesToShow]);

  return (
    <ResponsiveContainer debounce={10} width={width} height={height}>
      <LineChart
        margin={{ right: 20, top: 8 }}
        data={data}
        onMouseLeave={() => {
          if (onMouseLeave) {
            onMouseLeave();
          }
        }}
      >
        <CartesianGrid strokeDasharray="2" vertical={false} />
        <YAxis
          tickMargin={8}
          stroke="#D8D8D8"
          allowDecimals={false}
          label={yConfig.label}
          domain={yConfig.domain || handleYDomain()}
          width={yConfig.axisSize || 48}
          tick={{ fill: "#727272", fontSize: "12px", fontWeight: 400 }}
        />
        <XAxis
          tickMargin={14}
          stroke="#D8D8D8"
          type="number"
          label={xConfig?.label}
          domain={handleXDomain()}
          tickFormatter={xConfig?.tickFormatter}
          allowDuplicatedCategory={false}
          height={xConfig?.axisSize || 48}
          dataKey={xConfig?.dataKey || "unformattedTime"}
          allowDecimals={xConfig?.allowDecimals}
          minTickGap={xConfig?.minTickGap || 100}
          interval={xConfig?.interval || 0}
          tick={{ fill: "#727272", fontSize: "12px", fontWeight: 400 }}
        />
        {chartConfig.map((currentChartConfig: ChartConfig) =>
          !updateGraph ? handleMultipleLines(currentChartConfig) : ""
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}
