import { AxiosResponse } from "axios";

import {
  IAddEditStaffRequest,
  IAddEditStaffResponse,
  IGetStaffResponse,
  IHospitalStaffResponse,
} from "../@types/Staff";
import { api } from "./api";

export const staffService = {
  async getHospitalStaff(): Promise<AxiosResponse<IHospitalStaffResponse[]>> {
    const url = "/Portal/Staff/GetHospitalStaff";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getHospitalStaffById(
    staffId: number
  ): Promise<AxiosResponse<IGetStaffResponse>> {
    const url = `/Portal/Staff/GetStaffById/${staffId}`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async addHospitlStaff(
    staffData: IAddEditStaffRequest
  ): Promise<AxiosResponse<IAddEditStaffResponse>> {
    const url = `/Portal/Staff`;

    const serverResponse = await api.post(url, staffData);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async editHospitlStaff(
    staffId: number,
    staffData: IAddEditStaffRequest
  ): Promise<AxiosResponse<IAddEditStaffResponse>> {
    const url = `/Portal/Staff/${staffId}`;

    const serverResponse = await api.put(url, staffData);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async archiveHospitlStaff(staffId: number): Promise<AxiosResponse<boolean>> {
    const url = `/Portal/Staff/Archive/${staffId}`;

    const serverResponse = await api.put(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async reactivateHospitlStaff(
    staffId: number
  ): Promise<AxiosResponse<boolean>> {
    const url = `/Portal/Staff/Reactivate/${staffId}`;

    const serverResponse = await api.put(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateNotifyByEmailOnMessageFlag(
    notifyByEmailOnMessage: boolean
  ): Promise<AxiosResponse<boolean>> {
    const url = `/Portal/Staff/UpdateNotifyByEmailOnMessageFlag`;

    const serverResponse = await api.put(url, {
      NotifyByEmailOnMessage: notifyByEmailOnMessage,
    });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
