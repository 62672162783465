import { styled } from "@patientmpower/spiro";

// Components
export const SurveysListTableContainer = styled("section", {
  position: "relative",
  height: "calc(100vh - 200px)",
  maxHeight: "800px",
});

export const AddSurveyButton = styled("div", {
  width: "max-content",
  height: "26px",
  padding: "5px 11px",

  backgroundColor: "#212121",
  borderRadius: "12px",
  color: "$white",

  fontSize: "12px",
  lineHeight: "16px",
  fontFamily: "$openSans",
  fontWeight: "500",

  position: "absolute",
  top: "-32px",
  right: "10px",

  cursor: "pointer",
});
