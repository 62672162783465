import { Table } from "@patientmpower/spiro";

import { ReactComponent as NoDevicesImage } from "../../../../../../assets/images/no-devices.svg";
import { TableSkeleton } from "../../../../../../components/TableSkeleton";
import { ImageContainer } from "../../../PatientNotesTable/PatientNotesTable.style";
import {
  DevicesTableContainer,
  DevicesTableInnerContainer,
  NoContentMessage,
} from "./DevicesTable.style";
import { devicesTableColumns } from "./tableColumns";

interface IDvicesTableProps {
  content?: any;
  isLoading?: boolean;
}

export function DevicesTable({
  content,
  isLoading = false,
}: IDvicesTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={4} rowsNumber={6} />;
  }

  if (content && content.length > 0) {
    return (
      <DevicesTableContainer>
        <DevicesTableInnerContainer>
          <Table
            rowPadding
            data={content}
            emptyStateText="No data to show"
            columns={devicesTableColumns}
          />
        </DevicesTableInnerContainer>
      </DevicesTableContainer>
    );
  }

  return (
    <ImageContainer>
      <NoDevicesImage width={181} height={158} />
      <NoContentMessage>
        No devices have been added yet. See how to add devices or troubleshoot
        issues here.
      </NoContentMessage>
    </ImageContainer>
  );
}
