import dayjs, { ManipulateType } from "dayjs";

import { DateFilterOptionType } from "../pages/PatientPage/components/MeasurementModal/components/CustomOptionsBar";

type GraphSeparatorResult = {
  graphSeparators: number[];
  dates: number[];
  separatorCounter: number;
  tickFormat: string;
  viewType: string;
  isTickMultipleLine: boolean;
};

export function getGraphSeparators(
  dateInterval: DateFilterOptionType | undefined,
  minDate: dayjs.Dayjs | undefined,
  maxDate: dayjs.Dayjs | undefined
): GraphSeparatorResult {
  const graphSeparators: number[] = [];
  const dates: number[] = [];

  let separatorCounter = 0;
  let tickFormat = "";
  let viewType = "";
  let isTickMultipleLine = false;

  if (!dateInterval) {
    return {
      graphSeparators,
      dates,
      separatorCounter,
      tickFormat,
      viewType,
      isTickMultipleLine,
    };
  }

  let currentDate = dateInterval.startDate;
  let { endDate } = dateInterval;

  if (currentDate.isSame(endDate, "day")) {
    endDate = endDate.add(1, "day").add(-1, "millisecond");
  }

  if (minDate && maxDate && endDate.diff(currentDate, "year") > 5) {
    currentDate = minDate;
    endDate = maxDate;
  }

  const tickInterval = endDate.valueOf() - currentDate.valueOf(); // millisecond difference

  let incrementValue = 1;
  let unitValue: ManipulateType = "days";
  let startsAtMonday = false;

  switch (true) {
    case tickInterval <= 86400000: // 24 hours
      incrementValue = 30;
      unitValue = "minutes";
      separatorCounter = 4;
      tickFormat = "HH:mm";
      break;
    case tickInterval > 86400000 && tickInterval <= 172800000: // 48 hours
      incrementValue = 3;
      unitValue = "hours";
      separatorCounter = 4;
      tickFormat = "MMM D,HH:mm";
      isTickMultipleLine = true;
      break;
    case tickInterval > 172800000 && tickInterval <= 604800000: // 7 days
      incrementValue = 12;
      unitValue = "hours";
      separatorCounter = 2;
      tickFormat = "MMM D";
      break;
    case tickInterval > 604800000 && tickInterval <= 5270400000: // 2 months
      incrementValue = 2;
      unitValue = "days";
      separatorCounter = 2;
      tickFormat = "MMM D";
      break;
    case tickInterval > 5270400000 && tickInterval <= 15724800000: // 6 months
      unitValue = "week";
      tickFormat = "MMM";
      startsAtMonday = true;
      viewType = "monthly";
      break;
    case tickInterval > 15724800000 && tickInterval <= 31536000000: // 1 year
      unitValue = "month";
      tickFormat = "MMM";
      viewType = "yearly";
      break;
    case tickInterval > 31536000000 && tickInterval <= 126144000000: // Greater than 1 year, less than 4 years
      unitValue = "month";
      tickFormat = "Q";
      viewType = "yearly";
      break;
    case tickInterval > 126144000000: // Greater than 4 years
      unitValue = "year";
      tickFormat = "YYYY";
      viewType = "multiYear";
      break;
    default:
      break;
  }

  if (viewType === "monthly" || viewType === "yearly") {
    let dateToAdd = currentDate;

    while (dateToAdd <= endDate) {
      if (tickFormat !== "Q") {
        dateToAdd = dateToAdd.add(1, "month").startOf("month");
        dates.push(dateToAdd.valueOf());
        graphSeparators.push(dateToAdd.valueOf());
      } else {
        dateToAdd = dateToAdd.startOf("month");

        if (
          dateToAdd.month() !== 0 &&
          dateToAdd.month() !== 3 &&
          dateToAdd.month() !== 6 &&
          dateToAdd.month() !== 9
        ) {
          dateToAdd = dateToAdd.add(1, "month").startOf("month");
        } else {
          graphSeparators.push(dateToAdd.valueOf());
          dateToAdd = dateToAdd.add(3, "month").startOf("month");
        }
      }
    }

    dates.pop();
  }

  if (viewType === "multiYear") {
    let dateToAdd = currentDate;

    while (dateToAdd <= endDate) {
      dateToAdd = dateToAdd.add(1, "year").startOf("year");
      dates.push(dateToAdd.valueOf());
      graphSeparators.push(dateToAdd.valueOf());
    }

    dates.pop();
  }

  while (currentDate <= endDate && viewType !== "yearly") {
    if (startsAtMonday) {
      if (currentDate.day() !== 1) {
        const daysUntilMonday = 8 - currentDate.day();

        currentDate = dayjs(currentDate).add(daysUntilMonday, "days");
        dates.push(currentDate.valueOf());
      }

      startsAtMonday = false;
    } else {
      dates.push(currentDate.valueOf());
    }

    currentDate = dayjs(currentDate).add(incrementValue, unitValue);
  }

  dates.push(currentDate.valueOf());

  return {
    graphSeparators,
    dates,
    separatorCounter,
    tickFormat,
    viewType,
    isTickMultipleLine,
  };
}
