import { styled, css } from "@patientmpower/spiro";

export const ModalContainer = styled("div", {
  padding: "32px 28px",
});

export const ContentContainer = styled("div", {
  width: "100%",
  height: "100%",

  margin: "40px 0 10px 0",
  padding: "28px 32px 28px",
  borderRadius: "8px",
  backgroundColor: "$gray-20",

  display: "flex",
  flexDirection: "column",
  gap: "36px",

  "@media only screen and (max-width: 1250px)": {
    overflowY: "scroll",
    maxHeight: "350px",
    height: "100%",
  },
});

export const FormField = styled("div", {
  height: "72px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  position: "relative",

  ".ant-select": {
    height: "42px !important",
  },

  p: {
    color: "$gray-90",
    fontFamily: "$openSans",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",

    marginLeft: "4px",
  },

  "@media only screen and (max-width: 1250px)": {
    flexDirection: "row",
    height: "52px",
  },
});

export const Row = styled("div", {
  width: "100%",

  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: "28px",

  "@media only screen and (max-width: 1250px)": {
    flexDirection: "column",
  },
});

export const CheckboxContainer = styled("div", {
  width: "474px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "12px",

  p: {
    color: "$gray-90",
    fontFamily: "$openSans",
    fontSize: "14px",
    height: "19px",
  },

  ".ant-checkbox-inner": {
    borderColor: "$gray-100 !important",
  },

  ".ant-checkbox-checked .ant-checkbox-inner": {
    borderColor: "$primary !important",
  },

  variants: {
    alignItemsStart: {
      true: {
        alignItems: "flex-start",
      },
    },
  },
});

export const SubmitContainer = styled("div", {
  height: "70px",
  display: "flex",
  gap: "40px",
  padding: "26px 12px 0px 0px",
  alignItems: "center",
});

export const ErrorMessage = styled("p", {
  fontSize: "12px !important",
  fontWeight: "600 !important",

  lineHeight: "16px !important",

  color: "$error-light !important",

  position: "absolute",
  top: "74px",

  "@media only screen and (max-width: 1250px)": {
    top: "45px",
    left: "290px",
  },
});

export const ServerErrorMessage = styled("p", {
  fontSize: "12px !important",
  fontWeight: "600 !important",

  lineHeight: "16px !important",

  color: "$error-light !important",
});

// css

export const inputCss = css({
  width: "188px",
  height: "42px",

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    "&:hover": {
      border: "2px solid $primary-30",
    },
  },
});

export const submitButtonCss = css({
  width: "168px",

  button: {
    width: "100%",

    p: {
      margin: "auto",
    },
  },
});

export const cancelButtonCss = css({
  width: "168px",

  button: {
    width: "100%",

    p: {
      margin: "auto",
    },
  },
});

export const reactPhoneInputCss = css({
  width: "250px !important",
  height: "42px !important",
  zIndex: "2000",

  input: {
    border: "1px solid $gray-50 !important",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    color: "$gray-90 !important",
    fontFamily: "$openSans !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    lineHeight: "22px !important",

    "&:hover": {
      borderColor: "$primary-30 !important",
    },
  },

  ".form-control": {
    width: "100% !important",
    height: "100% !important",

    "&:focus": {
      boxShadow: "none !important",
    },
  },

  ".selected-flag": {
    "&::before": {
      borderColor: "transparent !important",
      boxShadow: "none !important",
    },
  },

  ".flag-dropdown": {
    ".country-list": {
      width: "250px !important",

      ".search": {
        ".search-box": {
          width: "220px",
          paddingTop: "5px",
        },
        "&::placeholder": {
          color: "$gray-80",
        },
      },

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
});
