export function Message({ ...rest }) {
  return (
    <svg
      {...rest}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        d="M3.45254 13.8107L1.46726 15.7959C1.19392 16.0693 0.881026 16.1304 0.528567 15.9794C0.176108 15.8283 -0.00012207 15.5586 -0.00012207 15.1702V1.72635C-0.00012207 1.25161 0.168915 0.845202 0.506988 0.507129C0.845061 0.169056 1.25147 1.90735e-05 1.72621 1.90735e-05H15.5369C16.0116 1.90735e-05 16.418 0.169056 16.7561 0.507129C17.0942 0.845202 17.2632 1.25161 17.2632 1.72635V12.0843C17.2632 12.5591 17.0942 12.9655 16.7561 13.3036C16.418 13.6416 16.0116 13.8107 15.5369 13.8107H3.45254ZM2.71885 12.0843H15.5369V1.72635H1.72621V13.0554L2.71885 12.0843ZM4.31571 10.358H9.4947C9.73926 10.358 9.94426 10.2753 10.1097 10.1098C10.2751 9.9444 10.3579 9.7394 10.3579 9.49484C10.3579 9.25028 10.2751 9.04527 10.1097 8.87983C9.94426 8.71439 9.73926 8.63167 9.4947 8.63167H4.31571C4.07114 8.63167 3.86614 8.71439 3.7007 8.87983C3.53526 9.04527 3.45254 9.25028 3.45254 9.49484C3.45254 9.7394 3.53526 9.9444 3.7007 10.1098C3.86614 10.2753 4.07114 10.358 4.31571 10.358ZM4.31571 7.76851H12.9474C13.1919 7.76851 13.3969 7.68579 13.5624 7.52035C13.7278 7.35491 13.8105 7.14991 13.8105 6.90534C13.8105 6.66078 13.7278 6.45578 13.5624 6.29034C13.3969 6.1249 13.1919 6.04218 12.9474 6.04218H4.31571C4.07114 6.04218 3.86614 6.1249 3.7007 6.29034C3.53526 6.45578 3.45254 6.66078 3.45254 6.90534C3.45254 7.14991 3.53526 7.35491 3.7007 7.52035C3.86614 7.68579 4.07114 7.76851 4.31571 7.76851ZM4.31571 5.17901H12.9474C13.1919 5.17901 13.3969 5.09629 13.5624 4.93085C13.7278 4.76541 13.8105 4.56041 13.8105 4.31585C13.8105 4.07128 13.7278 3.86628 13.5624 3.70084C13.3969 3.5354 13.1919 3.45268 12.9474 3.45268H4.31571C4.07114 3.45268 3.86614 3.5354 3.7007 3.70084C3.53526 3.86628 3.45254 4.07128 3.45254 4.31585C3.45254 4.56041 3.53526 4.76541 3.7007 4.93085C3.86614 5.09629 4.07114 5.17901 4.31571 5.17901Z"
        fill="#727272"
      />
    </svg>
  );
}
