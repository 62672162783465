export function BlackPlus({ ...rest }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4167 11.9168H11.9167V17.4168H10.0834V11.9168H4.58337V10.0835H10.0834V4.5835H11.9167V10.0835H17.4167V11.9168Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
