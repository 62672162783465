export function People({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24998 10.9999C10.0191 10.9999 11.4583 9.56075 11.4583 7.79159C11.4583 6.02242 10.0191 4.58325 8.24998 4.58325C6.48081 4.58325 5.04165 6.02242 5.04165 7.79159C5.04165 9.56075 6.48081 10.9999 8.24998 10.9999ZM1.83331 15.8124C1.83331 13.6766 6.10498 12.6041 8.24998 12.6041C10.395 12.6041 14.6666 13.6766 14.6666 15.8124V17.4166H1.83331V15.8124ZM8.24998 14.4374C6.60915 14.4374 4.74831 15.0516 3.97831 15.5833H12.5216C11.7516 15.0516 9.89081 14.4374 8.24998 14.4374ZM9.62498 7.79159C9.62498 7.03075 9.01081 6.41659 8.24998 6.41659C7.48915 6.41659 6.87498 7.03075 6.87498 7.79159C6.87498 8.55242 7.48915 9.16659 8.24998 9.16659C9.01081 9.16659 9.62498 8.55242 9.62498 7.79159ZM14.7033 12.6591C15.7666 13.4291 16.5 14.4558 16.5 15.8124V17.4166H20.1666V15.8124C20.1666 13.9608 16.9583 12.9066 14.7033 12.6591ZM16.9583 7.79159C16.9583 9.56075 15.5191 10.9999 13.75 10.9999C13.255 10.9999 12.7966 10.8808 12.375 10.6791C12.9525 9.86325 13.2916 8.86409 13.2916 7.79159C13.2916 6.71909 12.9525 5.71992 12.375 4.90409C12.7966 4.70242 13.255 4.58325 13.75 4.58325C15.5191 4.58325 16.9583 6.02242 16.9583 7.79159Z"
      />
    </svg>
  );
}
