import {
  IArticlesFixedTranslationsKeys,
  ISurveysFixedTranslationsKeys,
  ITranslationResponse,
} from "../@types/Translations";

export const parseSurveyTranslationResponseToDisplay = (
  translationsArray: ITranslationResponse[]
): ISurveysFixedTranslationsKeys => {
  const resources: ISurveysFixedTranslationsKeys = {
    survey_fixed_translations_survey_navigation_start: "",
    survey_fixed_translations_survey_navigation_next: "",
    survey_fixed_translations_survey_navigation_previous: "",
    survey_fixed_translations_survey_navigation_submit: "",
    survey_fixed_translations_five_questions_question_one_description: "",
    survey_fixed_translations_five_questions_question_one_option_one: "",
    survey_fixed_translations_five_questions_question_one_option_two: "",
    survey_fixed_translations_five_questions_question_two_description: "",
    survey_fixed_translations_five_questions_question_two_option_one: "",
    survey_fixed_translations_five_questions_question_two_option_two: "",
    survey_fixed_translations_five_questions_question_two_option_three: "",
    survey_fixed_translations_five_questions_question_two_option_four: "",
    survey_fixed_translations_five_questions_question_three_description: "",
    survey_fixed_translations_five_questions_question_four_description: "",
    survey_fixed_translations_five_questions_question_five_description: "",
  };

  translationsArray.forEach((translation: ITranslationResponse) => {
    resources[translation.key as keyof typeof resources] = translation.value;
  });

  return resources;
};

export const parseArticlesTranslationResponseToDisplay = (
  translationsArray: ITranslationResponse[]
): IArticlesFixedTranslationsKeys => {
  const resources: IArticlesFixedTranslationsKeys = {
    articles_fixed_translations_view_all_articles: "",
    articles_fixed_translations_other_articles: "",
  };

  translationsArray.forEach((translation: ITranslationResponse) => {
    resources[translation.key as keyof typeof resources] = translation.value;
  });

  return resources;
};
