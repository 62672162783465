import dayjs, { Dayjs } from "dayjs";

export const calendarDateFilterOptions = [
  {
    label: dayjs().subtract(5, "month").format("MMM"),
    value: (currentDate: Dayjs) =>
      currentDate.subtract(5, "month").startOf("M"),
  },
  {
    label: dayjs().subtract(4, "month").format("MMM"),
    value: (currentDate: Dayjs) =>
      currentDate.subtract(4, "month").startOf("M"),
  },
  {
    label: dayjs().subtract(3, "month").format("MMM"),
    value: (currentDate: Dayjs) =>
      currentDate.subtract(3, "month").startOf("M"),
  },
  {
    label: dayjs().subtract(2, "month").format("MMM"),
    value: (currentDate: Dayjs) =>
      currentDate.subtract(2, "month").startOf("M"),
  },
  {
    label: dayjs().subtract(1, "month").format("MMM"),
    value: (currentDate: Dayjs) =>
      currentDate.subtract(1, "month").startOf("M"),
  },
  {
    label: dayjs().format("MMM"),
    value: (currentDate: Dayjs) => currentDate.startOf("M"),
  },
  {
    label: dayjs().add(1, "month").format("MMM"),
    value: (currentDate: Dayjs) => currentDate.add(1, "month").startOf("M"),
  },
  {
    label: dayjs().add(2, "month").format("MMM"),
    value: (currentDate: Dayjs) => currentDate.add(2, "month").startOf("M"),
  },
  {
    label: dayjs().add(3, "month").format("MMM"),
    value: (currentDate: Dayjs) => currentDate.add(3, "month").startOf("M"),
  },
  {
    label: dayjs().add(4, "month").format("MMM"),
    value: (currentDate: Dayjs) => currentDate.add(4, "month").startOf("M"),
  },
  {
    label: dayjs().add(5, "month").format("MMM"),
    value: (currentDate: Dayjs) => currentDate.add(5, "month").startOf("M"),
  },
];
