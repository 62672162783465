import { css, styled } from "@patientmpower/spiro";

// components

export const DateAnswerField = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "20px",
  marginTop: "30px",
});

export const dateInputCss = css({
  width: "221px",
  alignSelf: "flex-start",

  ".ant-picker-input": {
    input: {
      fontSize: "16px",
      fontFamily: "$openSans",
      fontWeight: "400",
    },
    ".ant-picker-suffix": {
      display: "none",
    },
  },

  "&:hover": {
    borderColor: "rgba(189, 180, 221, 1)",
  },

  "&:focus-within": {
    borderColor: "rgba(189, 180, 221, 1)",
    boxShadow: "none",
  },
});

export const datePopUptCss = css({
  ".ant-picker-cell-inner": {
    "&::before": {
      border: "1px solid #7562A8 !important",
    },
  },

  ".ant-picker-cell-selected": {
    div: {
      background: "#7562A8 !important",
    },
  },

  ".ant-picker-now-btn": {
    color: "#7562A8",
  },
});
