import { Container, Title } from "./PageTitle.styles";

type IPageTitleProps = {
  children: React.ReactNode;
};

type IMobilePageTitleContainerProps = {
  children: React.ReactNode;
};

export function PageTitle({ children, ...props }: IPageTitleProps) {
  return <Title {...props}>{children}</Title>;
}

export function MobilePageTitleContainer({
  children,
}: IMobilePageTitleContainerProps) {
  return <Container>{children}</Container>;
}
