import * as DialogPrimitive from "@radix-ui/react-dialog";
import React, { useEffect, useRef, useState } from "react";

import { OptionsModalType } from "../../@types/Modal";
import { Close } from "../../assets/icons/Close";
import { CustomIconButton } from "../CustomIconButton";
import {
  StyledDialogClose,
  StyledDialogContent,
  StyledDialogOverlay,
  StyledTile,
} from "./Modal.styles";

type ModalProps = React.ComponentProps<typeof DialogPrimitive.Root> &
  DialogPrimitive.DialogContentProps & {
    optionsModal: OptionsModalType;
  };

export function Modal({ children, optionsModal, ...props }: ModalProps) {
  const [hasTabed, setHasTabed] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleTabTrue = (event: any) => {
      if (event.key === "Tab" && !hasTabed) {
        setHasTabed(true);
      }
    };
    if (props.open && modalRef.current) {
      (modalRef.current as HTMLElement).focus();
    }

    document.addEventListener("keydown", handleTabTrue, true);
    return () => {
      setHasTabed(false);
    };
  }, [props.open]);

  return (
    <DialogPrimitive.Root {...props}>
      <StyledDialogOverlay ref={modalRef}>
        <StyledDialogContent>
          <StyledTile
            css={{
              width: optionsModal.width || "925px",
              height: optionsModal.height || "auto",
              maxHeight: optionsModal.maxHeight || undefined,
              padding: optionsModal.padding || "16px",
            }}
          >
            {optionsModal.showCloseButton ? (
              <StyledDialogClose outline={hasTabed}>
                <CustomIconButton>
                  <Close />
                </CustomIconButton>
              </StyledDialogClose>
            ) : null}

            {children}
          </StyledTile>
        </StyledDialogContent>
      </StyledDialogOverlay>
    </DialogPrimitive.Root>
  );
}
