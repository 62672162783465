import { Dropdown, DropdownTextItem } from "@patientmpower/spiro";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { IDevice } from "../../../../@types/Devices";
import { Notification } from "../../../../@types/Notification";
import { ISectionProps } from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { Dots } from "../../../../assets/icons/Dots";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { ActionContainer } from "../../../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { ToastNotification } from "../../../../components/ToastNotification";
import { useDevices } from "../../../../hooks/queries/devices";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { getLongFormattedDate } from "../../../../utils/dateFormatter";
import { AddDeviceModal } from "./Components/AddDeviceModal/AddDeviceModal";
import { DeleteDeviceModal } from "./Components/DeleteDeviceModal";
import { DevicesTable } from "./Components/DevicesTable/DevicesTable";
import {
  AddDeviceButton,
  NewDataCOntainer,
  TitleContainer,
} from "./DevicesSection.styles";

export function DevicesSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: "max-content",
    rightMargin: "50%",
  });
  const [showNewData, setShowNewData] = useState<boolean>(false);

  const { isMobile } = useIsMobile();
  const { devices, isFetching, isRefetching, refetch } = useDevices(patient.id);
  const { openModal, closeModal } = useModal();

  const handleOnClose = (
    notification?: Notification,
    refetchTable?: boolean
  ) => {
    if (notification !== undefined) {
      setNotification(notification);

      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: undefined,
        });
      }, 3500);
    }

    if (refetchTable) {
      refetch();
    }

    closeModal();
  };

  const handleDeleteDevice = (content: IDevice) => {
    openModal(<DeleteDeviceModal content={content} onClose={handleOnClose} />, {
      width: "611px",
      height: "363px",
      showCloseButton: true,
    });
  };

  const handleAddDevice = () => {
    openModal(
      <AddDeviceModal patientId={patient.id} onClose={handleOnClose} />,
      {
        width: "auto",
        height: "auto",
        showCloseButton: true,
      }
    );
  };

  const parsedDataToDeviceTable = useMemo(() => {
    if (!devices) return [];

    return devices.map((device: IDevice) => {
      return {
        name: device.name,
        added: getLongFormattedDate(device.createdAt),
        identifier: device.identifier,
        firstUsed:
          device.firstUsed !== undefined
            ? getLongFormattedDate(device.firstUsed)
            : "-",
        lastUsed:
          device.lastUsed !== undefined
            ? getLongFormattedDate(device.lastUsed)
            : "-",
        action: (
          <Dropdown
            trigger={
              <ActionContainer className="actionContainer">
                <CustomIconButton>
                  <Dots />
                </CustomIconButton>
              </ActionContainer>
            }
            value=""
            onValueChange={(val: string) => {
              if (val === "Delete") handleDeleteDevice(device);
            }}
          >
            <DropdownTextItem key="createdAt" text="Delete" />
          </Dropdown>
        ),
      };
    });
  }, [devices]);

  useEffect(() => {
    if (
      collapsedSection.value === true &&
      !isFetching &&
      !isRefetching &&
      devices &&
      devices.length > 0
    ) {
      const collapsedDate = dayjs(collapsedSection.collapsedDate);

      devices?.forEach((device: IDevice) => {
        const lastAdded = dayjs(device.createdAt);
        const firstUsed = device.firstUsed
          ? dayjs(device.firstUsed)
          : dayjs("2000-01-01");
        const lastUsed = device.lastUsed
          ? dayjs(device.lastUsed)
          : dayjs("2000-01-01");

        if (
          lastAdded > collapsedDate ||
          firstUsed > collapsedDate ||
          lastUsed > collapsedDate
        ) {
          setShowNewData(true);
        }
      });
    }
  }, [devices]);

  const handleCollapseSection = () => {
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
    updateColapsedSections("devicesSection");
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Devices</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}
      <div
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <AddDeviceButton
          style={{ display: collapsedSection.value ? "none" : "" }}
          onClick={handleAddDevice}
        >
          Add device
        </AddDeviceButton>
        <DevicesTable
          content={parsedDataToDeviceTable}
          isLoading={isFetching || isRefetching}
        />
      </div>
      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </>
  );
}
