export type CardConfig = {
  name: string;
  isEditable: boolean;
  path?: string;
};

export const cardsCofigs: CardConfig[] = [
  {
    name: "DOB",
    isEditable: true,
    path: "/dob",
  },
  {
    name: "Hospital ID",
    isEditable: true,
    path: "/hospital_patient_id",
  },
  {
    name: "Email",
    isEditable: true,
    path: "/email",
  },
  {
    name: "Phone",
    isEditable: true,
    path: "/phone_number",
  },
  {
    name: "Weight (kg)",
    isEditable: true,
    path: "/weight",
  },
  {
    name: "Height (cm)",
    isEditable: true,
    path: "/height",
  },
  {
    name: "Country",
    isEditable: true,
    path: "/country_id",
  },
  {
    name: "Condition",
    isEditable: true,
    path: "/condition",
  },
  {
    name: "Caregiver name",
    isEditable: true,
    path: "/caregiver_name",
  },
  {
    name: "Caregiver phone number",
    isEditable: true,
    path: "/caregiver_phone_number",
  },
  {
    name: "Gender",
    isEditable: true,
    path: "/sex",
  },
  {
    name: "Ethnicity",
    isEditable: true,
    path: "/ethnicity",
  },
];
