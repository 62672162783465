import { Counter } from "./AlertsCounter.styles";

type AlertsCounterProps = React.ComponentProps<typeof Counter> & {
  children: React.ReactNode;
};

export function AlertsCounter({ children, ...rest }: AlertsCounterProps) {
  interface IStringByString {
    [key: string]: string;
  }

  const abbreviations: IStringByString = {
    dry_weight: "Dry Weight",
    weight: "Weight",
    height: "Height",
    bp_systolic: "BP Systolic",
    bp_diastolic: "BP Diastolic",
    creatinine: "Creatinine",
    hemoglobin: "Hemoglobin",
    cni_trough: "Tacrolimus",
    white_cells: "White Cells",
    viral_load: "Viral Load",
    psa: "PSA",
    blood_glucose: "Blood Glucose",
    hba1c: "HbA1c",
    heart_rate: "Heart Rate",
    pulse_ox: "Oxygen Saturation",
    temperature: "Temperature",
    dyspnea: "Breathlessness",
    fvc: "FVC",
    pef: "PEF",
    fev1: "FEV1",
    fev1_fvc: "FEV1/FVC",
    fev6: "FEV6",
    fev25_75: "FEF25/75",
    phosphate: "Phosphate",
    esr: "ESR",
    crp: "CRP",
    urine_produced: "Urine Produced",
    oxygen_rate: "Oxygen Flow Rate",
    fluid_intake: "Fluid Intake",
    jp_drain: "Jason-Pratt Drain",
    sputum_volume: "Sputum Volume",
    respiratory_rate: "Respiratory Rate",
    barthel_index: "Modified Barthel Index",
    rockwood_index: "Rockwood Frailty Index",
  };

  let numberOfAlerts = children?.toString();
  let wideAlertSize;

  if (numberOfAlerts) {
    const parsedAlerts = Number(numberOfAlerts);

    if (Number.isNaN(parsedAlerts) && numberOfAlerts.length >= 2) {
      numberOfAlerts = abbreviations[numberOfAlerts] || numberOfAlerts;
      wideAlertSize = {
        width: `${26 + (numberOfAlerts.length - 1) * 6}px`,
      };
    } else if (parsedAlerts > 9) {
      numberOfAlerts = "9+";
    }
  }

  return (
    <Counter style={wideAlertSize} {...rest}>
      {numberOfAlerts}
    </Counter>
  );
}
