import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { Notification } from "../../../../@types/Notification";
import { PatientAlertsTableContent } from "../../../../@types/Patient";
import { ReactComponent as ErrorCheckingAlertsImage } from "../../../../assets/images/error-checking-alerts.svg";
import { ReactComponent as NoAlertTriggeredImage } from "../../../../assets/images/no-alert-triggered.svg";
import { Table } from "../../../../components/Table";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { ToastNotification } from "../../../../components/ToastNotification";
import { getPatientAlertsData } from "../../../../hooks/queries/patients";
import { useModal } from "../../../../hooks/useModal";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { desktopTableColumns } from "../AlertsSection/patientAlertsTableColumns";
import { ResolveTriggerModal } from "../ResolveTriggerModal";
import { ManageTriggersModal } from "./components/ManageTriggersModal";
import {
  AlertsTableContainer,
  AlertsTableInnerContainer,
  ImageContainer,
  ManageTriggersTitle,
  NoContentMessage,
} from "./PatientAlertsTable.style";

type PatientAlertTableProps = {
  patientId: number;
  content: PatientAlertsTableContent[];
  isLoading?: boolean;
  isError?: boolean;
  updateDropdown: () => void;
  refreshTable: () => void;
  showManageTriggersButton: boolean;
};

export function PatientAlertsTable({
  patientId,
  isLoading,
  isError,
  content,
  showManageTriggersButton,
  updateDropdown,
  refreshTable,
}: PatientAlertTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={7} />;
  }

  if (isError) {
    return (
      <ImageContainer>
        <ErrorCheckingAlertsImage />
        <p>
          Error checking alerts, please{" "}
          <button type="button" onClick={refreshTable}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </ImageContainer>
    );
  }

  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const [highlightedRows, setHighlightedRows] = useState<any>([]);

  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
  });

  const { refetch } = getPatientAlertsData(patientId);

  useEffect(() => {
    const rows: any[] = [];
    if (content && content.length > 0) {
      content.forEach((row) => {
        rows.push(typeof row.alertStatus !== "string");
      });

      setHighlightedRows(rows);
    }
  }, [content]);

  const handleCloseModal = (
    type: string,
    showNotification: boolean,
    success?: boolean
  ) => {
    if (showNotification && success) {
      setNotification({
        show: true,
        message: `${type} alert successfully resolved`,
        type: "success",
      });

      setTimeout(() => {
        setNotification({ show: false, message: "", type: "error" });
      }, 3500);

      mixpanelActions.track("User Action: Resolve Alert");

      updateDropdown();
      refetch();
      queryClient.refetchQueries([
        `patient-notes-table-${patientId}`,
        patientId,
      ]);
    } else if (showNotification && !success) {
      setNotification({
        show: true,
        message: `${type} alert failed to be resolved.`,
        type: "error",
      });

      setTimeout(() => {
        setNotification({ show: false, message: "", type: "error" });
      }, 3500);
    }

    closeModal();
  };

  const handleRowClick = (patientAlertContent: PatientAlertsTableContent) => {
    if (patientAlertContent.alertStatus !== "Resolved") {
      openModal(
        <ResolveTriggerModal
          patientId={patientId}
          alertIds={[patientAlertContent.id]}
          type={patientAlertContent.type}
          triggered={patientAlertContent.timeOfAlert.toString()}
          onClose={(showNotification, success) =>
            handleCloseModal(
              patientAlertContent.type,
              showNotification,
              success
            )
          }
        />,
        {
          width: "auto",
          height: "auto",
        }
      );
    }
  };

  const handleChildNotification = (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => {
    if (showNotification && notificationMessage && notificationType) {
      setNotification({
        show: showNotification,
        message: notificationMessage,
        type: notificationType,
        width: notificationWidth,
      });
      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: undefined,
        });
      }, 3500);
    }
  };

  const handleManageTriggers = () => {
    openModal(
      <ManageTriggersModal
        setNotification={handleChildNotification}
        patientId={patientId}
      />,
      {
        width: "100%",
        height: "100%",
        showCloseButton: true,
      }
    );
  };

  if (content && content.length > 0) {
    return (
      <>
        {showManageTriggersButton && (
          <ManageTriggersTitle onClick={handleManageTriggers}>
            Manage triggers
          </ManageTriggersTitle>
        )}
        <AlertsTableContainer>
          <AlertsTableInnerContainer>
            <Table
              data={content}
              columns={desktopTableColumns}
              onRowClick={handleRowClick}
              highlightedRows={highlightedRows}
              highlightColor={[
                "rgba(250, 209, 217, 0.48)",
                "rgba(250, 209, 217, 0.28)",
              ]}
            />
          </AlertsTableInnerContainer>
        </AlertsTableContainer>
        {notification.show ? (
          <ToastNotification
            message={notification.message}
            type={notification.type}
            width={notification.width}
          />
        ) : null}
      </>
    );
  }

  return (
    <>
      {showManageTriggersButton && (
        <ManageTriggersTitle onClick={handleManageTriggers}>
          Manage triggers
        </ManageTriggersTitle>
      )}
      <ImageContainer>
        <NoAlertTriggeredImage width={181} height={158} />
        <NoContentMessage>
          No alerts triggered yet. Ensure thresholds are correct in Manage
          triggers.
        </NoContentMessage>
      </ImageContainer>
    </>
  );
}
