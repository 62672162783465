import React, { createContext, ReactNode, useMemo, useState } from "react";

import { OptionsModalType } from "../@types/Modal";

type ModalContextData = {
  isModalOpen: boolean;
  closeModal: () => void;
  modalContent: React.ReactNode | null;
  openModal: (
    content: React.ReactNode,
    optionsModal?: OptionsModalType
  ) => void;
  optionsModal: OptionsModalType;
};

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalContext = createContext({} as ModalContextData);

export function ModalProvider({ children }: ModalProviderProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionsModal, setOptionsModal] = useState({} as OptionsModalType);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );

  function openModal(
    content: React.ReactNode,
    optionsModal?: OptionsModalType
  ) {
    if (optionsModal) {
      setOptionsModal(optionsModal);
    }

    setModalContent(content);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    setModalContent(null);
  }

  const modalValues = useMemo(() => {
    return {
      isModalOpen,
      modalContent,
      openModal,
      closeModal,
      optionsModal,
    };
  }, [isModalOpen]);

  return (
    <div>
      <ModalContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={modalValues}
      >
        {children}
      </ModalContext.Provider>
    </div>
  );
}
