import { AxiosResponse } from "axios";

import { IAddBaseline } from "../@types/Baselines";
import { api } from "./api";

export const baselinesService = {
  async addBaseline(request: IAddBaseline): Promise<AxiosResponse> {
    const url = "/Portal/PatientBaselines";

    const serverResponse = await api.post(url, request);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async archiveBaseline(
    patientId: number,
    measurementType: string
  ): Promise<AxiosResponse> {
    const url = "/Portal/PatientBaselines/ArchivePatientBaseline";

    const serverResponse = await api.post(url, null, {
      params: {
        patientId,
        measurementType,
      },
    });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
