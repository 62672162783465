import { styled } from "@patientmpower/spiro";

export const ImageContainer = styled("div", {
  overflowY: "overlay",
  maxHeight: "100%",

  zIndex: 1000,

  borderRadius: "8px",

  width: "100%",
  minHeight: "426px",

  border: "1px solid #ECECEC",
  backgroundColor: "$white",
  color: "$black",

  boxSizing: "border-box",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "10px",

  p: {
    width: "418px",
    marginTop: "30px",
    textAlign: "center",

    button: {
      background: "none",
      color: "#7562A8",
      border: "none",
      padding: 0,
      font: "inherit",
      cursor: "pointer",
      outline: "inherit",
      fontWeight: "600",
    },
  },
});

export const PromsTableContainer = styled("div", {
  width: "100%",
  height: "420px",
  fontSize: "14px",
  minHeight: "420px",
  overflow: "auto",
});

export const PromsTableInnerContainer = styled("div", {
  width: "100%",
  height: "420px",
  fontSize: "14px",
  minHeight: "420px",
  overflow: "auto",

  "@media screen and (max-width: 1100px)": {
    width: "1000px",
  },
});

export const NoContentMessage = styled("p", {
  marginTop: "10px !important",

  fontFamily: "$openSans",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
});
