import { Button } from "@patientmpower/spiro";

import { IWorkflow } from "../../../../../../@types/Worflows";
import {
  ButtonsContainer,
  CancelButton,
  purpleButton,
  whiteButton,
} from "../../WorkflowsBuilder.styles";

interface IActionButton {
  workflow?: IWorkflow;
  handleOnSaveWorkflow: (publish: boolean, isSave?: boolean) => void;
  purpleButtonIsLoading: boolean;
  whiteButtonIsLoading: boolean;
  handleCancel: () => void;
}

export function ActionButtons({
  workflow,
  handleOnSaveWorkflow,
  purpleButtonIsLoading,
  whiteButtonIsLoading,
  handleCancel,
}: IActionButton) {
  return (
    <ButtonsContainer>
      <CancelButton onClick={handleCancel} tabIndex={0}>
        Cancel
      </CancelButton>
      {!workflow?.published ? (
        <Button
          label="Save"
          type="submit"
          onClick={() =>
            handleOnSaveWorkflow(workflow?.published ?? false, true)
          }
          className={purpleButton()}
          isLoading={purpleButtonIsLoading}
        />
      ) : null}
      <Button
        label={workflow?.published ? "Publish changes" : "Publish"}
        type="button"
        onClick={() => handleOnSaveWorkflow(true)}
        className={whiteButton()}
        isLoading={whiteButtonIsLoading}
      />
    </ButtonsContainer>
  );
}
