import { keyframes, styled } from "@patientmpower/spiro";
import * as PopoverPrimitive from "@radix-ui/react-popover";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

export const PopoverContainer = styled("div", {
  alignSelf: "center",

  "@media screen and (max-width: 1100px)": {
    display: "none",
  },
});

export const StyledContent = styled(PopoverPrimitive.Content, {
  zIndex: 1100,

  marginTop: "4px",
  marginRight: "-32px",

  minWidth: 236,
  borderRadius: 8,

  position: "absolute",
  top: 15,
  right: "1.46%",

  display: "flex",
  flexDirection: "column",

  backgroundColor: "$white",

  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.22)",

  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export const PopoverTrigger = styled(PopoverPrimitive.Trigger, {
  alignSelf: "center",
});

export const IconButton = styled("button", {
  all: "unset",

  height: 36,
  width: 67,
  borderRadius: "20px",

  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",

  cursor: "pointer",
  color: "$gray-100",
  backgroundColor: "$gray-30",

  border: "2px solid transparent",

  "&:hover": {
    border: "2px solid #E6F3F3",
    backgroundColor: "$gray-40",
  },

  "&:focus-visible": {
    outline: "none",

    border: "2px solid #E6F3F3",
  },

  '&[data-state="open"]': {
    border: "2px solid #E6F3F3",
  },
});

export const PopoverOption = styled("button", {
  all: "unset",

  height: 59,
  paddingLeft: 15,
  paddingRight: 15,

  fontFamily: "$openSans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "$text-lg",
  lineHeight: "19px",

  cursor: "pointer",
  color: "$gray-100",
  backgroundColor: "white",

  borderBottom: "1px solid $gray-50",

  "&:hover": {
    backgroundColor: "$gray-30",
  },

  "&:focus-visible": {
    backgroundColor: "$gray-30",
  },

  "&:first-child": {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },

  "&:last-child": {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",

    borderBottom: "none",
  },
});

export const ProfileInfo = styled("div", {
  height: 150,
  paddingLeft: 15,
  paddingTop: 25,
  paddingRight: 15,
  textAlign: "center",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",

  fontFamily: "$openSans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "normal",

  color: "rgba(33, 33, 33, .8)",
  transparent: "0.8",
  backgroundColor: "white",

  borderBottom: "1px solid $gray-50",
});

export const UserSettingsMobileContainer = styled("button", {
  display: "flex",
  alignItems: "center",

  width: "100%",
  padding: "6px 16px",

  border: "unset",
  borderRadius: "$3",
  backgroundColor: "transparent",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$gray-30",
  },

  "&:active": {
    backgroundColor: "$gray-30",
  },

  "&:focus": {
    backgroundColor: "$gray-30",
  },

  img: {
    width: 28,
    height: 28,
  },

  p: {
    paddingLeft: "$6",

    fontFamily: "$openSans",
    fontSize: "$text-xl",
    color: "$gray-90",
  },
});
