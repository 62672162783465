import { Fragment } from "react";

import { LocationLink } from "./LocationMenuOption.styles";

export type LocationMenuOptionProps = {
  label: string;
  keyLabel: string;
  active: boolean;
  onClick(): void;
};

export function LocationMenuOption({
  label,
  keyLabel,
  active,
  onClick,
}: LocationMenuOptionProps) {
  return (
    <Fragment key={keyLabel}>
      <LocationLink
        key={`${keyLabel}-link`}
        onClick={onClick}
        underline={active}
      >
        {label}
      </LocationLink>
    </Fragment>
  );
}
