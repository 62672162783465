export function CircleX(props: any) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill={color} />
      <path
        d="M7.26666 15.9681L6.03186 14.7333L9.76519 11L6.03186 7.26667L7.26666 6.03187L11 9.7652L14.7333 6.03187L15.9681 7.26667L12.2348 11L15.9681 14.7333L14.7333 15.9681L11 12.2348L7.26666 15.9681Z"
        fill="white"
      />
    </svg>
  );
}
