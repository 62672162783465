import { Tile, styled, css } from "@patientmpower/spiro";

// Components
export const Container = styled("div", {
  height: "100%",

  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",

  backgroundColor: "$gray-20",

  "@sm": {
    width: "100%",
  },
});

export const LoadingOverlay = styled("div", {
  width: "547px",
  borderRadius: "$4",

  zIndex: 200,
  opacity: 0.64,
  position: "absolute",

  backgroundColor: "$white",

  "@sm": {
    width: "100%",
    height: "100%",
  },
});

export const StyledTile = styled(Tile, {
  width: "547px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: "$white",

  "@sm": {
    width: "100%",
    height: "100%",

    padding: "0px 42px",

    justifyContent: "flex-start",
    flexDirection: "column",
  },
});

export const Logo = styled("img", {
  width: "228px",
  height: "54px",

  marginTop: "40px",
  marginBottom: "48px",

  "@sm": {
    margin: "$13 0 $14 0",

    width: "169px",
    height: "$13",
  },
});

export const Form = styled("form", {
  width: "430px",
  height: "100%",

  "@sm": {
    width: "100%",
    height: "360px",
  },
});

export const InfoMessage = styled("p", {
  fontFamily: "$openSans",
  fontWeight: "400",
  fontSize: "$text-xl",
  fontStyle: "normal",
  lineHeight: "22px",
  color: "#212121",
  marginBottom: "56px",
});

// // CSS Styles
export const emailTextFieldStyles = css({
  marginBottom: "48px",

  "@sm": {
    marginBottom: "28px",
  },
});

export const sendButtonStyles = css({
  zIndex: 300,
  position: "relative",
  marginBottom: "84px",
});
