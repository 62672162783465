import { styled } from "@patientmpower/spiro";

export const ActionContainer = styled("div", {
  display: "flex",
  visibility: "hidden",
  justifyContent: "flex-end",
  alignItems: "center",

  paddingRight: "16px",
  paddingTop: "6px",
  paddingBottom: "6px",

  div: {
    button: {
      borderRadius: "50% !important",
      "&:hover": {
        backgroundColor: "rgba(33, 33, 33, 0.06) !important",
      },
    },
  },
});
