/* eslint-disable no-debugger */
import { AxiosResponse } from "axios";

import { ICreateNoteProps } from "../@types/Note";
import { IPaginatedResponse } from "../@types/PaginatedResponse";
import { api } from "./api";

export const noteService = {
  async getNotes(patientId: number): Promise<AxiosResponse> {
    const url = "/Portal/Notes";

    const urlParamsParsed = new URLSearchParams();

    urlParamsParsed.append("patientId", patientId.toString());

    const notesResponse = await api.get<IPaginatedResponse<any>>(url, {
      params: urlParamsParsed,
    });

    return notesResponse;
  },

  async getNoteById(id: number): Promise<AxiosResponse> {
    const url = `/Portal/Notes/${id}`;
    const note = await api.get(url);
    return note;
  },

  async createNote(createNoteProps: ICreateNoteProps): Promise<AxiosResponse> {
    const url = `/Portal/Notes/`;
    const time = new Date().toISOString();
    const { noteContent, patientId } = createNoteProps;

    const response = await api.post(url, { time, noteContent, patientId });
    return response;
  },

  async editNote(id: number, noteContent: string): Promise<AxiosResponse> {
    const url = `/Portal/Notes/${id}`;

    const response = await api.put(url, { noteContent });
    return response;
  },

  async deleteNote(id: number): Promise<AxiosResponse> {
    const url = `/Portal/Notes/${id}`;

    const response = await api.patch(url, { publish: false });
    return response;
  },
};
