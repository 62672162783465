import { styled } from "@patientmpower/spiro";

export const ModalContainer = styled("div", {
  height: "100%",
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "24px 26px",

  "@sm": {
    height: "350px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
  },
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "500",

  marginBottom: "28px",
  paddingLeft: "16px",
});

export const Description = styled("p", {
  width: 403,
  paddingLeft: "16px",

  fontSize: 16,
  fontFamily: "$openSans",
  fontWeight: "400",
  lineHeight: "24px",
  color: "$charcoal",
});

export const SubmitContainer = styled("div", {
  height: "70px",
  display: "flex",
  gap: "40px",
  padding: "26px 12px 0px 10px",
  alignItems: "center",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "5px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});
