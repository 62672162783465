import { styled } from "@patientmpower/spiro";

export const PromDetailsModalContainer = styled("div", {
  padding: "24px 0px",
  position: "relative",
  height: "100%",
  width: "1185px",
  display: "flex",
  flexDirection: "column",

  "@media screen and (max-width: 1185px)": {
    width: "calc(100vw - 80px)",
    overflowX: "scroll",
  },
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "600",

  marginBottom: "4px",
  paddingLeft: "14px",
});

export const SubHeading = styled("p", {
  color: "#727272",
  fontFamily: "$openSans",
  fontSize: "14px",
  lineHeight: "15px",
  fontWeight: "400",

  marginBottom: "4px",
  paddingLeft: "14px",
});

export const TableContainer = styled("div", {
  width: "100%",
  height: "95%",
});

export const BackArrowContainer = styled("div", {
  position: "absolute",
  top: 0,
  left: 2,
  cursor: "pointer",
});
