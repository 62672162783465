import {
  AccordionList,
  AccordionListItem,
  AccordionListItemDefaultContent,
} from "@patientmpower/spiro";

import { tooltipTitles } from "../../../../../../constants/measurements";
import {
  AccordionTitleButton,
  EmptyStateContainer,
  TableContainer,
} from "./MeasurementsModalMobileTable.styles";

interface IModalMeasurements {
  data: Map<string, any[]>;
}

interface IMeasuremntTableData {
  measurement: string;
  source: string;
  time: string;
  unformattedTime: number;
}

export function MeasurementsModalMobileTable({ data }: IModalMeasurements) {
  if (data.size < 1) {
    return (
      <EmptyStateContainer>
        <p>No data to show</p>
      </EmptyStateContainer>
    );
  }
  const mapKeys = Array.from(data.keys());
  let header = tooltipTitles.get(mapKeys[0].split("|")[0]) ?? "";
  const tableData: IMeasuremntTableData[] = [];

  mapKeys.forEach((key) => {
    const measurementType = key.split("|")[0];

    data.get(key)?.forEach((measurement) => {
      if (measurementType === "bp_systolic") {
        header = "Systolic/Diastolic";
        const DiastolicMeasurement = data
          .get(`bp_diastolic|${measurement.source.toLowerCase()}`)
          ?.find((element) => element.id === measurement.id);
        tableData.push({
          measurement: `${measurement[measurementType]}/${
            DiastolicMeasurement ? DiastolicMeasurement.bp_diastolic : "-"
          }`,
          source: measurement.source,
          time: measurement.time,
          unformattedTime: measurement.unformattedTime,
        });
      } else if (measurementType !== "bp_diastolic") {
        tableData.push({
          measurement: measurement[key.split("|")[0]],
          source: measurement.source,
          time: measurement.time,
          unformattedTime: measurement.unformattedTime,
        });
      }
    });
  });
  tableData.sort((a, b) => b.unformattedTime - a.unformattedTime);

  const mobileAccordionListColumns = [
    { key: header, value: "measurement" },
    { key: "Source", value: "source" },
  ];
  return (
    <TableContainer>
      <AccordionList type="single" collapsible>
        {tableData.map((measurement: IMeasuremntTableData, index) => {
          return (
            <AccordionListItem
              key={`${measurement.unformattedTime}_${index}`}
              index={index + 2} // Adding +2 to start the list with gray color
              title={
                <AccordionTitleButton>
                  {measurement.measurement}
                </AccordionTitleButton>
              }
              value={`measurement-list-${index}`}
              subtitle={`Date: ${measurement.time}`}
            >
              <AccordionListItemDefaultContent
                content={measurement}
                columns={mobileAccordionListColumns}
              />
            </AccordionListItem>
          );
        })}
      </AccordionList>
    </TableContainer>
  );
}
