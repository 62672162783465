import { createColumn } from "../../../../components/Table";

export const promsTableColumns = [
  createColumn({
    keyName: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 200,
  }),

  createColumn({
    keyName: "lastCompleted",
    header: () => "Last completed",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "surveyResult",
    header: () => "Survey Result",
    cell: (info) => info.renderValue(),
    size: 100,
  }),
  createColumn({
    keyName: "frequency",
    header: () => "Frequency",
    cell: (info) => info.renderValue(),
    size: 100,
  }),
  createColumn({
    keyName: "lastTotalScore",
    header: () => "Last Total Score",
    cell: (info) => info.renderValue(),
    size: 100,
  }),
];
