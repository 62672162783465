import { ReactNode } from "react";

import ceMarkImage from "../../../assets/images/ce-mark.svg";
import pmpLogoName from "../../../assets/images/pmp-logo-name.png";
import {
  ChildrenContainer,
  Footer,
  InfoRow,
  Header,
  InfoContainer,
  InfoText,
  InfoTitle,
  Logo,
  MainContainer,
  MarkingRow,
  MarkingContainer,
  MarkingImmage,
} from "./SetPasswordLayout.styles";

interface ISetPasswordLayout {
  children: ReactNode;
  className?: string;
}

export function SetPasswordLayout({
  children,
  className = "",
}: ISetPasswordLayout) {
  return (
    <MainContainer className={className}>
      <Header>
        <Logo src={pmpLogoName} />
      </Header>
      <ChildrenContainer>{children}</ChildrenContainer>
      <Footer>
        <InfoRow>
          <InfoContainer>
            <InfoTitle>Dublin Office</InfoTitle>
            <InfoText>21 Denzille Lane</InfoText>
            <InfoText>Dublin</InfoText>
            <InfoText>D02 EY19</InfoText>
          </InfoContainer>
          <InfoContainer>
            <InfoTitle>Boston USA</InfoTitle>
            <InfoText>617-207-6699</InfoText>
          </InfoContainer>
          <InfoContainer>
            <InfoTitle>London UK</InfoTitle>
            <InfoText>+44 20 3322 4121</InfoText>
          </InfoContainer>
        </InfoRow>
        <MarkingRow>
          <MarkingContainer>
            <MarkingImmage src={ceMarkImage} />
            <InfoText>© patientMpower</InfoText>
          </MarkingContainer>
        </MarkingRow>
      </Footer>
    </MainContainer>
  );
}
