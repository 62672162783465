export function ArtiQAccepted({ ...rest }) {
  return (
    <svg
      {...rest}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.28864" cy="8" r="8" fill="#58C488" />
      <path
        d="M7.17482 9.01122L5.47349 7.30255L4.38815 8.39522L7.17482 11.1892L12.5574 5.79189L11.4721 4.69922L7.17482 9.01122Z"
        fill="white"
      />
    </svg>
  );
}
