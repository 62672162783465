import { Spin } from "antd";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { IArticle, IRelatedArticles } from "../../../../@types/Articles";
import { FixedTranslationsLanguageCodes } from "../../../../@types/Translations";
import { IS_MOBILE_APP } from "../../../../constants/localStorageKeys";
import { articlesSerivce } from "../../../../services/articlesService";
import { translationService } from "../../../../services/translationsService";
import { parseArticlesTranslationResponseToDisplay } from "../../../../utils/translationsUtils";
import {
  AllArticlesButtton,
  ArticleSeparator,
  ArticleTitle,
  ArticleViewContainer,
  ContentContainer,
  HtmlContainer,
  OtherArticleContainer,
  RelatedArticle,
  spinCss,
} from "./ArticleViewer.styles";

export function ArticleViewer() {
  const { articleId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const [mobileToken, setMobileToken] = useState<string>("");
  const [showAllArticles, setShowAllArticles] = useState(false);
  const [isArticleLoading, setIsArticleLoading] = useState(false);
  const [isRelatedArticlesLoading, setIsRelatedArticlesLoading] =
    useState(false);
  const [isTranslationsLoading, setIsTranslationsLoading] = useState(true);

  const [article, setArticle] = useState<IArticle>();
  const [articleList, setArticleList] = useState<IRelatedArticles[]>([]);

  useEffect(() => {
    setIsArticleLoading(true);
    setIsRelatedArticlesLoading(true);
    setShowAllArticles(false);

    const token = searchParams.get("token");
    if (token) {
      setMobileToken(token);
      const tokenJson = `{"tokenType":"Bearer","idToken":"${token}"}`;
      window.localStorage.setItem("tokenResponse", tokenJson);
      window.localStorage.setItem(IS_MOBILE_APP, "true");
    }

    if (articleId) {
      if (articleId === "all") {
        articlesSerivce.getAllHospitalArticles().then((response) => {
          setArticleList(response.data);
          setIsRelatedArticlesLoading(false);
          setIsArticleLoading(false);
          setShowAllArticles(true);
        });
      } else {
        articlesSerivce.getById(articleId).then((response) => {
          if (response.data.articleUrl) {
            window.location.href = response.data.articleUrl;
          }
          setArticle(response.data);
          setIsArticleLoading(false);
        });

        articlesSerivce.getRelatedArticles(articleId).then((response) => {
          setArticleList(response.data);
          setIsRelatedArticlesLoading(false);
        });
      }
    }
  }, [articleId]);

  const handleArticleListing = (showAll = false) => {
    const articlesToReturn: any[] = [];

    const handleNavigation = async (articleId: string) => {
      await articlesSerivce.incrementArticleReads(articleId);
      navigate(`/careinfo/view/${articleId}`);
    };

    articleList?.forEach((relatedArticle, index) => {
      if (index === 0) {
        articlesToReturn.push(
          <RelatedArticle
            onClick={() => handleNavigation(relatedArticle.articleId)}
          >
            {relatedArticle.articleName}
          </RelatedArticle>
        );
      } else if (index < 5 || showAll) {
        articlesToReturn.push(
          <>
            <ArticleSeparator />
            <RelatedArticle
              onClick={() => handleNavigation(relatedArticle.articleId)}
            >
              {relatedArticle.articleName}
            </RelatedArticle>
          </>
        );
      }
    });

    return articlesToReturn;
  };

  useEffect(() => {
    i18n.changeLanguage();

    translationService
      .getArticlesFixedTranslationsByLanguageCode(
        i18n.language as FixedTranslationsLanguageCodes
      )
      .then((translations) => {
        if (!translations.data) return;

        const resourcesToAdd = parseArticlesTranslationResponseToDisplay(
          translations.data
        );

        i18n.addResourceBundle(i18n.language, "translation", resourcesToAdd);

        setIsTranslationsLoading(false);
      });
  }, []);

  if (isArticleLoading || isRelatedArticlesLoading || isTranslationsLoading) {
    return (
      <div>
        <Spin className={spinCss()} fullscreen size="large" />
      </div>
    );
  }

  if (showAllArticles) {
    return (
      <ArticleViewContainer>
        <ContentContainer>
          <ArticleTitle>
            {t("articles_fixed_translations_view_all_articles")}
          </ArticleTitle>

          <OtherArticleContainer>
            {handleArticleListing(true)}
          </OtherArticleContainer>
        </ContentContainer>
      </ArticleViewContainer>
    );
  }

  return (
    <ArticleViewContainer>
      <ContentContainer>
        <ArticleTitle style={{ marginLeft: "4px" }}>
          {article?.title}
        </ArticleTitle>

        <HtmlContainer>
          {ReactHtmlParser(article?.articleText ?? "")}
        </HtmlContainer>

        <ArticleTitle>
          {t("articles_fixed_translations_other_articles")}
        </ArticleTitle>

        <OtherArticleContainer>{handleArticleListing()}</OtherArticleContainer>

        <AllArticlesButtton
          onClick={() => {
            setShowAllArticles(true);
            navigate(
              `/careinfo/view/all?language=${i18n.language}&token=${mobileToken}`
            );
          }}
        >
          {t("articles_fixed_translations_view_all_articles")}
        </AllArticlesButtton>
      </ContentContainer>
    </ArticleViewContainer>
  );
}
