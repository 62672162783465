import axios, { AxiosError, AxiosResponse } from "axios";

import { IPortalPreferences, IUserPreferences } from "../@types/Preferences";
import {
  IConfirmEnableMfa,
  IMfa,
  IUpdatePortalPreferences,
  IUpdateUserUnitPreferences,
  IUser,
} from "../@types/User";
import { api } from "./api";

export const userService = {
  async getUserById(id: number): Promise<IUser> {
    const url = `/Legacy/Users/${id}`;

    const serverResponse = await api
      .get(url)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response?.data;
        }
        return response.data;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async disableUserMfa(): Promise<AxiosResponse> {
    const url = `/Portal/Authentication/DisableMfa`;

    const serverResponse = await api
      .post(url)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async enableUserMfa({
    email,
    phoneNumber,
    mfaType,
  }: IMfa): Promise<AxiosResponse> {
    const url = "/Portal/Authentication/EnableMfa";

    const serverResponse = await api
      .post(url, {
        email,
        phoneNumber,
        mfaType,
      })
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async confirmEnableUserMfa({
    email,
    code,
    mfaType,
  }: IConfirmEnableMfa): Promise<AxiosResponse> {
    const url = "/Portal/Authentication/ConfirmEnableMfa";

    const serverResponse = await api
      .post(url, { email, code, mfaType })
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async resendOtp({
    email,
    mfaType,
    phoneNumber,
  }: IMfa): Promise<AxiosResponse> {
    const url = "/Portal/Authentication/ResendOtp";

    const serverResponse = await api
      .post(url, {
        email,
        mfaType,
        phoneNumber,
      })
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async confirmMfaOtp({ email, code }: any): Promise<AxiosResponse> {
    const url = "/Portal/Authentication/ConfirmEnableMfa";

    const serverResponse = await api
      .post(url, { email, code })
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateUserDataDisplayPreference(
    preference: string
  ): Promise<AxiosResponse> {
    const url = "/Legacy/UserPreferences/UpdateDataDisplayPreference";

    const serverResponse = await api
      .post(url, preference)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getUserPreferences(): Promise<AxiosResponse<IUserPreferences>> {
    const url = "/Portal/Users/GetUserPreferences";

    const serverResponse = await api
      .get(url)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateUserUnitPreferences(
    userUnitPrefences: IUpdateUserUnitPreferences
  ): Promise<AxiosResponse> {
    const url = "/Portal/Users/UpdateUserUnitPreferences";

    const serverResponse = await api.post(url, userUnitPrefences);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getPortalPreferences(): Promise<AxiosResponse<IPortalPreferences>> {
    const url = "/Portal/Users/GetPortalPreferences";

    const serverResponse = await api
      .get(url)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updatePatientListConfig(
    userPortalPrefences: IUpdatePortalPreferences
  ): Promise<AxiosResponse> {
    const url = "/Portal/Users/UpdatePatientListConfig";

    const serverResponse = await api.put(url, userPortalPrefences);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateWardsConfig(wardsConfig: number[]): Promise<AxiosResponse> {
    const url = "/Portal/Users/UpdateWardsConfig";

    const serverResponse = await api.put(url, wardsConfig);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateScaleYAxis(scaleYAxis: boolean): Promise<AxiosResponse> {
    const url = "/Portal/Users/UpdateScaleYAxisConfig";

    const serverResponse = await api.put(url, scaleYAxis);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateCollapsedSectionsConfig(
    collapsedSectionsConfig: string
  ): Promise<AxiosResponse> {
    const url = "/Portal/Users/UpdateCollapsedSectionsConfig";

    const serverResponse = await api.put(url, { collapsedSectionsConfig });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
