import { styled } from "@patientmpower/spiro";

// Components
export const TokenContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});
