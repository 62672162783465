export function SwitchOff(props: any) {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.4" stroke="#727272" strokeWidth="1.2" />
    </svg>
  );
}
