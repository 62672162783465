export function CircleLetterOuter(props: any) {
  const { letter } = props;

  return (
    <svg
      width="74"
      height="74"
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="37" cy="37" r="37" fill="#B5DADB" />
      <circle cx="37" cy="37" r="35" fill="#82C5B7" />
      <text
        x="50%"
        y="50%"
        fontFamily="Nunito Sans"
        textAnchor="middle"
        stroke="#fff"
        fill="#fff"
        dy=".3em"
        fontSize="32px"
        fontStyle="normal"
      >
        {letter}
      </text>
    </svg>
  );
}
