import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  height: "100%",
  // overflowY: "auto",
});

export const SessionGraphContainer = styled("div", {
  position: "relative",
  right: "38px",
  top: "20px",
  width: "calc(100% + 70px)",
  marginBottom: "30px",
});
