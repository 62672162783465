import { Button } from "@patientmpower/spiro";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SetPasswordLayout } from "../../../layout/components/SetPasswordLayout";
import {
  InfoMessage,
  MainContainer,
  portalButtonCss,
  StoreImage,
  StoreImagesRow,
  TitleMessage,
} from "./CreatePassSuccessPage.styles";

export function CreatePassSuccessPage() {
  const [isHcp, setIsHcp] = useState<boolean>(false);

  const appStoreUrl = "https://apps.apple.com/us/app/pmp-next-gen/id6477621295";
  const googlePlayStoreUrl =
    "https://play.google.com/store/apps/details?id=co.patientbuddy.tracker.nextgen&hl=en";

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const isHcpParam = searchParams.get("isHcp");
    if (!isHcpParam || (isHcpParam !== "true" && isHcpParam !== "false")) {
      navigate("/login");
    }
    if (isHcpParam === "true") {
      setIsHcp(true);
    } else {
      setIsHcp(false);
    }
  }, []);

  const handleGoToPortal = () => {
    navigate("/login");
  };

  const handleGoToAppStore = () => {
    window.location.href = appStoreUrl;
  };

  const handleGoToGooglePlay = () => {
    window.location.href = googlePlayStoreUrl;
  };

  return (
    <SetPasswordLayout>
      <MainContainer>
        <TitleMessage>Password set</TitleMessage>
        {isHcp ? (
          <>
            <InfoMessage>
              The next step is to log into the portal using the button below
            </InfoMessage>
            <Button
              className={portalButtonCss()}
              label="Portal"
              onClick={handleGoToPortal}
              type="button"
            />
          </>
        ) : (
          <>
            <InfoMessage>
              The next step is to download our patientMpower app, available for
              Android, iPhone and iPad.
            </InfoMessage>
            <StoreImagesRow>
              <StoreImage
                src="https://patientmpower-production.s3.eu-west-1.amazonaws.com/static/img/emails/google-play-download-image.png"
                onClick={handleGoToGooglePlay}
              />
              <StoreImage
                src="https://patientmpower-production.s3.eu-west-1.amazonaws.com/static/img/emails/app-store-download-image.png"
                onClick={handleGoToAppStore}
              />
            </StoreImagesRow>
          </>
        )}
      </MainContainer>
    </SetPasswordLayout>
  );
}
