import { styled } from "@patientmpower/spiro";

export const AppointmentsContainer = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "column",

  height: "500px",
});

export const CreateAppointmentContainer = styled("div", {
  color: "$charcoal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "17px",
  cursor: "pointer",
  position: "absolute",
  top: "68px",
  right: "10px",
  borderRadius: "6px",
  padding: "0 2px",

  "&:hover": {
    color: "$primary",
  },
});

export const Subtitle = styled("h2", {
  marginBottom: "12px",

  color: "$gray-80",

  fontWeight: 400,
  fontSize: "12px",

  lineHeight: "16px",
});
