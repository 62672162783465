import { Button, TextField } from "@patientmpower/spiro";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { IForgotPasswordFormValues } from "../../@types/ForgotPassword";
import pmpLogoName from "../../assets/images/pmp-logo-name.png";
import {
  FooterLinksDesktop,
  FooterLinksMobile,
} from "../../layout/components/FooterLinks";
import { authService } from "../../services/authService";
import { mixpanelActions } from "../../utils/mixpanel";
import {
  Logo,
  Container,
  StyledTile,
  emailTextFieldStyles,
  sendButtonStyles,
  InfoMessage,
  Form,
  LoadingOverlay,
} from "./ForgotPasswordPage.styles";

export function ForgotPasswordPage() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSucessMessage] = useState(false);
  const [apiFieldErrors, setApiFieldErrors] = useState({
    email: "",
  });

  const handleOnSubmitForm = async ({ email }: IForgotPasswordFormValues) => {
    mixpanelActions.track("User Action: ForgotPasswordEmailSubmit");

    setIsLoading(true);

    try {
      await authService.sendStaffForgotPasswordRequest({ email });
    } finally {
      setShowSucessMessage(true);
      setIsLoading(false);
    }
  };

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  useEffect(() => {
    mixpanelActions.track("Visited Screen: ForgotPasswordEmail");
  }, []);

  const shouldShowErrorMessage = (field: "email") => {
    return formik.touched[field]
      ? apiFieldErrors[field] || formik.errors[field]
      : "";
  };

  const handleResetGlobalErrorFieldOnBlur = (field: "email") => {
    setApiFieldErrors((state: IForgotPasswordFormValues) => ({
      ...state,
      [field]: "",
    }));
  };

  return (
    <Container>
      <StyledTile>
        {isLoading && <LoadingOverlay />}
        <Form onSubmit={formik.handleSubmit}>
          <Logo src={pmpLogoName} alt="patientMpower logo" />
          <InfoMessage>
            {!showSuccessMessage
              ? "Please enter your email address below and we will send you an email to reset your password."
              : `Check your email (${formik.values.email}) to reset your password.`}
          </InfoMessage>
          {!showSuccessMessage ? (
            <div>
              <TextField
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="jane@example.com"
                className={emailTextFieldStyles()}
                errorMessage={shouldShowErrorMessage("email")}
                onBlur={() => handleResetGlobalErrorFieldOnBlur("email")}
              />
              <Button
                type="submit"
                label="Send"
                isLoading={isLoading}
                className={sendButtonStyles()}
                disabled={!formik.isValid}
              />
            </div>
          ) : (
            <div>
              <Button
                type="button"
                label="Back to log in"
                className={sendButtonStyles()}
                onClick={() => navigate("/login")}
              />
            </div>
          )}
        </Form>
        <FooterLinksMobile />
      </StyledTile>
      <FooterLinksDesktop />
    </Container>
  );
}
