export function TableSettings({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2728 0.963623V2.55453L0.954645 2.55453V0.963623L15.2728 0.963623ZM10.5001 10.5091L0.954645 10.5091V8.91817L10.5001 8.91817L10.5001 10.5091ZM0.954645 6.5318L10.5001 6.5318L10.5001 4.9409L0.954645 4.9409V6.5318Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0065 8.60308C19.0293 8.4436 19.0512 8.29052 19.0512 8.12283C19.0512 7.95513 19.0293 7.80205 19.0065 7.64257C19.0055 7.63562 19.0045 7.62866 19.0035 7.62169L19.9103 6.81828L19.1148 5.44214L17.9614 5.83192C17.7069 5.61714 17.4205 5.4501 17.1023 5.33078L16.8637 4.14555L15.2728 4.14555L15.0342 5.33078C14.716 5.4501 14.4296 5.61714 14.1751 5.83192L13.0217 5.44214L12.2262 6.81828L13.133 7.62169C13.132 7.62866 13.131 7.63562 13.13 7.64257C13.1072 7.80204 13.0853 7.95513 13.0853 8.12283C13.0853 8.29052 13.1072 8.44361 13.13 8.60308C13.131 8.61003 13.132 8.61699 13.133 8.62396L12.2262 9.42737L13.0217 10.8035L14.1751 10.4137C14.4296 10.6285 14.716 10.7956 15.0342 10.9149L15.2728 12.1001H16.8637L17.1023 10.9149C17.4205 10.7956 17.7069 10.6285 17.9614 10.4137L19.1148 10.8035L19.9103 9.42737L19.0035 8.62396C19.0045 8.61699 19.0055 8.61002 19.0065 8.60308ZM14.4773 8.12282C14.4773 7.24782 15.1932 6.53192 16.0682 6.53192C16.9432 6.53192 17.6592 7.24782 17.6592 8.12282C17.6592 8.99782 16.9432 9.71373 16.0682 9.71373C15.1932 9.71373 14.4773 8.99782 14.4773 8.12282Z"
        fill="#666666"
      />
    </svg>
  );
}
