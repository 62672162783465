import { TextField } from "@patientmpower/spiro";

import {
  ErrorMessage,
  Row,
  SettingsLabel,
  TextFieldCss,
} from "../../WorkflowsBuilder.styles";

interface ITextFieldRow {
  label: string;
  name: "title" | "description" | "wards";
  placeholder: string;
  value: any;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  shouldShowErrorMessage: (
    field: "title" | "description" | "wards"
  ) => string | string[];
}

export function TextFieldRow({
  label,
  name,
  placeholder,
  value,
  onChange,
  shouldShowErrorMessage,
}: ITextFieldRow) {
  return (
    <Row isError={shouldShowErrorMessage(name) !== ""}>
      <SettingsLabel>{label}</SettingsLabel>
      <TextField
        name={name}
        placeholder={placeholder}
        value={value}
        label=""
        onChange={onChange}
        backgroudColor="white"
        className={`${TextFieldCss()} nopan nodrag`}
      />
      <ErrorMessage isSettingsPageError>
        {shouldShowErrorMessage(name)}
      </ErrorMessage>
    </Row>
  );
}
