import { AxiosResponse } from "axios";

import {
  IProtocolResponse,
  IProtocolOptions,
  IIntervalOptions,
  IAddProtocol,
  IProtocolServerResponse,
} from "../@types/Protocol";
import { api } from "./api";

export const protocolService = {
  async getProtocolList(): Promise<AxiosResponse<IProtocolResponse[]>> {
    const url = "/Portal/Protocols";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getProtocolById(
    id: string
  ): Promise<AxiosResponse<IProtocolServerResponse>> {
    const url = `/Portal/Protocols/${id}`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async addProtocol(request: IAddProtocol): Promise<AxiosResponse<string>> {
    const url = "/Portal/Protocols";

    const serverResponse = await api.post(url, request);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateProtocol(request: IAddProtocol): Promise<AxiosResponse<string>> {
    const url = `/Portal/Protocols/${request.id}`;

    const serverResponse = await api.put(url, request);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateProtocolStatus(
    protocolId: string,
    published: boolean
  ): Promise<AxiosResponse<string>> {
    const url = `/Portal/Protocols/UpdateStatus/${protocolId}`;

    const serverResponse = await api.put(url, published);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async archiveProtocol(protocolId: string): Promise<AxiosResponse<string>> {
    const url = `/Portal/Protocols/Archive/${protocolId}`;

    const serverResponse = await api.put(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getSurveyOptions(): Promise<AxiosResponse<IProtocolOptions[]>> {
    const url = "/Portal/Protocols/GetSurveyOptions";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getIntervalOptions(): Promise<AxiosResponse<IIntervalOptions[]>> {
    const url = "/Portal/Protocols/GetIntervalOptions";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
