import { css, styled } from "@patientmpower/spiro";

// components

export const FormAnswerField = styled("div", {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "20px",
  marginTop: "30px",
});

export const HorizontalSliderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  width: "95%",
  marginTop: "40px",

  p: {
    textJustify: "inter-word",
    width: "30%",
  },
});

export const SliderContainer = styled("div", {
  width: "95%",
  alignSelf: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const LabelsRow = styled("div", {
  alignSelf: "center",
  width: "92%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const LabelContainerLeft = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  width: "40%",

  fontWeight: "300",
  fontSize: "14px",
  lineHeight: "16px",
  textAlign: "left",
});

export const LabelContainerRight = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",

  width: "40%",

  fontWeight: "300",
  fontSize: "14px",
  lineHeight: "16px",
  textAlign: "right",
});

export const SliderValueHorizontal = styled("p", {
  width: "100% !important",
  fontFamily: "$openSans",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "16px",
  color: "#4B4B4B",
});

export const SliderLabelHorizontal = styled("p", {
  width: "100% !important",
  fontFamily: "$openSans",
  fontWeight: "300",
  fontSize: "16px",
  lineHeight: "16px",
  color: "#727272",
});

export const SliderValueVertical = styled("p", {
  fontFamily: "$openSans",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "16px",
  color: "#4B4B4B",
});

export const SliderLabelVertical = styled("p", {
  textAlign: "center",
  fontFamily: "$openSans",
  fontWeight: "300",
  fontSize: "16px",
  lineHeight: "16px",
  color: "#727272",
});

export const VerticalSliderContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "90%",
  height: "100%",
  minHeight: "300px",

  p: {
    textJustify: "inter-word",
  },
});

export const SliderUsabilityMessage = styled("div", {
  marginTop: "40px",

  textAlign: "center",
  fontFamily: "$openSans",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  color: "$charcoal",
});

export const verticalSliderCss = css({
  width: "200px !important",
  height: "98%!important",
  margin: "10px 11px",

  ".ant-slider": {
    "&:hover": {
      backgroundColor: "$secondary-lighter !important",
    },
  },

  ".ant-slider-rail": {
    backgroundColor: "$secondary-lighter !important",
    left: "97px",
    width: "5px",

    "&:hover": {
      backgroundColor: "$secondary-lighter !important",
    },
  },

  ".ant-slider-step": {
    left: " 98px",
  },
  ".ant-slider-handle": {
    boxShadow: "none",
    left: "93px",
    height: "13px",
    width: "13px",

    ".ant-tooltip": {
      inset: "auto auto -12px 23px !important",

      ".ant-tooltip-content": {
        fontSize: "16px",

        ".ant-tooltip-inner": {
          boxShadow: "none",
          padding: "4px 8px 6px 8px",
        },
      },
    },

    "&::after": {
      backgroundColor: "$primary-40 !important",
      boxShadow: "0 0 0 2px $gray-20 !important",
      height: "13px",
      width: "13px",
    },
    "&::before": {
      zIndex: "1071",

      width: "75px",
      heigth: "40px !important",
      minHeight: "40px !important",

      insetBlockStart: "-13px",
    },
    "&:focus": {
      "&::before": {
        zIndex: "1071",

        width: "75px",
        heigth: "40px !important",
        minHeight: "40px !important",

        insetBlockStart: "-13px",
      },
      "&::after": {
        height: "13px",
        width: "13px",
        insetInlineStart: "0px !important",
        insetBlockStart: "0px !important",
      },
    },

    "&:hover": {
      "&::before": {
        zIndex: "1071",

        width: "75px",
        heigth: "40px !important",
        minHeight: "40px !important",

        insetBlockStart: "-13px",
      },
      "&::after": {
        height: "13px",
        width: "13px",
        insetInlineStart: "0px !important",
        insetBlockStart: "0px !important",
      },
    },

    "&:active": {
      "&::before": {
        zIndex: "1071",

        width: "75px",
        heigth: "40px !important",
        minHeight: "40px !important",

        insetBlockStart: "-13px",
      },
      "&::after": {
        height: "13px",
        width: "13px",
        insetInlineStart: "0px !important",
        insetBlockStart: "0px !important",
      },
    },
  },
});

export const horizontalSliderCss = css({
  margin: "0px !important",
  height: "50px !important",
  ".ant-slider-rail": {
    backgroundColor: "$secondary-lighter !important",
    bottom: "22px",
    height: "5px",

    "&:hover": {
      backgroundColor: "$secondary-lighter !important",
    },
  },

  ".ant-slider-step": {
    top: "19px",
  },

  ".ant-slider-handle": {
    boxShadow: "none",
    top: "19px",
    height: "13px",
    width: "13px",

    ".ant-tooltip": {
      "--arrow-x": "16px !important",
      inset: "auto auto 22px -10px !important",

      ".ant-tooltip-arrow": {
        left: "16px !important",
      },
      ".ant-tooltip-content": {
        fontSize: "16px",
        ".ant-tooltip-inner": {
          boxShadow: "none",
        },
      },
    },

    "&::after": {
      backgroundColor: "$primary-40 !important",
      boxShadow: "0 0 0 2px $gray-20 !important",
      height: "13px !important",
      width: "13px !important",
    },

    "&::before": {
      zIndex: "1071",

      width: "35px",
      heigth: "75px !important",
      minHeight: "75px !important",

      insetInlineStart: "-13px",
      insetBlockStart: "-50px",
    },

    "&:focus": {
      "&::before": {
        zIndex: "1071",

        width: "35px",
        heigth: "75px !important",
        minHeight: "75px !important",

        insetInlineStart: "-13px",
        insetBlockStart: "-50px",
      },
      "&::after": {
        height: "13px !important",
        width: "13px !important",
        insetInlineStart: "0px !important",
        insetBlockStart: "0px !important",
      },
    },

    "&:hover": {
      "&::before": {
        zIndex: "1071",

        width: "35px",
        heigth: "75px !important",
        minHeight: "75px !important",

        insetInlineStart: "-13px",
        insetBlockStart: "-50px",
      },
      "&::after": {
        height: "13px !important",
        width: "13px !important",
        insetInlineStart: "0px !important",
        insetBlockStart: "0px !important",
      },
    },

    "&:active": {
      "&::before": {
        zIndex: "1071",

        width: "35px",
        heigth: "75px !important",
        minHeight: "75px !important",

        insetInlineStart: "-13px",
        insetBlockStart: "-50px",
      },
      "&::after": {
        height: "13px !important",
        width: "13px !important",
        insetInlineStart: "0px !important",
        insetBlockStart: "0px !important",
      },
    },
  },
});

export const VerticalSliderInputContainer = styled("div", {
  height: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  "@sm": {
    height: "98%",
  },
});

export const verticalSliderCssNoTooltip = css(verticalSliderCss, {
  ".ant-slider-handle": {
    ".ant-tooltip": {
      ".ant-tooltip-content": {
        ".ant-tooltip-inner": {
          backgroundColor: "transparent",
        },
      },
      ".ant-tooltip-arrow::before": {
        background: "transparent",
      },
    },
    "&::after": {
      backgroundColor: "transparent !important",
      boxShadow: "0 0 0 2px $gray-20 !important",
      height: "13px",
      width: "13px",
    },
    "&:focus": {
      "&::after": {
        backgroundColor: "transparent",
      },
    },
    "&:hover": {
      "&::after": {
        backgroundColor: "transparent",
      },
    },
    "&:active": {
      "&::after": {
        backgroundColor: "transparent",
      },
    },
  },
});

export const horizontalSliderCssNoTooltip = css(horizontalSliderCss, {
  ".ant-slider-handle": {
    ".ant-tooltip": {
      ".ant-tooltip-content": {
        ".ant-tooltip-inner": {
          backgroundColor: "transparent",
        },
      },
      ".ant-tooltip-arrow::before": {
        background: "transparent",
      },
    },
    "&::after": {
      backgroundColor: "transparent !important",
      boxShadow: "0 0 0 2px $gray-20 !important",
      height: "13px !important",
      width: "13px !important",
    },
    "&:focus": {
      "&::after": {
        backgroundColor: "transparent",
      },
    },
    "&:hover": {
      "&::after": {
        backgroundColor: "transparent",
      },
    },
    "&:active": {
      "&::after": {
        backgroundColor: "transparent",
      },
    },
  },
});
