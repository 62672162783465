/* eslint-disable no-debugger */
import { AxiosResponse } from "axios";

import { ICreateMessageProps } from "../@types/Message";
import { IPaginatedResponse } from "../@types/PaginatedResponse";
import { api } from "./api";

export const messageService = {
  async getMessages(patientId: number): Promise<AxiosResponse> {
    const url = "/Portal/Messages";

    const urlParamsParsed = new URLSearchParams();

    urlParamsParsed.append("sortOrder", "desc");
    urlParamsParsed.append("sortColumn", "sentDate");
    urlParamsParsed.append("patientId", patientId.toString());

    const messagesResponse = await api.get<IPaginatedResponse<any>>(url, {
      params: urlParamsParsed,
    });

    return messagesResponse;
  },

  async createMessage(
    createMessageProps: ICreateMessageProps
  ): Promise<AxiosResponse> {
    const url = `/Portal/Messages/`;
    const {
      messageTitle,
      patientId,
      messageBody,
      timeFrame,
      responseType,
      sentProvider,
      responseOptions,
    } = createMessageProps;

    const response = await api.post(url, {
      title: messageTitle,
      body: messageBody,
      patientId,
      timeFrame,
      responseType,
      sentProvider,
      responseOptions,
    });
    return response;
  },

  async respondToPatientMessage(
    messageId: string,
    messageResponse: string
  ): Promise<AxiosResponse> {
    const url = `/Portal/Messages/${messageId}/Respond`;

    const serverResponse = await api.post(url, messageResponse);
    return serverResponse;
  },

  async readPatientMessage(messageId: string): Promise<AxiosResponse> {
    const url = `/Portal/Messages/${messageId}/ReadPatientMessage`;

    const serverResponse = await api.put(url);
    return serverResponse;
  },

  async getRegisteredDevices(patientId: number): Promise<AxiosResponse> {
    const url = "/Portal/Messages/GetRegisteredDevicesForPatient";
    const urlParamsParsed = new URLSearchParams();
    urlParamsParsed.append("patientId", patientId.toString());

    const devicesResponse = await api.get<IPaginatedResponse<any>>(url, {
      params: urlParamsParsed,
    });

    return devicesResponse;
  },
};
