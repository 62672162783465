import { Button } from "@patientmpower/spiro";
import { useMemo, useState } from "react";

import { getJournalData } from "../../../../../../hooks/queries/journals";
import { useModal } from "../../../../../../hooks/useModal";
import { getLongFormattedDate } from "../../../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  Container,
  SubmitContainer,
  BackgroundContainer,
  Title,
  LabelWeight,
  Label,
  LabelNotes,
  TopContainer,
  ChildOneContainer,
  ContentContainer,
} from "./ViewJournalModal.styles";

type ViewJournalModalProps = {
  journalId: number;
};

export function ViewJournalModal({ journalId }: ViewJournalModalProps) {
  const [journalContent, setJournalContent] = useState("");
  const [tagsContent, setTagsContent] = useState("");
  const [dateContent, setDateContent] = useState("");
  const { closeModal } = useModal();

  const { patientJournalData, isFetching, isError, refetch, isRefetching } =
    getJournalData(journalId);

  useMemo(() => {
    if (!isFetching && !isError && !isRefetching && patientJournalData) {
      mixpanelActions.track(`Visited Screen: JournalEntry`);
      let notesStr = "No text associated with this entry";
      if (patientJournalData.notes) {
        notesStr = patientJournalData.notes;
      }
      setJournalContent(notesStr);
      const timeStr = getLongFormattedDate(patientJournalData.date);
      setDateContent(timeStr);
      let tagsString = "-";
      if (patientJournalData.tags) {
        tagsString = patientJournalData.tags;
      }
      setTagsContent(tagsString);
    }
  }, [patientJournalData, isFetching, isError, isRefetching]);

  const refreshData = () => {
    refetch();
  };

  const handleDoneClick = () => {
    mixpanelActions.track(`User action: JournalDone`);
    closeModal();
  };

  if (isError) {
    return (
      <Container>
        <p>
          Error getting journal entry data, please{" "}
          <button type="button" onClick={refreshData}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </Container>
    );
  }

  if (isFetching) {
    return (
      <Container>
        <Title>Journal entry</Title>
        <BackgroundContainer />
      </Container>
    );
  }

  return (
    <Container>
      <Title>Journal entry</Title>
      <BackgroundContainer>
        <TopContainer>
          <ChildOneContainer>
            <LabelWeight>Tags</LabelWeight>
            <Label>{tagsContent}</Label>
          </ChildOneContainer>
          <ChildOneContainer>
            <LabelWeight>Date</LabelWeight>
            <Label>{dateContent}</Label>
          </ChildOneContainer>
        </TopContainer>
        <ContentContainer>
          <LabelWeight>Text</LabelWeight>
          <LabelNotes>{journalContent}</LabelNotes>
        </ContentContainer>
      </BackgroundContainer>
      <SubmitContainer>
        <Button label="Done" type="button" onClick={handleDoneClick} />
      </SubmitContainer>
    </Container>
  );
}
