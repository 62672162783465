export function ArtiQRejected({ ...rest }) {
  return (
    <svg
      {...rest}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#EB5A7B" />
      <path
        d="M8.00001 11.75C7.79862 11.75 7.62675 11.6788 7.48439 11.5365C7.34202 11.3941 7.27084 11.2222 7.27084 11.0208C7.27084 10.8194 7.34202 10.6476 7.48439 10.5052C7.62675 10.3628 7.79862 10.2917 8.00001 10.2917C8.2014 10.2917 8.37327 10.3628 8.51564 10.5052C8.658 10.6476 8.72918 10.8194 8.72918 11.0208C8.72918 11.2222 8.658 11.3941 8.51564 11.5365C8.37327 11.6788 8.2014 11.75 8.00001 11.75ZM7.27084 9.25V4.25H8.72918V9.25H7.27084Z"
        fill="white"
      />
    </svg>
  );
}
