import { createContext, ReactNode, useMemo, useState } from "react";

type ILeftPanelContextData = {
  isMobileLeftPanelMenuOpen: boolean;
  setIsMobileLeftPanelMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobileUserSettingsMenuOpen: boolean;
  setIsMobileUserSettingsMenuOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

type ILeftPanelProviderProps = {
  children: ReactNode;
};

export const LeftPanelContext = createContext({} as ILeftPanelContextData);

export function LeftPanelProvider({ children }: ILeftPanelProviderProps) {
  const [isMobileLeftPanelMenuOpen, setIsMobileLeftPanelMenuOpen] =
    useState(false);

  const [isMobileUserSettingsMenuOpen, setIsMobileUserSettingsMenuOpen] =
    useState(false);

  const valuesToShare = useMemo(
    () => ({
      isMobileLeftPanelMenuOpen,
      setIsMobileLeftPanelMenuOpen,
      isMobileUserSettingsMenuOpen,
      setIsMobileUserSettingsMenuOpen,
    }),
    [isMobileLeftPanelMenuOpen, isMobileUserSettingsMenuOpen]
  );

  return (
    <LeftPanelContext.Provider value={valuesToShare}>
      {children}
    </LeftPanelContext.Provider>
  );
}
