export function Lab({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        d="M1.94144 17.5743C1.15637 17.5743 0.601241 17.216 0.276038 16.4992C-0.0491491 15.7825 0.0572758 15.1235 0.595313 14.5224L6.20094 8.1964V2.34569H5.30721C5.05196 2.34569 4.83687 2.25783 4.66192 2.08212C4.48696 1.9064 4.39948 1.69035 4.39948 1.43397C4.39948 1.17758 4.48696 0.962864 4.66192 0.789827C4.83687 0.616791 5.05196 0.530273 5.30721 0.530273H12.6923C12.9476 0.530273 13.1648 0.618128 13.3441 0.793838C13.5234 0.969563 13.613 1.18561 13.613 1.44199C13.613 1.69838 13.5234 1.9131 13.3441 2.08613C13.1648 2.25917 12.9476 2.34569 12.6923 2.34569H11.8116V8.1964L17.4172 14.5224C17.9466 15.1235 18.0487 15.7825 17.7235 16.4992C17.3983 17.216 16.8432 17.5743 16.0581 17.5743H1.94144ZM1.92551 15.7589H16.087L9.9832 8.86896V2.34569H8.01635V8.86896L1.92551 15.7589Z"
        fill="#727272"
      />
    </svg>
  );
}
