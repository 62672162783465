import { IOxygenQuestion } from "../../../../../../../@types/Surveys";

const oxygenFlowRateOptions = [
  { label: "0.5", value: 0.5 },
  { label: "1", value: 1 },
  { label: "1.5", value: 1.5 },
  { label: "2", value: 2 },
  { label: "2.5", value: 2.5 },
  { label: "3", value: 3 },
  { label: "3.5", value: 3.5 },
  { label: "4", value: 4 },
  { label: "4.5", value: 4.5 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "+12", value: 99 },
];

export const fiveQuestionsOptions: IOxygenQuestion[] = [
  {
    description:
      "survey_fixed_translations_five_questions_question_one_description",
    options: [
      {
        label:
          "survey_fixed_translations_five_questions_question_one_option_one",
        value: 0,
      },
      {
        label:
          "survey_fixed_translations_five_questions_question_one_option_two",
        value: 1,
      },
    ],
  },
  {
    description:
      "survey_fixed_translations_five_questions_question_two_description",
    options: [
      {
        label:
          "survey_fixed_translations_five_questions_question_two_option_one",
        value: 1,
      },
      {
        label:
          "survey_fixed_translations_five_questions_question_two_option_two",
        value: 2,
      },
      {
        label:
          "survey_fixed_translations_five_questions_question_two_option_three",
        value: 3,
      },
      {
        label:
          "survey_fixed_translations_five_questions_question_two_option_four",
        value: 4,
      },
    ],
  },
  {
    description:
      "survey_fixed_translations_five_questions_question_three_description",
    options: oxygenFlowRateOptions,
  },
  {
    description:
      "survey_fixed_translations_five_questions_question_four_description",
    options: oxygenFlowRateOptions,
  },
  {
    description:
      "survey_fixed_translations_five_questions_question_five_description",
    options: oxygenFlowRateOptions,
  },
];
