export function ChevronDown() {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7143 6.00774L12.9511 0.770898C13.1292 0.592869 13.3529 0.501798 13.6224 0.497684C13.8919 0.493569 14.1198 0.584641 14.306 0.770898C14.4923 0.957155 14.5854 1.18297 14.5854 1.44834C14.5854 1.71373 14.4923 1.93956 14.306 2.12582L8.52774 7.9041C8.40742 8.02443 8.2805 8.10931 8.14698 8.15877C8.01348 8.20821 7.86925 8.23293 7.7143 8.23293C7.55935 8.23293 7.41513 8.20821 7.28163 8.15877C7.14811 8.10931 7.02118 8.02443 6.90086 7.9041L1.12257 2.12582C0.944545 1.94779 0.853474 1.72403 0.84936 1.45455C0.845223 1.18504 0.936295 0.957155 1.12257 0.770898C1.30883 0.584641 1.53464 0.491512 1.80002 0.491512C2.06539 0.491512 2.2912 0.584641 2.47746 0.770898L7.7143 6.00774Z"
        fill="#666666"
      />
    </svg>
  );
}
