import { styled } from "@patientmpower/spiro";

export const TitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const NewDataCOntainer = styled("div", {
  marginLeft: "5px",
  color: "$gray-80",

  fontFamily: "$nunito",
  fontWeight: "600px",
  fontSize: "16px",
  lineHeight: "34px",
});
