export function Alert({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        d="M0.245605 8.20089C0.245605 6.89314 0.516122 5.65335 1.05715 4.48151C1.59819 3.30969 2.37154 2.30397 3.37721 1.46435L4.51111 2.70286C3.68411 3.38571 3.04526 4.20675 2.59456 5.16596C2.14387 6.12518 1.91852 7.13682 1.91852 8.20089H0.245605ZM16.104 8.20089C16.104 7.13609 15.8901 6.12426 15.4623 5.16541C15.0345 4.20656 14.4072 3.38571 13.5801 2.70286L14.714 1.46435C15.7131 2.31061 16.4733 3.31695 16.9948 4.48334C17.5162 5.64976 17.7769 6.88894 17.7769 8.20089H16.104ZM1.10945 16.702V14.8736H3.15105V8.2238C3.15105 6.86202 3.54645 5.63994 4.33724 4.55757C5.12803 3.4752 6.18104 2.78941 7.49626 2.50018V1.90575C7.49626 1.48959 7.6437 1.14426 7.93859 0.869752C8.2335 0.595257 8.58723 0.458008 8.99979 0.458008C9.41236 0.458008 9.76609 0.595257 10.061 0.869752C10.3559 1.14426 10.5033 1.48959 10.5033 1.90575V2.50018C11.8272 2.78941 12.8883 3.47304 13.6868 4.55109C14.4852 5.62913 14.8844 6.85337 14.8844 8.2238V14.8736H16.9031V16.702H1.10945ZM9.00628 19.4908C8.4876 19.4908 8.03402 19.3012 7.64553 18.9219C7.25707 18.5425 7.06283 18.0865 7.06283 17.5539H10.9497C10.9497 18.0926 10.7584 18.5501 10.3758 18.9264C9.99318 19.3027 9.53668 19.4908 9.00628 19.4908ZM4.96646 14.8736H13.056V8.2238C13.056 7.09325 12.6665 6.13075 11.8873 5.3363C11.1081 4.54186 10.1533 4.14464 9.02271 4.14464C7.89216 4.14464 6.93348 4.54186 6.14667 5.3363C5.35986 6.13075 4.96646 7.09325 4.96646 8.2238V14.8736Z"
        fill="#727272"
      />
    </svg>
  );
}
