import { Tile, styled } from "@patientmpower/spiro";

export const StaffContainer = styled(Tile, {
  width: "100%",
  height: "160px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  backgroundColor: "$white",
  marginTop: "6px",
  padding: "0 0 30px 55px",
  fontSize: "14px",
  fontWeight: "500",
  color: "#666666",
});

export const TableContainer = styled("div", {
  height: "410px",
  position: "relative",
});

export const AddStaffTitle = styled("div", {
  color: "$charcoal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "17px",
  cursor: "pointer",
  position: "absolute",
  top: "-22px",
  right: "10px",
  borderRadius: "6px",
  padding: "0 2px",

  "&:hover": {
    color: "$primary",
  },
});
