import { AxiosResponse } from "axios";

import {
  FixedTranslationsLanguageCodes,
  ITranslationResponse,
} from "../@types/Translations";
import { api } from "./api";

export const translationService = {
  async getSurveysFixedTranslationsByLanguageCode(
    languageCode: FixedTranslationsLanguageCodes
  ): Promise<AxiosResponse<ITranslationResponse[]>> {
    const url = `/Shared/Languages/${languageCode}/Translations?usedLocation=SurveysFixedTranslations`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getArticlesFixedTranslationsByLanguageCode(
    languageCode: FixedTranslationsLanguageCodes
  ): Promise<AxiosResponse<ITranslationResponse[]>> {
    const url = `/Shared/Languages/${languageCode}/Translations`;

    const serverResponse = await api.get(url, {
      params: {
        usedLocation: "ArticlesFixedTranslations",
      },
    });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
