import { CircleDot } from "../../assets/icons/CircleDot";
import { SquareDot } from "../../assets/icons/SquareDot";
import { TriangleDot } from "../../assets/icons/TriangleDot";

export function CustomRechartsDot(props: any) {
  const { shape } = props;

  return (
    <>
      {shape === "circle" && <CircleDot {...props} />}
      {shape === "line" && <CircleDot {...props} fill="transparent" />}
      {shape === "square" && <SquareDot {...props} />}
      {shape === "triangle" && <TriangleDot {...props} />}
    </>
  );
}
