export function ReturnArrow({ ...rest }) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3.9343 12L12 3.93426L13.1995 5.13371L7.18485 11.1484H20.0658V12.8516H7.18485L13.1995 18.8723L12 20.0658L3.9343 12Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
