export function CircleChecked({ ...rest }) {
  return (
    <svg
      {...rest}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" fill="#58C488" />
      <circle cx="10" cy="10" r="9.3" stroke="#A1DEBC" strokeWidth="1.4" />
      <path
        d="M8.36713 12.1L14.0171 6.45C14.1505 6.31667 14.3088 6.25 14.4921 6.25C14.6755 6.25 14.8338 6.31667 14.9671 6.45C15.1005 6.58333 15.1671 6.74167 15.1671 6.925C15.1671 7.10833 15.1005 7.26667 14.9671 7.4L8.8338 13.5333C8.70046 13.6667 8.54491 13.7333 8.36713 13.7333C8.18935 13.7333 8.0338 13.6667 7.90046 13.5333L5.0338 10.6667C4.90046 10.5333 4.83657 10.375 4.84213 10.1917C4.84769 10.0083 4.91713 9.85 5.05046 9.71667C5.1838 9.58333 5.34213 9.51667 5.52546 9.51667C5.7088 9.51667 5.86713 9.58333 6.00046 9.71667L8.36713 12.1Z"
        fill="white"
      />
    </svg>
  );
}
