/* eslint-disable jsx-a11y/control-has-associated-label */
import { roundToX } from "../../../../../../utils/unitOperations";
import { StyledTable, TableContainer } from "./SpirometryValuesSection.styles";

type SpirometryValuesSectionProps = {
  bestValues?: {
    fvc: number | string;
    pef: number | string;
    fev1: number | string;
    fev1fvc: number | string;
    predictedFev1: number | string;
    predictedFvc: number | string;
  };
};

export function SpirometryValuesSection({
  bestValues,
}: SpirometryValuesSectionProps) {
  const { fvc, fev1, predictedFvc, predictedFev1, fev1fvc, pef } =
    bestValues || {};

  const getPercentPredicted = (value: string, predicted: string) => {
    return roundToX(
      (Number.parseFloat(value) / Number.parseFloat(predicted)) * 100,
      2
    );
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <th />
            <th>Best</th>
            <th>% Pred</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>FVC (L)</td>
            <td>{fvc ?? "-"}</td>
            <td>
              {predictedFvc && fvc
                ? getPercentPredicted(fvc.toString(), predictedFvc.toString())
                : "-"}
            </td>
          </tr>
          <tr>
            <td>FEV1 (L)</td>
            <td>{fev1 ?? "-"}</td>
            <td>
              {predictedFev1 && fev1
                ? getPercentPredicted(fev1.toString(), predictedFev1.toString())
                : "-"}
            </td>
          </tr>
          <tr>
            <td>FEV1/FVC (%)</td>
            <td>{fev1fvc ?? "-"}</td>
            <td />
          </tr>
          <tr>
            <td>PEF (L/s)</td>
            <td>{pef ?? "-"}</td>
            <td />
          </tr>
        </tbody>
      </StyledTable>
    </TableContainer>
  );
}
