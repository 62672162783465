export function CircleDoubleTick(props: any) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill={color} />
      <path
        d="M8.43813 15.6311L4.40001 11.5929L5.77735 10.2156L8.43813 12.8764L9.81548 14.2537L8.43813 15.6311ZM11.9598 15.1302L7.89033 11.0608L9.29898 9.68343L11.9598 12.3442L17.6883 6.6157L19.0969 7.99305L11.9598 15.1302ZM11.9598 11.0764L10.5824 9.69908L13.6814 6.60005L15.0588 7.9774L11.9598 11.0764Z"
        fill="white"
      />
    </svg>
  );
}
