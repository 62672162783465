export function DragHandle({ ...rest }) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85417 0.270996H0.5625V2.56266H2.85417V0.270996ZM7.4375 0.270996H5.14583V2.56266H7.4375V0.270996ZM0.5625 4.85433H2.85417V7.146H0.5625V4.85433ZM7.4375 4.85433H5.14583V7.146H7.4375V4.85433ZM0.5625 9.43766H2.85417V11.7293H0.5625V9.43766ZM7.4375 9.43766H5.14583V11.7293H7.4375V9.43766Z"
        fill="#3C3C3C"
      />
    </svg>
  );
}
