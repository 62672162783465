import {
  Dropdown,
  DropdownTextItem,
  IOption,
  SelectDropdown,
} from "@patientmpower/spiro";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { Notification } from "../../@types/Notification";
import { IWorkflowsTableContent } from "../../@types/Worflows";
import { CircleChecked } from "../../assets/icons/CircleChecked";
import { CircleUnchecked } from "../../assets/icons/CircleUncheked";
import { Dots } from "../../assets/icons/Dots";
import { CustomIconButton } from "../../components/CustomIconButton";
import { ActionContainer } from "../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { ToastNotification } from "../../components/ToastNotification";
import {
  AUTH_USER_DETAILS,
  NOTIFICATION,
} from "../../constants/localStorageKeys";
import { useWorkflows } from "../../hooks/queries/workflows";
import { useModal } from "../../hooks/useModal";
import { PageLayout } from "../../layout/components/PageLayout";
import { PageTitle } from "../../layout/components/PageTitle";
import { hospitalService } from "../../services/hospitalService";
import { getLongFormattedDate } from "../../utils/dateFormatter";
import { mixpanelActions } from "../../utils/mixpanel";
import { WorflowListTable } from "./components/WorflowListTable";
import { WorkflowStatusChangeModal } from "./components/WorkflowsBuilder/components/WorkflowStatusChangeModal";
import {
  AddWorkflowButton,
  HospitalGroupSelectCss,
  PageTitleContainer,
  PMPHospitalGroupSelect,
  WorflowsTableContainer,
  WorkflowListContainer,
} from "./PatientWorkflows.styles";

export function WorkflowsList() {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: "max-content",
    rightMargin: "50%",
  });
  const [isPMPUser, setIsPMPUser] = useState(false);
  const [hospitalGroupOptions, setHospitalGroupOptions] = useState<IOption[]>(
    []
  );
  const [selectedHospitalGroup, setSelectedHospitalGroup] = useState<
    string | undefined
  >(undefined);

  const { workflows, isFetching, isRefetching, refetch } = useWorkflows(
    Number.parseInt(selectedHospitalGroup ?? "", 10)
  );

  const parsedArticles = useMemo(() => {
    if (!workflows || workflows.length === 0) return [];

    return workflows?.map((workflow: IWorkflowsTableContent) => {
      const options: any[] = ["Unpublish", "Publish"];

      const handleOnModalClose = (
        notification?: Notification,
        refetchTable?: boolean
      ) => {
        if (notification) {
          setNotification(notification);

          setTimeout(() => {
            setNotification({
              show: false,
              message: "notificationMessage",
              type: "error",
              width: undefined,
              rightMargin: "50%",
            });
          }, 3500);
        }

        if (refetchTable) {
          refetch();
        }

        closeModal();
      };

      return {
        id: workflow.id,
        title: workflow.title,
        description: workflow.description,
        updated:
          workflow.updated !== undefined
            ? getLongFormattedDate(workflow.updated)
            : getLongFormattedDate(workflow.created),
        published: (
          <div style={{ marginLeft: "30px" }}>
            {workflow.published ? <CircleChecked /> : <CircleUnchecked />}
          </div>
        ),
        action: (
          <div>
            <ActionContainer
              className="actionContainer"
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown
                trigger={
                  <CustomIconButton>
                    <Dots />
                  </CustomIconButton>
                }
                value=""
                onValueChange={(val: string) => {
                  openModal(
                    <WorkflowStatusChangeModal
                      workflowId={workflow.id}
                      published={val === options[1]}
                      isWorkflowList
                      onClose={handleOnModalClose}
                    />,
                    {
                      width: "623px",
                      height: "100%",
                      showCloseButton: true,
                    }
                  );
                }}
              >
                {workflow.published ? (
                  <DropdownTextItem key="Unpublish" text="Unpublish" />
                ) : (
                  <DropdownTextItem key="Publish" text="Publish" />
                )}
              </Dropdown>
            </ActionContainer>
          </div>
        ),
      } as IWorkflowsTableContent;
    });
  }, [workflows]);

  const handleOnRowClick = (rowContent: IWorkflowsTableContent) => {
    navigate(
      `/workflows/builder/${rowContent.id}${
        selectedHospitalGroup ? `?hospitalGroupId=${selectedHospitalGroup}` : ""
      }`
    );
  };

  const handleAddWorkflowClick = () => {
    navigate(
      `/workflows/builder${
        selectedHospitalGroup ? `?hospitalGroupId=${selectedHospitalGroup}` : ""
      }`
    );
  };

  useEffect(() => {
    mixpanelActions.track("PageView: Care Info");
  }, []);

  useEffect(() => {
    mixpanelActions.track("PageView: Workflows");

    const savedNotification = localStorage.getItem(NOTIFICATION);

    if (savedNotification) {
      const parsedNotification = JSON.parse(savedNotification) as Notification;

      setNotification(parsedNotification);

      localStorage.removeItem(NOTIFICATION);

      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: undefined,
          rightMargin: "50%",
        });
      }, 3500);
    }

    refetch();

    const localStorageUserDetails =
      localStorage.getItem(AUTH_USER_DETAILS) ?? "";
    const parsedUserDetails = JSON.parse(localStorageUserDetails);

    const userEmail = parsedUserDetails?.userEmail as string;

    const isPMPUser = userEmail.includes("patientmpower");

    setIsPMPUser(isPMPUser);

    if (isPMPUser) {
      hospitalService.getHospitalGroups().then((hospitalGroups) => {
        if (hospitalGroups.data === null) return;

        const groupsToAdd: IOption[] = [];

        hospitalGroups.data.forEach((group) => {
          groupsToAdd.push({
            label: group.hospitalGroupName,
            value: group.hospitalGroupId.toString(),
            key: uuidv4(),
          });
        });

        setHospitalGroupOptions(groupsToAdd);
      });
    }
  }, []);

  return (
    <PageLayout>
      <WorkflowListContainer>
        {isPMPUser ? (
          <PMPHospitalGroupSelect>
            <SelectDropdown
              options={hospitalGroupOptions ?? []}
              height={30}
              placeholder="Select hospital"
              optionFilterProp="label"
              value={selectedHospitalGroup}
              onValueChange={(value: string | string[]) => {
                setSelectedHospitalGroup(value.toString());
              }}
              className={HospitalGroupSelectCss()}
            />
          </PMPHospitalGroupSelect>
        ) : null}
        <PageTitleContainer>
          <PageTitle>Workflows</PageTitle>
        </PageTitleContainer>

        <WorflowsTableContainer>
          <AddWorkflowButton onClick={handleAddWorkflowClick}>
            Add workflow
          </AddWorkflowButton>
          <WorflowListTable
            content={parsedArticles}
            isLoading={isFetching || isRefetching}
            onRowClick={handleOnRowClick}
          />
        </WorflowsTableContainer>
      </WorkflowListContainer>

      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </PageLayout>
  );
}
