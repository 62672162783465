export function QuestionMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.4632 6.68106C12.4632 5.99302 12.2332 5.44039 11.7733 5.02318C11.3133 4.60599 10.7062 4.39739 9.95195 4.39739C9.47438 4.39739 9.05104 4.49488 8.68191 4.68985C8.31279 4.88483 7.99414 5.17838 7.72597 5.57047C7.56786 5.79375 7.3566 5.92569 7.09218 5.96629C6.82776 6.00688 6.59674 5.94225 6.3991 5.77239C6.25272 5.64525 6.16912 5.4858 6.14829 5.29404C6.12745 5.10227 6.17206 4.92039 6.2821 4.74839C6.70518 4.10843 7.22842 3.62179 7.85181 3.28845C8.4752 2.95512 9.17524 2.78845 9.95195 2.78845C11.1923 2.78845 12.2019 3.14369 12.9808 3.85416C13.7596 4.56462 14.149 5.48983 14.149 6.62979C14.149 7.23341 14.0198 7.78656 13.7612 8.28922C13.5027 8.7919 13.062 9.33277 12.4391 9.91183C11.8558 10.4407 11.4578 10.8699 11.2452 11.1995C11.0326 11.5291 10.9092 11.8996 10.875 12.3109C10.8408 12.5481 10.7425 12.7457 10.5801 12.9038C10.4178 13.0619 10.2222 13.141 9.99362 13.141C9.76499 13.141 9.56949 13.0627 9.4071 12.9062C9.24471 12.7497 9.16351 12.5571 9.16351 12.3285C9.16351 11.7708 9.29092 11.2609 9.54572 10.7988C9.80054 10.3368 10.2271 9.83972 10.8254 9.30766C11.4643 8.74677 11.897 8.27883 12.1235 7.90383C12.35 7.52883 12.4632 7.12124 12.4632 6.68106ZM9.95195 17.9166C9.61115 17.9166 9.31762 17.7935 9.07137 17.5472C8.82512 17.301 8.70199 17.0074 8.70199 16.6666C8.70199 16.3258 8.82512 16.0323 9.07137 15.7861C9.31762 15.5398 9.61115 15.4167 9.95195 15.4167C10.2928 15.4167 10.5863 15.5398 10.8325 15.7861C11.0788 16.0323 11.2019 16.3258 11.2019 16.6666C11.2019 17.0074 11.0788 17.301 10.8325 17.5472C10.5863 17.7935 10.2928 17.9166 9.95195 17.9166Z"
        fill="#939393"
      />
    </svg>
  );
}
