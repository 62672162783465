import { styled, IconButton } from "@patientmpower/spiro";

export const closedPatientLateralMenuCardContainerWidth = 32;
export const expandedPatientLateralMenuCardContainerWidth = 282;

export const PatientLateralMenu = styled("div", {
  zIndex: 800,

  top: "62px",
  left: "100%",
  display: "flex",
  position: "sticky",

  backgroundColor: "$white",

  border: "1px solid $gray-40",

  width: closedPatientLateralMenuCardContainerWidth,
  height: "calc(100vh - 61px)",

  transition: "width .4s",

  variants: {
    isLateralPatientMenuOpenOnDesktop: {
      true: {
        zIndex: 1000,

        width: expandedPatientLateralMenuCardContainerWidth,
      },
    },
  },

  "@sm": {
    display: "none",
  },
});

export const PatientLateralMenuCardContainer = styled("div", {
  position: "fixed",

  width: expandedPatientLateralMenuCardContainerWidth - 2,
  height: "calc(100vh - 62px)",

  overflowY: "auto",
  overflowX: "hidden",

  transition: "opacity .150ms",

  "&::-webkit-scrollbar": {
    width: "0px",
  },

  variants: {
    isLateralPatientMenuOpenOnDesktop: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
});

export const ExpandButton = styled(IconButton, {
  bottom: "50px",

  alignSelf: "center",
  marginLeft: -15,
  transition: "transform .4s",
  position: "fixed",

  transform: "rotate(180deg)",

  div: {
    paddingLeft: 10,
  },

  variants: {
    isExpanded: {
      true: {
        transform: "rotate(360deg)",
      },
    },
  },
});

export const EditButtonContainer = styled("div", {
  zIndex: "$1",
  position: "fixed",
  bottom: "9px",

  maxHeight: 52,
  width: expandedPatientLateralMenuCardContainerWidth,

  overflow: "hidden",

  display: "flex",
  justifyContent: "center",
  padding: "12px 20px 12px 12px",
});

export const EditButton = styled(IconButton, {
  backgroundColor: "$gray-50",
  width: "67px",
  height: "24px",
  borderRadius: "10px",

  color: "$black",
  fontSize: "12px",
  fontFamily: "$openSans",
  fontWeight: "600",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$gray-60 !important",
  },
});

export const EditControlsContainer = styled("div", {
  backgroundColor: "$black",

  width: 168,
  height: 48,
  borderRadius: 10,

  position: "absolute",
  bottom: 80,
  left: "50%",
  translate: "-50%",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: 19,
});

export const EditControlsButton = styled("button", {
  cursor: "pointer",

  fontFamily: "$openSans",
  fontSize: "$text-md",
  fontWeight: 600,

  border: "none",
  borderRadius: "$4",

  variants: {
    primary: {
      true: {
        width: 60,
        height: 26,
        backgroundColor: "$white",

        "&:hover": {
          opacity: "0.9",
        },
      },
      undefined: {
        color: "$white",
        backgroundColor: "transparent",

        "&:hover": {
          opacity: "0.9",
        },
      },
    },
  },
});
