import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  position: "relative",
});

export const DownloadReportTitle = styled("p", {
  color: "$charcoal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "22px",
  cursor: "pointer",
  position: "absolute",
  top: "-26px",
  right: "10px",
});
