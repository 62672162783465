export function CircleTick(props: any) {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill={color} />
      <path
        d="M9.85547 15.796L5.55499 11.4955L7.04362 10.0069L9.85547 12.8188L15.9092 6.76502L17.3978 8.25365L9.85547 15.796Z"
        fill="white"
      />
    </svg>
  );
}
