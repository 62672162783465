import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "auto",
  width: "70vw",

  "@md": {
    width: "85vw",
  },
});

export const ContentContainer = styled("div", {
  height: "auto",
  width: "auto",
  margin: "0 22px 0 22px",
  padding: "28px 0px 16px 28px",
  backgroundColor: "$gray-20",
  borderRadius: "8px",
});

export const ScrollableContainer = styled("div", {
  height: "auto",
  width: "101.5%",
  maxHeight: "65vh",
  overflowY: "auto",
  borderRadius: "8px",

  "&::-webkit-scrollbar": {
    width: "$4",
  },

  "&:hover": {
    "&::-webkit-scrollbar": {
      width: "$4",
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "$gray-50",
      borderRadius: "10px",
    },
  },
});

export const SelectSection = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  marginBottom: "20px",
});

export const Field = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  width: "175px",
  margin: "0 10px 10px 0",

  ".ant-select": {
    height: "42px !important",
  },

  p: {
    color: "#727272",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "22px",
    margin: "0 0 8px 6px",
  },

  variants: {
    isHCPMessage: {
      true: {
        width: "100%",
      },
    },
  },
});

export const HCPMessage = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  width: "80%",
  minWidth: "600px",
  margin: "0 10px 10px 4px",
  fontSize: "14px",
  caretColor: "$gray-70",
  userSelect: "none",
  color: "rgba(0, 0, 0, 0.48)",
});

export const AdditionalButton = styled("div", {
  width: "fit-content",
  height: "26px",
  borderRadius: "12px",
  color: "$black",
  backgroundColor: "$gray-50",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "2px 12px",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "600",
  margin: "10px 0 24px 22px",
  cursor: "pointer",

  p: {
    marginLeft: "4px",
  },

  "&:hover": {
    backgroundColor: "$gray-40",
  },

  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
        opacity: 0.7,

        "&:hover": {
          backgroundColor: "$gray-50",
        },
      },
    },
  },
});

export const ResolveTriggerContainer = styled("div", {
  height: "100%",
  backgroundColor: "#F8F8F8",
  padding: "24px 20px",
  margin: "0 20px 0 20px",
  borderRadius: "8px",

  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

export const SubmitContainer = styled("div", {
  display: "flex",
  gap: "40px",
  padding: "24px 22px 24px 22px",
  alignItems: "center",
});

export const Row = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  lineHeight: "30px",
});

export const ComponentTitle = styled("div", {
  display: "flex",
  alignSelf: "flex-start",
  width: "32%",
  paddingLeft: "10px",
  color: "#757575",
  fontSize: "14px",
  fontWeight: "500",
});

export const Component = styled("div", {
  display: "flex",
  alignSelf: "flex-start",
  width: "68%",
  paddingLeft: "10px",
  color: "#4B4B4B",
  fontSize: "14px",
  fontWeight: "400",
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",
  padding: "24px 22px 32px 22px",
  color: "$black",
});

export const NoteSection = styled("div", {
  width: "60%",
  height: "95px",
  borderRadius: "8px",
  backgroundColor: "rgba(233, 230, 244, 0.60)",
  margin: "20px 0 0 22px",
  padding: "20px 21px 27px 21px",
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  p: {
    color: "$primary-70",
  },
});
