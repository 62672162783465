import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import {
  ISectionProps,
  PatientMessagesTableContent,
} from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { getPatientMessagesData } from "../../../../hooks/queries/messages";
import useIsMobile from "../../../../hooks/useIsMobile";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { PatientMessagesTable } from "../PatientMessagesTable";
import { NewDataCOntainer, TitleContainer } from "./MessagesSection.styles";

export function MessagesSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const { isMobile } = useIsMobile();
  const highlightedRows: boolean[] = [];

  const [showNewData, setShowNewData] = useState<boolean>(false);

  const { patientMessagesTable, isError, isFetching, refetch, isRefetching } =
    getPatientMessagesData(patient.id);

  const handleRefreshTable = () => {
    refetch();
  };

  const parsePatientMessagesToTable = useMemo(() => {
    if (!patientMessagesTable) return [];

    const applyElliples = (lastMessage: string) => {
      return lastMessage.length > 55
        ? `${lastMessage.substring(0, 55)}...`
        : lastMessage;
    };

    return patientMessagesTable.map(
      (patientMessage: PatientMessagesTableContent) => {
        if (patientMessage.sentBy === "App" && !patientMessage.isRead) {
          highlightedRows.push(true);
        } else {
          highlightedRows.push(false);
        }

        return {
          id: patientMessage.id,
          title: patientMessage.title,
          sentBy: patientMessage.sentBy,
          isSent: patientMessage.isSent,
          sentDate: patientMessage.sentDate,
          isAnswered: patientMessage.isAnswered,
          isDelivered: patientMessage.isDelivered,
          deliveredDate: patientMessage.deliveredDate,
          isRead: patientMessage.isRead,
          readDate: patientMessage.readDate,
          response: patientMessage.response,
          createdBy: patientMessage.createdBy,
          body: patientMessage.body,
          responseType: patientMessage.responseType,
          responseOptions: patientMessage.responseOptions,
          responseDate: patientMessage.responseDate,
          sentProvider: patientMessage.sentProvider,
          createdByEmail: patientMessage.createdByEmail,
          freeResponse: patientMessage.freeResponse,
          lastMessage:
            patientMessage.sentBy === "App" && !patientMessage.isRead ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {applyElliples(patientMessage.lastMessage)}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 6,
                    width: 17,
                    height: 17,
                    borderRadius: "50%",
                    backgroundColor: "#3B787B",
                    textAlign: "center",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  1
                </div>
              </div>
            ) : (
              applyElliples(patientMessage.lastMessage)
            ),
          sent: patientMessage.sent,
        };
      }
    );
  }, [patientMessagesTable]);

  useEffect(() => {
    if (
      collapsedSection.value === true &&
      !isFetching &&
      !isRefetching &&
      patientMessagesTable.length > 0
    ) {
      const collapsedDate = dayjs(collapsedSection.collapsedDate);

      patientMessagesTable.forEach((message: PatientMessagesTableContent) => {
        const sentDate = dayjs(message.sentDate);
        const deliveredDate = message.deliveredDate
          ? dayjs(message.deliveredDate)
          : dayjs("2000-01-01");
        const readDate = message.readDate
          ? dayjs(message.readDate)
          : dayjs("2000-01-01");

        if (
          sentDate > collapsedDate ||
          deliveredDate > collapsedDate ||
          readDate > collapsedDate
        ) {
          setShowNewData(true);
        }
      });
    }
  }, [patientMessagesTable]);

  const handleCollapseSection = () => {
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
    updateColapsedSections("messagesSection");
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Messages</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}
      <div
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <PatientMessagesTable
          patientId={patient.id}
          patientPhoneNumber={patient.user.phoneNumber}
          isLoading={isFetching && !isRefetching}
          isError={isError}
          content={parsePatientMessagesToTable}
          highlightedRows={highlightedRows}
          refreshTable={handleRefreshTable}
          showSendMessageButton={!collapsedSection.value}
        />
      </div>
    </>
  );
}
