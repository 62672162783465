import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

import { IUser } from "../../../../@types/User";
import { ToastNotification } from "../../../../components/ToastNotification";
import { AUTH_UNIT_PREFERENCES } from "../../../../constants/localStorageKeys";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { userService } from "../../../../services/userService";
import { SecurityModal } from "../SecurityModal";
import { SecurityContainer, EnabledButton } from "./SecuritySection.styles";

export function SecuritySection() {
  const { openModal } = useModal();
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [wasDisableSuccessful, setWasDisableSuccessful] = useState(false);
  const [wasEnableSuccessful, setWasEnableSuccessful] = useState(false);
  const [user, setUser] = useState<IUser>();
  const { widthView } = useIsMobile();

  useEffect(() => {
    const userUnitPreferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);
    const userUnitPreferencesParsed = JSON.parse(userUnitPreferences ?? "");

    userService
      .getUserById(userUnitPreferencesParsed.userId)
      .then((serverUser) => {
        setUser(serverUser);
        if (serverUser.mfa?.length) {
          setIsMfaEnabled(true);
        }
      });
  }, []);

  const disableMfa = () => {
    userService.disableUserMfa().then((response: AxiosResponse) => {
      if (response.status === 200) {
        setIsMfaEnabled(false);
        setWasDisableSuccessful(true);
        setTimeout(() => {
          setWasDisableSuccessful(false);
        }, 3500);
      }
    });
  };

  const handleEnableSuccess = () => {
    setWasEnableSuccessful(true);
    setTimeout(() => {
      setWasEnableSuccessful(false);
    }, 3500);
  };

  const handleModalWidth = (): string => {
    const x = widthView <= 768 ? "90vw" : "611px";
    return x;
  };

  const enableMfa = () => {
    openModal(
      <SecurityModal
        setIsMfaEnabled={setIsMfaEnabled}
        onClose={handleEnableSuccess}
        email={user?.email ?? ""}
      />,
      {
        width: handleModalWidth(),
        showCloseButton: false,
      }
    );
  };

  return (
    <>
      <br />
      <br />
      <PageTitle>Security</PageTitle>

      <SecurityContainer>
        MFA login
        {isMfaEnabled ? (
          <EnabledButton onClick={disableMfa}>Turn off MFA</EnabledButton>
        ) : (
          <EnabledButton onClick={enableMfa}>Turn on MFA</EnabledButton>
        )}
      </SecurityContainer>
      {wasDisableSuccessful && (
        <ToastNotification
          message="MFA successfully disabled for your account"
          type="success"
        />
      )}
      {wasEnableSuccessful && (
        <ToastNotification
          message="MFA successfully setup for your account"
          type="success"
        />
      )}
    </>
  );
}
