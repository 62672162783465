import { createColumn } from "../../../../components/Table";

export const desktopTableColumns = [
  createColumn({
    keyName: "title",
    header: () => "Subject",
    cell: (info) => info.renderValue(),
    size: 250,
  }),
  createColumn({
    keyName: "lastMessage",
    header: () => "Last Message",
    cell: (info) => info.renderValue(),
    size: 300,
  }),
  createColumn({
    keyName: "sent",
    header: () => "Sent",
    cell: (info) => info.renderValue(),
    size: 150,
  }),
  createColumn({
    keyName: "createdBy",
    header: () => "Created by",
    cell: (info) => info.renderValue(),
    size: 100,
  }),
];
