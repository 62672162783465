export function RuleIcon({ ...rest }) {
  return (
    <svg
      {...rest}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0679 17.8744L12.1106 16.9171L14.494 14.5338L12.1106 12.1505L13.0679 11.1932L15.4512 13.5766L17.8345 11.1932L18.7917 12.1505L16.4084 14.5338L18.7917 16.9171L17.8345 17.8744L15.4512 15.491L13.0679 17.8744ZM14.887 9.93282L11.9591 7.00476L12.9161 6.04753L14.8641 7.99544L18.7599 4.09961L19.7171 5.07976L14.887 9.93282ZM2.29175 15.1773V13.8023H10.0834V15.1773H2.29175ZM2.29175 8.19642V6.82142H10.0834V8.19642H2.29175Z"
        fill="#212121"
      />
    </svg>
  );
}
