import { FlagFilled } from "../../../../../../assets/icons/FlagFilled";
import { FlagOutlined } from "../../../../../../assets/icons/FlagOutlined";
import { CustomIconButton } from "../../../../../../components/CustomIconButton";
import { FlagContainer } from "./FlagPatientButton.styles";

type FlagPatientButtonProps = {
  flagged: boolean;
  onClick: (event: any) => void;
  isMobile?: boolean;
  isPatientsPage?: boolean;
};

export function FlagPatientButton({
  flagged,
  onClick,
  isMobile,
  isPatientsPage,
}: FlagPatientButtonProps) {
  if (isPatientsPage) {
    if (flagged) {
      return (
        <FlagContainer onClick={onClick}>
          <FlagFilled />
        </FlagContainer>
      );
    }

    return (
      <FlagContainer onClick={onClick}>
        <FlagOutlined />
      </FlagContainer>
    );
  }

  if (flagged) {
    return (
      <CustomIconButton onClick={onClick}>
        <FlagFilled />
      </CustomIconButton>
    );
  }

  return (
    <CustomIconButton
      onClick={onClick}
      className={!isMobile ? "actionContainer" : ""}
    >
      <FlagOutlined />
    </CustomIconButton>
  );
}

FlagPatientButton.defaultProps = {
  isMobile: false,
};
