export function IncreaseArrow({ ...rest }) {
  return (
    <svg
      {...rest}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2.95069L15.0493 9L14.1497 9.89959L9.63872 5.3886V15.0493H8.36128V5.3886L3.8458 9.89959L2.95069 9L9 2.95069Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
