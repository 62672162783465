import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  IPatientMedication,
  IPatientMedicationsResponse,
} from "../../@types/Patient";
import { medicationService } from "../../services/medicationService";

dayjs.extend(customParseFormat);

export function getPatientMedicationsData(patientId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-medications-table-${patientId}`, patientId],
    async () => {
      if (!patientId) return {} as IPatientMedication;

      const { data } = await medicationService.getMedications(patientId);

      return data as IPatientMedication;
    }
  );

  const patientMedication = data as IPatientMedication;

  return {
    patientMedicationTable: patientMedication?.medications?.map(
      (medicationData) => {
        let time = "-";
        if (medicationData.time) {
          time = dayjs(medicationData.time, "HH:mm:ss").format("hh:mm A");
        }

        return {
          ...medicationData,
          time,
          interval: medicationData.interval || "-",
        } as IPatientMedicationsResponse;
      }
    ),
    medicationsMaxDate: patientMedication?.maxDate,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
