import { createContext, ReactNode, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { IRoutePattern } from "../@types/Route";
import { routesWithChildRoutes } from "../routes/routes";

type IChildRoutesContextData = {
  childRoutes: Array<IRoutePattern>;
  hideChildRoute: (name: string) => void;
};

type IChildRoutesProvider = {
  children: ReactNode;
};

export const ChildRoutesContext = createContext({} as IChildRoutesContextData);

export function ChildRoutesProvider({ children }: IChildRoutesProvider) {
  const [routes, setRoutes] = useState(routesWithChildRoutes);
  const location = useLocation();

  async function hideChildRoute(name: string) {
    const updatedRoutes = routesWithChildRoutes.map((route) => ({
      paths: route.paths,
      childRoutes: route.childRoutes.filter((childRoute) => {
        return childRoute.name !== name;
      }),
    }));

    setRoutes(updatedRoutes);
  }

  const valuesToShare = useMemo(() => {
    /**
     * This piece of code will match the routes that have the same
     * child routes.
     */
    const activeChildRoute =
      routes.find(({ paths }) => {
        return paths.find((path) =>
          matchPath(
            {
              path,
            },
            location.pathname
          )
        );
      })?.childRoutes || [];

    return {
      childRoutes: activeChildRoute,
      hideChildRoute,
    };
  }, [location, routes]);

  return (
    <ChildRoutesContext.Provider value={valuesToShare}>
      {children}
    </ChildRoutesContext.Provider>
  );
}
