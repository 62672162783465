export function PhoneDevice({ ...rest }) {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 11.75V6.25H1.83333V11.75H0ZM2.75 13.5833V4.41667H4.58333V13.5833H2.75ZM20.1667 11.75V6.25H22V11.75H20.1667ZM17.4167 13.5833V4.41667H19.25V13.5833H17.4167ZM7.33333 17.25C6.82917 17.25 6.39757 17.0705 6.03854 16.7115C5.67951 16.3524 5.5 15.9208 5.5 15.4167V2.58333C5.5 2.07917 5.67951 1.64757 6.03854 1.28854C6.39757 0.929514 6.82917 0.75 7.33333 0.75H14.6667C15.1708 0.75 15.6024 0.929514 15.9615 1.28854C16.3205 1.64757 16.5 2.07917 16.5 2.58333V15.4167C16.5 15.9208 16.3205 16.3524 15.9615 16.7115C15.6024 17.0705 15.1708 17.25 14.6667 17.25H7.33333ZM7.33333 15.4167H14.6667V2.58333H7.33333V15.4167Z"
        fill="#727272"
      />
    </svg>
  );
}
