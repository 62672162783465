import { Button } from "@patientmpower/spiro";
import { useState } from "react";

import { Notification } from "../../../../../../@types/Notification";
import { workflowsService } from "../../../../../../services/worflowsService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  CancelButton,
  Description,
  ModalContainer,
  SubmitContainer,
  Title,
} from "./WorkflowStatusChangeModal.styles";

type WorkflowStatusChangeModalProps = {
  workflowId?: string;
  published: boolean;
  isWorkflowList?: boolean;
  onClose(
    notification?: Notification,
    refetchTable?: boolean,
    saveWorkflow?: boolean
  ): void;
};

export function WorkflowStatusChangeModal({
  workflowId,
  published,
  isWorkflowList,
  onClose,
}: WorkflowStatusChangeModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  mixpanelActions.track(
    `Visited Screen: ${published ? "Publish" : "Unpublish"} Modal`
  );

  const handleWorkflowStatusChange = async () => {
    if (!isWorkflowList || !workflowId) {
      onClose(undefined, undefined, true);
      return;
    }

    const result = await workflowsService.updateWorkflowStatus(
      workflowId,
      published
    );

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    setIsLoading(false);

    if (result.status >= 200 && result.status < 300) {
      notification.message = `Workflow successfully ${
        published ? "published" : "unpublished"
      }`;

      onClose(notification, true);
    } else {
      notification.message = `Error ${
        published ? "publishing" : "unpublishing"
      } workflow, please try again`;
      notification.type = "error";

      onClose(notification, true);
    }
  };

  const handleModalDescriptionMessage = () => {
    if (workflowId && !isWorkflowList && published) {
      return "Please confirm you would like to publish the changes you’ve made.";
    }

    if (!published)
      return "All patients currently enrolled in this workflow will be unable to access this workflow. This includes patients in progress.";

    return "Please confirm you would like to publish the workflow.";
  };

  return (
    <ModalContainer>
      <Title>{`Confirm ${published ? "publish" : "unpublish"}`}</Title>
      <Description>{handleModalDescriptionMessage()}</Description>
      <SubmitContainer>
        <Button
          label={`${published ? "Publish" : "Unpublish"}`}
          type="submit"
          onClick={() => {
            handleWorkflowStatusChange();
            mixpanelActions.track(
              `User Action: ${
                published ? "Publish workflow" : "Unpublish workflow"
              }`
            );
          }}
          isLoading={isLoading}
        />

        <CancelButton
          onClick={() => {
            mixpanelActions.track(
              `User Action: Cancel ${published ? "publish" : "unpublish"} modal`
            );
            onClose();
          }}
          style={{ marginLeft: "-40px" }}
        >
          Cancel
        </CancelButton>
      </SubmitContainer>
    </ModalContainer>
  );
}
