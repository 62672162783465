import { styled } from "@patientmpower/spiro";

// Components
export const ContentContainer = styled("section", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  width: "100%",
  height: "100%",

  padding: "42px 0 0 0",

  backgroundColor: "$gray-20",
  overflowY: "auto",
});

export const SettingsPageConatiner = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",

  width: "90%",
  fontFamily: "$openSans",
});

export const SettingsPageContent = styled("div", {
  transition: "width .4s",

  width: "100%",

  "@sm": {
    width: "100%",
  },
});

export const StyledSection = styled("section", {
  scrollMarginTop: 158,
  marginTop: 64,
  fontFamily: "$openSans",

  "&:first-child": { marginTop: 0 },

  "@sm": {
    marginTop: 0,
  },
});
