import { styled } from "@patientmpower/spiro";

export const MessagesPreferencesContainer = styled("div", {
  width: "100%",
  height: "160px",

  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  marginTop: "6px",
  padding: "0 0 30px 55px",

  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "$openSans",

  color: "$gray-100",
  backgroundColor: "$white",
  borderRadius: "12px",
  border: "1px solid $gray-40",
});

export const ParentContainer = styled("div", {
  marginTop: "50px",
});

export const TextContainer = styled("div", {
  display: "flex",
  alignItems: "top",
  justifyContent: "center",

  height: "22px",
});
