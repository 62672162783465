import { AxiosResponse } from "axios";

import { ICreateTriggerProps } from "../@types/Alerts";
import { api } from "./api";

export const alertsService = {
  async getTriggersByPatientId(patientId: number): Promise<AxiosResponse> {
    const url = `/Portal/Alerts/GetTriggersByPatientId/${patientId}/`;

    const triggers = await api.get(url);

    return triggers;
  },

  async getTriggerByMeasurementAlertId(
    measurementAlertId: string
  ): Promise<AxiosResponse> {
    const url = `/Portal/Alerts/GetTriggerByMeasurementAlertId/${measurementAlertId}/`;

    const trigger = await api.get(url);

    return trigger;
  },

  async getTriggerBytAlertId(alertId: string): Promise<AxiosResponse> {
    const url = `/Portal/Alerts/GetTriggerByAlertId/${alertId}/`;

    const trigger = await api.get(url);

    return trigger;
  },

  async createTrigger(
    createTriggerProps: ICreateTriggerProps
  ): Promise<AxiosResponse> {
    const url = `/Portal/Alerts`;

    const {
      measurementType,
      measurementType2,
      unit,
      unit2,
      comparator,
      comparator2,
      threshold,
      threshold2,
      baseline,
      baseline2,
      timeFrameDays,
      percent,
      percent2,
      hcpNotification,
      hcpMessage,
      secondTrigger,
      patientId,
    } = createTriggerProps;

    const response = await api.post(url, {
      measurementType,
      measurementType2,
      unit,
      unit2,
      comparator,
      comparator2,
      threshold,
      threshold2,
      baseline,
      baseline2,
      timeFrameDays,
      percent,
      percent2,
      hcpNotification,
      hcpMessage,
      secondTrigger,
      patientId,
    });

    return response;
  },

  async editTrigger(
    createTriggerProps: ICreateTriggerProps,
    measurementAlertId?: string
  ): Promise<AxiosResponse> {
    const url = `/Portal/Alerts/EditTrigger/${measurementAlertId}`;

    const {
      measurementType,
      measurementType2,
      unit,
      unit2,
      comparator,
      comparator2,
      threshold,
      threshold2,
      baseline,
      baseline2,
      timeFrameDays,
      percent,
      percent2,
      hcpNotification,
      hcpMessage,
      secondTrigger,
      patientId,
    } = createTriggerProps;

    const response = await api.post(url, {
      measurementType,
      measurementType2,
      unit,
      unit2,
      comparator,
      comparator2,
      threshold,
      threshold2,
      baseline,
      baseline2,
      timeFrameDays,
      percent,
      percent2,
      hcpNotification,
      hcpMessage,
      secondTrigger,
      patientId,
    });

    return response;
  },

  async deleteTrigger(measurementAlertId: string): Promise<AxiosResponse> {
    const url = `/Portal/Alerts/DeleteTrigger/${measurementAlertId}`;

    const response = await api.patch(url);

    return response;
  },
};
