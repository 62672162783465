import { SurveyAnswerContainer } from "./AnswerSurveyPage.styles";
import { AnswerSurvey } from "./Components/Survey";

export function AnswerSurveyPage() {
  return (
    <SurveyAnswerContainer>
      <AnswerSurvey />
    </SurveyAnswerContainer>
  );
}
