import { styled } from "@patientmpower/spiro";

export const FlagContainer = styled("div", {
  backgroundColor: "#ECECEC",
  borderRadius: "8px",
  width: "40px",
  height: "26px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  svg: {
    width: "11px !important",

    path: {
      fill: "$black !important",
    },
  },

  "&:hover": {
    opacity: 0.8,
  },
});
