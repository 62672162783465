import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  PatientMessagesTableContent,
  PatientRegisteredDevices,
} from "../../@types/Patient";
import { messageService } from "../../services/messageService";

dayjs.extend(customParseFormat);

export function textTruncate(response: string) {
  const length = 7;
  const ending = "...";

  if (response && response.length > length) {
    return response.substring(0, length - ending.length) + ending;
  }
  return response;
}

export function getMessageResponse(
  responseType: string,
  responseOptions: { value: string; selected: boolean }[]
) {
  // Check the responseType to return the required string response
  let response = "-";

  if (responseType === "FreeText") {
    response = "Text response";
  } else if (responseType === "NoAnswer") {
    response = "No answer required";
  } else if (responseOptions.length > 0) {
    const selectedResponse = responseOptions.find(
      (response) => response.selected === true
    );
    if (selectedResponse) {
      // Display the selected truncated response
      response = textTruncate(selectedResponse.value);
    }
  }

  return response;
}

export function getPatientMessagesData(patientId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-messages-table-${patientId}`, patientId],
    async () => {
      if (!patientId) return [] as PatientMessagesTableContent[];

      const { data } = await messageService.getMessages(patientId);

      return data.messages as PatientMessagesTableContent[];
    }
  );

  const patientMessagesTableData = data as PatientMessagesTableContent[];

  return {
    patientMessagesTable: patientMessagesTableData?.map((messageData) => {
      return {
        ...messageData,
        sentBy: messageData.sentBy,
        createdBy: messageData.createdByEmail,
        response: getMessageResponse(
          messageData.responseType,
          messageData.responseOptions
        ),
        messageBody: messageData.body,
        responseDate: messageData.responseDate,
        messageResponse: messageData.freeResponse,
      } as PatientMessagesTableContent;
    }),
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function getPatientRegisteredDevices(patientId: number) {
  const { data, isFetching, isError, refetch, isRefetching } = useQuery(
    [`patient-registered-devices-${patientId}`, patientId],
    async () => {
      if (!patientId) return [] as PatientRegisteredDevices[];

      const { data } = await messageService.getRegisteredDevices(patientId);

      return data.devices as PatientRegisteredDevices[];
    }
  );

  const PatientRegisteredDevices = data as PatientRegisteredDevices[];

  return {
    PatientRegisteredDevices,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
