import {
  Checkbox,
  IOption,
  TopPanelMenuComponent,
  TopPanelMenuMobileButtonComponent,
} from "@patientmpower/spiro";
import { Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { IHospitalConfiguration, IUserDetails } from "../../../@types/Login";
import { IPatientListVariants } from "../../../@types/Patient";
import { IPortalPreferences } from "../../../@types/Preferences";
import { IRoutePattern } from "../../../@types/Route";
import { ArrowDown } from "../../../assets/icons/ArrowDown";
import { SearchBar } from "../../../components/SearchBar";
import { SupportButton } from "../../../components/SupportButton";
import { UserSettingsDesktop } from "../../../components/UserSettings/UserSettings";
import {
  AUTH_HOSPITAL_CONFIGURATION,
  AUTH_PORTAL_PREFERENCES,
  AUTH_STORED_ROUTE,
  AUTH_USER_DETAILS,
  IS_MOBILE_APP,
  PATIENT_LIST_VARIANT,
} from "../../../constants/localStorageKeys";
import { PMP_EMAIL_DOMAIN } from "../../../constants/pMpConstants";
import { usePatientList } from "../../../hooks/queries/patients";
import { useAuth } from "../../../hooks/useAuth";
import { useChildRoutes } from "../../../hooks/useChildRoutes";
import useIsMobile from "../../../hooks/useIsMobile";
import { useLeftPanel } from "../../../hooks/useLeftPanel";
import { spinCss } from "../../../pages/Surveys/Answer Survey/Components/Survey/AnswerSurvey.styles";
import { mainRoutes } from "../../../routes/main.routes";
import { userService } from "../../../services/userService";
import { wardsService } from "../../../services/wardsService";
import { mixpanelActions } from "../../../utils/mixpanel";
import { LeftPanelMenu } from "../LeftPanelMenu";
import { LeftPanelUserSettings } from "../LeftPanelUserSettings";
import { Link } from "../Link";
import {
  ContentContainer,
  DisplayName,
  NavContentContainer,
  OptionsContainer,
  TopPanelItemsContainerDesktop,
  WardOption,
  WardSelector,
} from "./DashboardLayout.styles";

type DashboardProps = {
  leftPanelHidden?: boolean;
};

type SelectedOption = {
  id: string;
  value: boolean;
};

export function DashboardLayout({ leftPanelHidden = false }: DashboardProps) {
  const [wardData] = useState<IOption[]>([]);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const [showMulitpleWards, setShowMulitpleWards] = useState(false);
  const [update, setUpdate] = useState(true);

  const optionRef = useRef<HTMLDivElement>(null);

  const { childRoutes } = useChildRoutes();

  const url = window.location.href.split("/");
  let patientListVariant: IPatientListVariants = {
    variant: url[4] as "archived" | "active" | "flagged" | "alerts",
  };

  const { isAuthenticated } = useAuth();
  const {
    isMobileLeftPanelMenuOpen,
    setIsMobileLeftPanelMenuOpen,
    isMobileUserSettingsMenuOpen,
    setIsMobileUserSettingsMenuOpen,
  } = useLeftPanel();
  const { isMobile, isSmallScreen } = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();

  if (!isAuthenticated) {
    localStorage.setItem(
      AUTH_STORED_ROUTE,
      `${location.pathname}${location.search}`
    );
    return <Navigate to="/login" />;
  }

  const { refetch } = usePatientList(patientListVariant, false);

  useEffect(() => {
    const url = window.location.href.split("/");

    if (url[3] === "patients") {
      if (
        url[4] === "archived" ||
        url[4] === "active" ||
        url[4] === "flagged" ||
        url[4] === "alerts"
      ) {
        patientListVariant = { variant: url[4] };
        refetch();
      }
    }
  }, [window.location.href]);

  const handleOnChangeMobileLeftPanelVisibility = () => {
    if (isMobileUserSettingsMenuOpen) {
      setIsMobileUserSettingsMenuOpen(false);
    } else {
      setIsMobileLeftPanelMenuOpen(
        (prevMobileLeftPanelMenuState) => !prevMobileLeftPanelMenuState
      );
    }
  };

  const handlePatientListNavigation = () => {
    const localStorageVariant = localStorage.getItem(PATIENT_LIST_VARIANT);

    navigate(`/patients/${localStorageVariant}`);
  };

  const renderTopBarMenuRoutes = () => {
    const handlePathNavigation = (path: string) => {
      if (path.includes("patients")) {
        const localStorageVariant = localStorage.getItem(PATIENT_LIST_VARIANT);

        return `/patients/${localStorageVariant}`;
      }

      return path;
    };

    const tempRoutes = mainRoutes;

    const userDetails = localStorage.getItem(AUTH_USER_DETAILS) ?? "{}";
    const parsedUserDetails = JSON.parse(userDetails) as IUserDetails;

    if (
      parsedUserDetails.userEmail &&
      parsedUserDetails.userEmail.includes(PMP_EMAIL_DOMAIN)
    ) {
      const surveysIndex = tempRoutes.findIndex((x) => x.name === "Surveys");
      if (surveysIndex !== -1) {
        tempRoutes[surveysIndex].disabled = false;
      }

      const protocolsIndex = tempRoutes.findIndex((x) => x.name === "Protocol");
      if (protocolsIndex !== -1) {
        tempRoutes[protocolsIndex].disabled = false;
      }

      const careInfoIndex = tempRoutes.findIndex((x) => x.name === "Care Info");
      if (careInfoIndex !== -1) {
        tempRoutes[careInfoIndex].disabled = false;
      }

      const workflowsIndex = tempRoutes.findIndex(
        (x) => x.name === "Workflows"
      );
      if (careInfoIndex !== -1) {
        tempRoutes[workflowsIndex].disabled = false;
      }
    }

    const hospitalConfiguration =
      localStorage.getItem(AUTH_HOSPITAL_CONFIGURATION) ?? "{}";

    const parsedHospitalConfiguration = JSON.parse(
      hospitalConfiguration
    ) as IHospitalConfiguration;

    if (parsedHospitalConfiguration.enableAppointments) {
      const appointmentsIndex = tempRoutes.findIndex(
        (x) => x.name === "Appointments"
      );

      if (appointmentsIndex !== -1) {
        tempRoutes[appointmentsIndex].disabled = false;
      }
    }

    return tempRoutes
      .filter(({ disabled }) => !disabled)
      .map(({ path, name, newTab }) => (
        <Link
          key={`${Math.random()}-${path}`}
          to={handlePathNavigation(path)}
          newTab={newTab}
        >
          {name}
        </Link>
      ));
  };

  const renderLeftPanelMenuByDevice = (): IRoutePattern[] => {
    return isMobile || isSmallScreen ? mainRoutes : childRoutes;
  };

  const handleUserTracking = () => {
    const selectedIds: string[] = [];

    selectedOptions.forEach((option) => {
      if (option.id === "all" && option.value) {
        wardData.forEach((ward) => {
          selectedIds.push(ward.value);
        });
      }

      if (option.id !== "all" && option.value) {
        selectedIds.push(option.id);
      }
    });

    const selectedHospitalsNames = wardData
      .filter((x) => selectedIds.includes(x.value))
      .map((x) => x.label)
      .join(", ");

    mixpanelActions.track(
      `User Action: ChangeWard (${selectedHospitalsNames})`
    );
  };

  const handleWardOptions = (): void => {
    const optionsToSet: any[] = [];

    if (!selectedOptions.length) {
      return;
    }

    optionsToSet.push(
      <WardOption
        selected={selectedOptions.find((x) => x.id === "all")?.value}
        key="all-wards-key"
      >
        <Checkbox
          checked={selectedOptions.find((x) => x.id === "all")?.value ?? false}
          label=""
          onChange={(isChecked) => {
            const tempOptions = [...selectedOptions];

            tempOptions[0].value = isChecked;

            setSelectedOptions(tempOptions);

            handleUserTracking();
          }}
          disabled={selectedOptions[0].value}
        />
        <p>All Wards</p>
      </WardOption>
    );

    wardData.forEach((option) => {
      optionsToSet.push(
        <WardOption
          selected={selectedOptions.find((x) => x.id === option.value)?.value}
          key={option.key}
        >
          <Checkbox
            checked={
              selectedOptions.find((x) => x.id === option.value)?.value ?? false
            }
            label=""
            onChange={(isChecked) => {
              const tempOptions = [...selectedOptions];
              const index = tempOptions.findIndex((x) => x.id === option.value);

              tempOptions[0].value = false;
              tempOptions[index].value = isChecked;

              let shouldSelectAllWards = true;

              tempOptions.forEach((option) => {
                if (option.value) {
                  shouldSelectAllWards = false;
                }
              });

              if (shouldSelectAllWards) {
                tempOptions[0].value = true;
              }

              setSelectedOptions(tempOptions);

              handleUserTracking();

              setTimeout(() => {
                setUpdate(false);
                setUpdate(true);
              }, 1);
            }}
          />
          <p>{option.label}</p>
        </WardOption>
      );
    });

    setOptions(optionsToSet);
  };

  useEffect(() => {
    if (selectedOptions.length === 0) {
      return;
    }

    const selectedIds: number[] = [];

    selectedOptions.forEach((option) => {
      if (option.id === "all" && option.value) {
        wardData.forEach((ward) => {
          selectedIds.push(parseInt(ward.value, 10));
        });
      }

      if (option.id !== "all" && option.value) {
        selectedIds.push(parseInt(option.id, 10));
      }
    });

    const orderIds = Array.from(new Set(selectedIds.sort((a, b) => a - b)));

    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = JSON.parse(
        portalPreferences
      ) as IPortalPreferences;

      if (parsedPortalPreferences.wards !== undefined) {
        const preferencesWards = parsedPortalPreferences.wards;

        const orderPreferencesWards = Array.from(
          new Set(preferencesWards.sort((a, b) => a - b))
        );

        if (
          JSON.stringify(orderPreferencesWards) === JSON.stringify(orderIds) ||
          orderIds.length === 0 ||
          orderPreferencesWards.length === 0
        ) {
          return;
        }

        parsedPortalPreferences.wards = orderIds;

        localStorage.setItem(
          AUTH_PORTAL_PREFERENCES,
          JSON.stringify(parsedPortalPreferences)
        );
      }
    }

    userService.updateWardsConfig(orderIds).then(() => {
      refetch();

      setTimeout(() => {
        const url = window.location.href.split("/");
        if (
          url[4] !== "archived" &&
          url[4] !== "active" &&
          url[4] !== "flagged" &&
          url[4] !== "alerts" &&
          url[3] !== "calendar"
        ) {
          const isMobileApp = localStorage.getItem(IS_MOBILE_APP);
          if (!isMobileApp) {
            navigate("/");
          }
        }
      }, 1);
    });
  }, [selectedOptions]);

  useEffect(() => {
    wardsService.getAvailableWards().then((response) => {
      const { data } = response;

      data.forEach((ward) => {
        const doesHospitalIdExists = wardData.some(
          (item) => item.value === ward.hospitalId.toString()
        );

        if (!doesHospitalIdExists) {
          wardData.push({
            label: ward.hospitalName,
            value: ward.hospitalId.toString(),
            key: uuidv4(),
          });
        }
      });

      if (wardData.length > 1) {
        setShowMulitpleWards(true);
      } else {
        return;
      }

      const options: SelectedOption[] = [];

      const parsedPortalPreferences = JSON.parse(
        localStorage.getItem(AUTH_PORTAL_PREFERENCES) ?? "{}"
      ) as IPortalPreferences;

      if (
        parsedPortalPreferences.wards === undefined ||
        parsedPortalPreferences.wards.length === data.length ||
        parsedPortalPreferences.wards.length === 0
      ) {
        options.push({
          id: "all",
          value: true,
        });

        wardData.forEach((option) => {
          options.push({
            id: option.value,
            value: false,
          });
        });
      } else {
        options.push({
          id: "all",
          value: false,
        });

        wardData.forEach((option) => {
          options.push({
            id: option.value,
            value: parsedPortalPreferences.wards.includes(
              parseInt(option.value, 10)
            ),
          });
        });
      }

      setSelectedOptions(options);

      setTimeout(() => {
        setUpdate(false);
        setUpdate(true);
      }, 1);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        showOptions &&
        optionRef.current &&
        !optionRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showOptions]);

  useEffect(() => {
    if (!selectedOptions.length) {
      return;
    }

    const tempOptions = [...selectedOptions];

    if (selectedOptions[0].value === true) {
      selectedOptions
        .filter((v) => v.id !== "all")
        .forEach((option, index) => {
          tempOptions[index + 1].value = false;
        });
    }

    setSelectedOptions(tempOptions);

    setTimeout(() => {
      setUpdate(false);
      setUpdate(true);
    }, 1);
  }, [selectedOptions[0]?.value]);

  useEffect(() => {
    handleWardOptions();
  }, [update]);

  const getNameToDisplay = () => {
    const selectedLabels: string[] = [];
    const tempOptions = [...selectedOptions];

    tempOptions.forEach((option) => {
      if (option.value) {
        if (option.id !== "all") {
          const detailedOption = wardData.find((x) => x.value === option.id);

          if (detailedOption) {
            selectedLabels.push(detailedOption.label);
          }
        } else {
          selectedLabels.push("All Wards");
        }
      }
    });

    if (selectedLabels.length > 1) {
      selectedLabels.sort((a, b) => {
        return a.length - b.length;
      });
      return (
        <DisplayName>{`${selectedLabels[0]} (+${
          selectedLabels.length - 1
        })`}</DisplayName>
      );
    }

    return <DisplayName>{selectedLabels[0]}</DisplayName>;
  };

  const isMobileApp = localStorage.getItem(IS_MOBILE_APP);
  if (isMobileApp) {
    return <Spin className={spinCss()} fullscreen size="large" />;
  }

  if (!leftPanelHidden) {
    return (
      <>
        <TopPanelMenuComponent onLogoClick={handlePatientListNavigation}>
          <NavContentContainer>
            <TopPanelItemsContainerDesktop>
              {renderTopBarMenuRoutes()}
            </TopPanelItemsContainerDesktop>
            <SearchBar />

            <SupportButton
              style={showMulitpleWards ? {} : { marginRight: "10px" }}
            />

            {showMulitpleWards ? (
              <WardSelector
                style={{
                  borderColor: showOptions ? "#E6F3F3" : "transparent",
                }}
                active={!selectedOptions[0]?.value}
                onClick={() => setShowOptions(true)}
              >
                {getNameToDisplay()} <ArrowDown />
                <OptionsContainer ref={optionRef} open={showOptions}>
                  {update ? options : null}
                </OptionsContainer>
              </WardSelector>
            ) : null}

            <TopPanelMenuMobileButtonComponent
              isMobileLeftPanelMenuOpen={isMobileLeftPanelMenuOpen}
              onClick={handleOnChangeMobileLeftPanelVisibility}
              smallScreenWidth={1100}
            />
          </NavContentContainer>

          <UserSettingsDesktop />
        </TopPanelMenuComponent>

        <LeftPanelMenu
          items={renderLeftPanelMenuByDevice()}
          onLeftPanelMenuItemClick={handleOnChangeMobileLeftPanelVisibility}
        />

        <LeftPanelUserSettings />

        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </>
    );
  }

  return (
    <>
      <TopPanelMenuComponent onLogoClick={() => navigate("/")}>
        <NavContentContainer>
          <TopPanelItemsContainerDesktop>
            {renderTopBarMenuRoutes()}
          </TopPanelItemsContainerDesktop>

          <SearchBar />

          <SupportButton style={{ marginRight: "10px", marginLeft: "15px" }} />

          <TopPanelMenuMobileButtonComponent
            isMobileLeftPanelMenuOpen={isMobileLeftPanelMenuOpen}
            onClick={handleOnChangeMobileLeftPanelVisibility}
          />
        </NavContentContainer>

        <UserSettingsDesktop />
      </TopPanelMenuComponent>

      <LeftPanelUserSettings />

      <ContentContainer leftPanelHidden>
        <Outlet />
      </ContentContainer>
    </>
  );
}
