import React from "react";

import { Button } from "./CustomIconButton.styles";

type CustomIconButtonProps = React.ComponentProps<typeof Button> & {
  active?: boolean;
};

export function CustomIconButton({
  active,
  children,
  ...props
}: CustomIconButtonProps) {
  return (
    <Button {...props} active={active}>
      {children}
    </Button>
  );
}

CustomIconButton.defaultProps = {
  active: false,
};
