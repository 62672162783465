import { ColumnDef } from "@tanstack/react-table";

export const defaultTableColumns: ColumnDef<any, string>[] = [
  {
    accessorKey: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 220,
    sortingFn: "text",
  },
  {
    accessorKey: "hospitalId",
    header: () => "Hospital ID",
    cell: (info) => info.renderValue(),
    size: 150,
    sortingFn: "text",
  },
  {
    accessorKey: "id",
    header: () => "pMp ID",
    cell: (info) => info.renderValue(),
    sortingFn: "basic",
  },
  {
    accessorKey: "alerts",
    header: () => "Alerts",
    cell: (info) => info.renderValue(),
    sortingFn: (rowA, rowB) => {
      return rowA.original.alertsCount - rowB.original.alertsCount;
    },
  },
  {
    accessorKey: "signUpDate",
    header: () => "Sign up date",
    cell: (info) => info.renderValue(),
    size: 160,
    sortingFn: (rowA, rowB) => {
      return (
        rowB.original.signUpdateParsedDate - rowA.original.signUpdateParsedDate
      );
    },
  },
  {
    accessorKey: "lastUsed",
    header: () => "Last used",
    cell: (info) => info.renderValue(),
    size: 170,
    sortingFn: (rowA, rowB) => {
      return rowB.original.lastUsedDate - rowA.original.lastUsedDate;
    },
  },
  {
    accessorKey: "lastReviewedBy",
    header: () => "Last reviewed by",
    cell: (info) => info.renderValue(),
    size: 160,
    sortingFn: "text",
  },
  {
    accessorKey: "flag",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 30,
    enableSorting: false,
  },
  {
    accessorKey: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 30,
    enableSorting: false,
  },
  {
    accessorKey: "alertsCount",
    size: 0,
  },
  {
    accessorKey: "surveyResultStatus",
    size: 0,
  },
  {
    accessorKey: "lastUsedDate",
    size: 0,
  },
  {
    accessorKey: "surveyReceivedDate",
    size: 0,
  },
  {
    accessorKey: "signUpdateParsedDate",
    size: 0,
  },
];

export const mobileAccordionListColumns = [
  { key: "Hospital ID", value: "hospitalId" },
  { key: "pMp ID", value: "id" },
  { key: "Sign up date", value: "signUpDate" },
  { key: "Hospital ID", value: "hospitalId" },
  { key: "Last reviewed by", value: "lastReviewedBy" },
];
