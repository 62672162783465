import { useEffect, useState } from "react";

const DEFAULT_SCALE = 1;
const ACTIVE_SCALE = 1.5;

export function TriangleDot(props: any) {
  const { cx, cy, legend } = props;

  const [active, setActive] = useState(false);
  const [scale, setScale] = useState(DEFAULT_SCALE);

  useEffect(() => {
    if (!legend) setScale(active ? ACTIVE_SCALE : DEFAULT_SCALE);
  }, [active]);

  return (
    <svg
      x={cx && cx - 8 * scale}
      y={cy && cy - 8 * scale}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      cursor="pointer"
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <path
        d="M8.00002 5L4.40002 11H11.6L8.00002 5Z"
        fill="#297A94"
        transform={`scale(${scale})`}
      />
    </svg>
  );
}
