import { PageTitle } from "../../layout/components/PageTitle";
import { BillableSection } from "./components/BillableSection";
import { DashboardContainer } from "./Dashboard.styles";

export function Dashboard() {
  document.title = "Dashboard - patientMpower";

  return (
    <DashboardContainer>
      <PageTitle>Billable (#99454)</PageTitle>
      <BillableSection />
    </DashboardContainer>
  );
}
