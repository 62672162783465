export function Minus({ ...rest }) {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66659 1.66659H0.333252V0.333252H9.66659V1.66659Z"
        fill="#212121"
      />
    </svg>
  );
}
