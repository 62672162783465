/* eslint-disable no-debugger */
import { AxiosResponse } from "axios";

import { IPromsResponse, ISurveysFormsReponse } from "../@types/Proms";
import { api } from "./api";

export const promsService = {
  async getPatientProms(
    patientId: number
  ): Promise<AxiosResponse<IPromsResponse>> {
    const url = "/Portal/Surveys";

    const urlParamsParsed = new URLSearchParams();

    urlParamsParsed.append("patientId", patientId.toString());

    const promsResponse = await api.get<IPromsResponse>(url, {
      params: urlParamsParsed,
    });

    return promsResponse;
  },

  async getPatientSurveyForms(
    patientId: number,
    surveyId: number
  ): Promise<AxiosResponse<ISurveysFormsReponse>> {
    const url = `/Portal/Surveys/${surveyId.toString()}/Forms`;

    const urlParamsParsed = new URLSearchParams();

    urlParamsParsed.append("patientId", patientId.toString());

    const surveyFormsResponse = await api.get<ISurveysFormsReponse>(url, {
      params: urlParamsParsed,
    });

    return surveyFormsResponse;
  },
};
