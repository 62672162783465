export function FlagFilled({ ...rest }) {
  return (
    <svg
      {...rest}
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.92857 0H2.07143C1.20714 0 0.5 0.7 0.5 1.55556V14L6 11.6667L11.5 14V1.55556C11.5 0.7 10.7929 0 9.92857 0Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
