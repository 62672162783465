import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  width: "100%",
  height: "100%",

  padding: "16px 46px 16px 46px",
});

export const Row = styled("div", {
  height: "28px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  marginBottom: "16px",

  p: {
    width: "260px",

    color: "$gray-80",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "28px",
  },

  ".ant-checkbox-wrapper": {
    lineHeight: "0px !important",
  },

  ".ant-checkbox .ant-checkbox-inner": {
    width: "18px !important",
    height: "18px !important",
    borderWidth: "2px !important",

    "&::after": {
      borderWidth: "2px !important",
    },
  },

  variants: {
    disabled: {
      true: {
        label: {
          opacity: "0.5",
        },
      },
    },
  },
});

export const ColumnTitle = styled("p", {
  width: "260px",

  color: "$charcoal !important",
  fontSize: "14px",
  lineHeight: "22px !important",
});
