import { Checkbox } from "@patientmpower/spiro";
import { useState } from "react";

import { Notification } from "../../../../@types/Notification";
import { IStaffPreferences } from "../../../../@types/Preferences";
import { ToastNotification } from "../../../../components/ToastNotification";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { staffService } from "../../../../services/staffService";
import {
  MessagesPreferencesContainer,
  ParentContainer,
  TextContainer,
} from "./MessagesPreferencesSection.styles";

export function MessagesPreferencesSection() {
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
  });

  const { staffPreferences, changeStaffPreferences } = useUserPreferences();

  const handleCheckboxChange = async (isChecked: boolean) => {
    const newStafPreferences: IStaffPreferences = {
      ...staffPreferences,
      notifyByEmailOnMessage: isChecked,
    };
    changeStaffPreferences(newStafPreferences);

    const result = await staffService.updateNotifyByEmailOnMessageFlag(
      isChecked
    );

    if (result.status >= 200 && result.status < 300) {
      setNotification({
        show: true,
        message: "Messages preferences successfully updated",
        type: "success",
      });
    } else {
      const oldStafPreferences: IStaffPreferences = {
        ...staffPreferences,
        notifyByEmailOnMessage: !isChecked,
      };
      changeStaffPreferences(oldStafPreferences);

      setNotification({
        show: true,
        message: "Something went wrong while updating, please try again",
        type: "error",
      });
    }

    setTimeout(() => {
      setNotification({
        show: false,
        message: "notificationMessage",
        type: "error",
      });
    }, 3500);
  };

  return (
    <>
      <ParentContainer>
        <PageTitle>Messages Preferences</PageTitle>

        <MessagesPreferencesContainer>
          <TextContainer>
            Receive email notification for new messages
          </TextContainer>
          <Checkbox
            spacing="40px"
            label=""
            onChange={(isChecked) => {
              handleCheckboxChange(isChecked);
            }}
            checked={staffPreferences.notifyByEmailOnMessage}
          />
        </MessagesPreferencesContainer>
      </ParentContainer>
      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
        />
      ) : null}
    </>
  );
}
