export function CircleLetter(props: any) {
  const { letter } = props;

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#82C5B7" />
      <text
        x="50%"
        y="50%"
        fontFamily="Nunito Sans"
        textAnchor="middle"
        stroke="#fff"
        fill="#fff"
        dy=".3em"
        fontSize="15px"
        fontStyle="normal"
      >
        {letter}
      </text>
    </svg>
  );
}
