import { useQuery } from "@tanstack/react-query";

import { surveysService } from "../../services/surveysService";

export function useSurveys() {
  const { data, isError, isLoading, isFetching, refetch, isRefetching } =
    useQuery(
      ["surveys-list"],
      async () => {
        const { data } = await surveysService.getAllSurveys();

        return data;
      },
      {
        refetchOnMount: true,
      }
    );

  return {
    surveys: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
    isLoading,
  };
}
