import { styled } from "@patientmpower/spiro";

export const Button = styled("div", {
  all: "unset",

  height: "30px",
  width: "30px",

  borderRadius: "$round",

  display: "flex",

  alignItems: "center",
  justifyContent: "center",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$gray-50",
  },

  "@sm": {
    backgroundColor: "white !important",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "rgba(33, 33, 33, 0.06)",
      },
    },
  },
});
