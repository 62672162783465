import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { ISectionProps } from "../../../../@types/Patient";
import { IPromsTableData } from "../../../../@types/Proms";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { getPatientPromsData } from "../../../../hooks/queries/proms";
import useIsMobile from "../../../../hooks/useIsMobile";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { PatientPromsTable } from "./PatientPromsTable";
import { NewDataCOntainer, TitleContainer } from "./PromsSection.styles";

export function PromsSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const { isMobile } = useIsMobile();

  const { proms, isError, isFetching, isRefetching, refetch } =
    getPatientPromsData(patient.id);

  const handleRefreshTable = () => {
    refetch();
  };

  const [showNewData, setShowNewData] = useState<boolean>(false);

  useEffect(() => {
    if (
      !isFetching &&
      !isRefetching &&
      collapsedSection.value === true &&
      proms.length > 0
    ) {
      const collapsedDate = dayjs(collapsedSection.collapsedDate);

      proms.forEach((prom: IPromsTableData) => {
        const lastCompleted = prom.lastCompleted
          ? dayjs(prom.lastCompleted)
          : dayjs("2000-01-01");

        if (lastCompleted > collapsedDate) {
          setShowNewData(true);
        }
      });
    }
  }, [proms]);

  const handleCollapseSection = () => {
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
    updateColapsedSections("promsSection");
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>PROM</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}
      <div
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <PatientPromsTable
          patientId={patient.id}
          isLoading={isFetching && !isRefetching}
          isError={isError}
          content={proms}
          refreshTable={handleRefreshTable}
        />
      </div>
    </>
  );
}
