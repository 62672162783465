export function Panel({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 2.75H10.0833V11.9167H2.75V2.75ZM19.25 2.75H11.9167V8.25H19.25V2.75ZM8.25 10.0833V4.58333H4.58333V10.0833H8.25ZM17.4167 6.41667V4.58333H13.75V6.41667H17.4167ZM17.4167 11.9167V17.4167H13.75V11.9167H17.4167ZM8.25 17.4167V15.5833H4.58333V17.4167H8.25ZM19.25 10.0833H11.9167V19.25H19.25V10.0833ZM2.75 13.75H10.0833V19.25H2.75V13.75Z"
      />
    </svg>
  );
}
