import { Tooltip } from "antd";

import { CircleDot } from "../../assets/icons/CircleDot";
import { LineDot } from "../../assets/icons/LineDot";
import { SquareDot } from "../../assets/icons/SquareDot";
import { TriangleDot } from "../../assets/icons/TriangleDot";
import { getSourceLegends } from "../../utils/chartSourceUtils";
import { Container, StyledLegend } from "./CustomChartLegend.styles";

type MeasurementTypeName = {
  [key: string]: string;
};

const measurementTypeNames: MeasurementTypeName = {
  bp_diastolic: "Diastolic",
  bp_systolic: "Systolic",
};

export function CustomChartLegend(props: any) {
  const { payload, focusMode, baseline } = props;

  const legend: any[] = [];

  if (baseline) {
    legend.push(
      <StyledLegend key="baseline-legend">
        <LineDot fill="#58C488" />
        Baseline
      </StyledLegend>
    );
  }

  payload.map((entry: any, index: any) => {
    const { dataKey, payload } = entry;
    const { name } = payload;
    const source = (name || "unknown").toLowerCase();

    if (source.includes("archived")) {
      return null;
    }

    const sourceLegend = getSourceLegends(dataKey, source);
    const { shape, color, lineOpacity } = sourceLegend;

    const typeName = measurementTypeNames[dataKey];

    legend.push(
      <StyledLegend key={`${source}_${index}`}>
        {shape === "circle" && (
          <CircleDot
            fill={color}
            lineOpacity={lineOpacity}
            legend
            focusMode={focusMode}
          />
        )}
        {shape === "square" && <SquareDot fill={color} legend />}
        {shape === "triangle" && <TriangleDot fill={color} legend />}
        {shape === "line" && <LineDot fill={color} />}
        {typeName && `${typeName} - `}
        {sourceLegend.name}
      </StyledLegend>
    );

    return null;
  });
  if (legend.length <= 4) {
    return <Container>{legend}</Container>;
  }

  return (
    <Tooltip
      title={legend.slice(4, legend.length)}
      zIndex={3002}
      color="white"
      placement="right"
      overlayInnerStyle={{
        height: "100%",
        width: "fit-content",
        background: "white !important",
        borderRadius: "4px !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Container>{legend.slice(0, 4)}</Container>
    </Tooltip>
  );
}
