import { styled } from "@patientmpower/spiro";

// components

export const OptionsSelectionContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  width: "100%",
  marginBottom: "15px",
});

export const RadioButtonContainer = styled("div", {
  width: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "10px",
});

export const RadioLabel = styled("div", {
  fontSize: "16px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});
