import { ChartCardValue, ChartCardValuesProps } from "../ChartCard.types";
import {
  ChartValueContainer,
  Label,
  Value,
} from "./ChartSpecificValues.styles";

export function ChartSpecificValues({ values }: ChartCardValuesProps) {
  return (
    <ChartValueContainer>
      {values.map((currentValue: ChartCardValue, index: number) => (
        <div key={`${currentValue.label}_${currentValue.value}_${index}`}>
          <Value>{currentValue.value}</Value>
          <Label>{currentValue.label}</Label>
        </div>
      ))}
    </ChartValueContainer>
  );
}
