import React, { createContext, ReactNode, useMemo, useState } from "react";

import {
  IQuestionWithConditionalRecord,
  ISurveyAnswer,
} from "../@types/Surveys";

type ContextData = {
  surveyAnswerArray: ISurveyAnswer[];
  setValueSurveyAnswerArray: (list: ISurveyAnswer[]) => void;
  updateSurveyAnswerArray: (answerUpdate: ISurveyAnswer) => void;
  questionWithConditionalList: IQuestionWithConditionalRecord[];
  setQuestionWithConditionalList: React.Dispatch<
    React.SetStateAction<IQuestionWithConditionalRecord[]>
  >;
  updateSurveyAnswerArrayOnOptionClick(answerUpdate: ISurveyAnswer): void;
};

type DynamicSurveysProviderProps = {
  children: ReactNode;
};

export const DynamicSurveysContext = createContext({} as ContextData);

export function DynamicSurveysProvider({
  children,
}: DynamicSurveysProviderProps) {
  const [surveyAnswerArray, setSurveyAnswerArray] = useState<ISurveyAnswer[]>(
    []
  );
  const [questionWithConditionalList, setQuestionWithConditionalList] =
    useState<IQuestionWithConditionalRecord[]>([]);

  function updateSurveyAnswerArray(answerUpdate: ISurveyAnswer) {
    const answerListWithNewAnswer = [...surveyAnswerArray];
    const indexToEdit = answerListWithNewAnswer.findIndex(
      (answer) => answer.surveyQuestionId === answerUpdate.surveyQuestionId
    );
    answerListWithNewAnswer[indexToEdit] = answerUpdate;
    setSurveyAnswerArray(answerListWithNewAnswer);
  }

  function updateSurveyAnswerArrayOnOptionClick(answerUpdate: ISurveyAnswer) {
    const answerListWithNewAnswer = [...surveyAnswerArray];
    let answerListWithConditionalCheck: ISurveyAnswer[] = [];
    const indexToEdit = answerListWithNewAnswer.findIndex(
      (answer) => answer.surveyQuestionId === answerUpdate.surveyQuestionId
    );
    answerListWithNewAnswer[indexToEdit] = answerUpdate;
    const isAnswerUpdateToQuestionWithConditional =
      questionWithConditionalList.find(
        (question) => question.questionId === answerUpdate.surveyQuestionId
      );
    answerListWithConditionalCheck = [...answerListWithNewAnswer];

    if (isAnswerUpdateToQuestionWithConditional) {
      let resetAnswerValues = false;
      answerListWithNewAnswer.forEach((answer, answerIndex) => {
        if (!resetAnswerValues) {
          if (answerUpdate.surveyQuestionId === answer.surveyQuestionId) {
            resetAnswerValues = true;
          }
        } else {
          answerListWithConditionalCheck[answerIndex].answerValue = "";
        }
      });
    }
    setSurveyAnswerArray(answerListWithConditionalCheck);
  }

  function setValueSurveyAnswerArray(list: ISurveyAnswer[]) {
    setSurveyAnswerArray(list);
  }

  const values = useMemo(() => {
    return {
      surveyAnswerArray,
      setValueSurveyAnswerArray,
      updateSurveyAnswerArray,
      questionWithConditionalList,
      setQuestionWithConditionalList,
      updateSurveyAnswerArrayOnOptionClick,
    };
  }, [surveyAnswerArray]);

  return (
    <DynamicSurveysContext.Provider value={values}>
      {children}
    </DynamicSurveysContext.Provider>
  );
}
