import {
  Button,
  Dropdown,
  DropdownTextItem,
  TextField,
} from "@patientmpower/spiro";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { IPatientInfo } from "../../../../@types/Patient";
import { IPortalPreferences } from "../../../../@types/Preferences";
import { AUTH_PORTAL_PREFERENCES } from "../../../../constants/localStorageKeys";
import { patientService } from "../../../../services/patientService";
import {
  ButtonContainer,
  CancelButton,
  ContentContainer,
  dateInputCss,
  ModalContainer,
  ModalTitle,
  PatientName,
  Row,
  ErrorMessage,
} from "./DownloadPatientData.styles";

type DownloadPatientDataProps = {
  patient: IPatientInfo;
  onClose: (success?: boolean, format?: string) => void;
};

export function DownloadPatientData(props: DownloadPatientDataProps) {
  const { onClose, patient } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [format, setFormat] = useState("PDF");
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");
  const [countryCode, setCountryCode] = useState<string>();
  const [startDate, setStartDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [endDate, setEndDate] = useState({
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    const portalPreferences = localStorage.getItem(AUTH_PORTAL_PREFERENCES);

    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IPortalPreferences)
        : undefined;

      if (parsedPortalPreferences) {
        setCountryCode(parsedPortalPreferences.countryCode);
      }
    }
  }, []);

  const handleCancelClick = () => {
    onClose();
  };

  const handleDownloadClick = async () => {
    let error = false;

    if (
      startDate.day === "" ||
      startDate.month === "" ||
      startDate.year === ""
    ) {
      setErrorStartDate("Please enter a valid date.");
      error = true;
    }

    if (endDate.day === "" || endDate.month === "" || endDate.year === "") {
      setErrorEndDate("Please enter a valid date.");
      error = true;
    }

    if (error) {
      return;
    }

    const newStartDate = new Date(
      `${startDate.year}-${startDate.month}-${startDate.day}`
    );
    const newEndDate = new Date(
      `${endDate.year}-${endDate.month}-${endDate.day}`
    );

    if (dayjs(newStartDate).isValid()) {
      setErrorStartDate("");
    } else {
      setErrorStartDate("Please enter a valid date.");
      error = true;
    }

    if (dayjs(newEndDate).isValid()) {
      setErrorEndDate("");
    } else {
      setErrorEndDate("Please enter a valid date.");
      error = true;
    }

    if (error) {
      return;
    }

    const currentDt = dayjs();
    if (dayjs(newStartDate).isBefore(currentDt)) {
      setErrorStartDate("");
    } else {
      setErrorStartDate("Date is in the future.");
      error = true;
    }

    if (dayjs(newEndDate).isBefore(currentDt)) {
      setErrorEndDate("");
    } else {
      setErrorEndDate("Date is in the future.");
      error = true;
    }

    if (error) {
      return;
    }

    if (dayjs(newEndDate).isBefore(dayjs(newStartDate))) {
      setErrorEndDate("End is before start date.");
      return;
    }

    setSubmitLoading(true);
    const { data, status } = await patientService.getPatientPdf(
      patient.id,
      `${startDate.year}-${startDate.month}-${startDate.day}`,
      `${endDate.year}-${endDate.month}-${endDate.day}`
    );

    if (status === 200) {
      const blob = await data;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Patient-${patient.id}-Report-${startDate.year}${startDate.month}${startDate.day}-to-${endDate.year}${endDate.month}${endDate.day}.pdf`
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      setSubmitLoading(false);
      onClose(true, format);
    } else {
      setSubmitLoading(false);
      onClose(false, format);
    }
  };

  const pressTab = () => {
    const { activeElement } = document;

    const allFocusableElements = document.querySelectorAll(
      'input, [tabindex]:not([tabindex="-1"])'
    );

    const currentIndex = Array.from(allFocusableElements).indexOf(
      activeElement as Element
    );

    const nextIndex = (currentIndex + 1) % allFocusableElements.length;
    const nextElement = allFocusableElements[nextIndex];

    if (nextElement) {
      (nextElement as HTMLElement).focus();
    }
  };

  useEffect(() => {
    if (startDate.day.length === 2) {
      pressTab();
    }
  }, [startDate.day]);

  useEffect(() => {
    if (startDate.month.length === 2) {
      pressTab();
    }
  }, [startDate.month]);

  useEffect(() => {
    if (startDate.year.length === 4) {
      pressTab();
    }
  }, [startDate.year]);

  useEffect(() => {
    if (endDate.day.length === 2) {
      pressTab();
    }
  }, [endDate.day]);

  useEffect(() => {
    if (endDate.month.length === 2) {
      pressTab();
    }
  }, [endDate.month]);

  const checkYearError = (value: string) => {
    const intValue = Number(value);
    if (value.length > 3 && (intValue < 1970 || intValue > 2080)) {
      return true;
    }
    return false;
  };

  const checkMonthError = (value: string) => {
    const intValue = Number(value);
    if (value.length > 1 && (intValue < 1 || intValue > 12)) {
      return true;
    }
    return false;
  };

  const checkDayError = (value: string) => {
    const intValue = Number(value);
    if (value.length > 1 && (intValue < 1 || intValue > 31)) {
      return true;
    }
    return false;
  };

  const handleStartDateLast = (value: string) => {
    if (checkYearError(value)) {
      return;
    }

    setStartDate((prevState) => {
      const newState = { ...prevState };
      newState.year = value;
      return newState;
    });
  };

  const handleEndDateLast = (value: string) => {
    if (checkYearError(value)) {
      return;
    }

    setEndDate((prevState) => {
      const newState = { ...prevState };
      newState.year = value;
      return newState;
    });
  };

  const handleEndDateMiddle = (value: string) => {
    if (countryCode === "US") {
      if (checkDayError(value)) {
        return;
      }
      setEndDate((prevState) => {
        const newState = { ...prevState };
        newState.day = value;
        return newState;
      });
    } else {
      if (checkMonthError(value)) {
        return;
      }
      setEndDate((prevState) => {
        const newState = { ...prevState };
        newState.month = value;
        return newState;
      });
    }
  };

  const handleStartDateMiddle = (value: string) => {
    if (countryCode === "US") {
      if (checkDayError(value)) {
        return;
      }
      setStartDate((prevState) => {
        const newState = { ...prevState };
        newState.day = value;
        return newState;
      });
    } else {
      if (checkMonthError(value)) {
        return;
      }
      setStartDate((prevState) => {
        const newState = { ...prevState };
        newState.month = value;
        return newState;
      });
    }
  };

  const handleStartDateFirst = (value: string) => {
    if (countryCode === "US") {
      if (checkMonthError(value)) {
        return;
      }
      setStartDate((prevState) => {
        const newState = { ...prevState };
        newState.month = value;
        return newState;
      });
    } else {
      if (checkDayError(value)) {
        return;
      }
      setStartDate((prevState) => {
        const newState = { ...prevState };
        newState.day = value;
        return newState;
      });
    }
  };

  const handleEndDateFirst = (value: string) => {
    if (countryCode === "US") {
      if (checkMonthError(value)) {
        return;
      }
      setEndDate((prevState) => {
        const newState = { ...prevState };
        newState.month = value;
        return newState;
      });
    } else {
      if (checkDayError(value)) {
        return;
      }
      setEndDate((prevState) => {
        const newState = { ...prevState };
        newState.day = value;
        return newState;
      });
    }
  };

  return (
    <ModalContainer>
      <ModalTitle>Download report</ModalTitle>
      <ContentContainer>
        <Row>
          <p>Format</p>
          <Dropdown
            trigger={format}
            onValueChange={(e) => {
              setFormat(e);
            }}
            backgroundColor="#E6E6E6"
            fontColor="#212121"
            size="small"
          >
            <DropdownTextItem text="PDF" value="PDF" />
          </Dropdown>
        </Row>
        <Row>
          <p>Patient</p>
          <PatientName>{patient.user.name}</PatientName>
        </Row>
        <Row dateField>
          <p>Start date</p>
          <TextField
            label=""
            name={countryCode === "US" ? "startDate.month" : "startDate.day"}
            placeholder={countryCode === "US" ? "MM" : "DD"}
            value={countryCode === "US" ? startDate.month : startDate.day}
            onChange={(event) => handleStartDateFirst(event.target.value)}
            backgroudColor="white"
            className={dateInputCss()}
            maxLength={2}
          />
          <TextField
            label=""
            name={countryCode === "US" ? "startDate.day" : "startDate.month"}
            placeholder={countryCode === "US" ? "DD" : "MM"}
            value={countryCode === "US" ? startDate.day : startDate.month}
            onChange={(event) => handleStartDateMiddle(event.target.value)}
            backgroudColor="white"
            className={dateInputCss()}
            maxLength={2}
          />
          <TextField
            label=""
            name="startDate.year"
            placeholder="YYYY"
            value={startDate.year}
            onChange={(event) => handleStartDateLast(event.target.value)}
            backgroudColor="white"
            className={dateInputCss()}
            maxLength={4}
          />
          <ErrorMessage>{errorStartDate}</ErrorMessage>
        </Row>
        <Row dateField>
          <p>End date</p>
          <TextField
            label=""
            name={countryCode === "US" ? "endDate.month" : "endDate.day"}
            placeholder={countryCode === "US" ? "MM" : "DD"}
            value={countryCode === "US" ? endDate.month : endDate.day}
            onChange={(event) => handleEndDateFirst(event.target.value)}
            backgroudColor="white"
            className={dateInputCss()}
            maxLength={2}
          />
          <TextField
            label=""
            name={countryCode === "US" ? "endDate.day" : "endDate.month"}
            placeholder={countryCode === "US" ? "DD" : "MM"}
            value={countryCode === "US" ? endDate.day : endDate.month}
            onChange={(event) => handleEndDateMiddle(event.target.value)}
            backgroudColor="white"
            className={dateInputCss()}
            maxLength={2}
          />
          <TextField
            label=""
            name="endDate.year"
            placeholder="YYYY"
            value={endDate.year}
            onChange={(event) => handleEndDateLast(event.target.value)}
            backgroudColor="white"
            className={dateInputCss()}
            maxLength={4}
          />
          <ErrorMessage>{errorEndDate}</ErrorMessage>
        </Row>
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={handleDownloadClick}
          type="submit"
          label={submitLoading ? "Downloading" : "Download"}
          isLoading={submitLoading}
        />
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      </ButtonContainer>
    </ModalContainer>
  );
}
