import { css, styled } from "@patientmpower/spiro";

// Components
export const FormAnswerField = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "20px",
  marginTop: "30px",
});

export const Title = styled("p", {
  color: "$black",
  fontFamily: "$openSans",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "600",
  padding: "5px",
  marginBottom: "10px",
});

export const Description = styled("p", {
  color: "$black",

  alignSelf: "flex-start",

  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "$openSans",
  fontWeight: "500",
  padding: "5px",
  marginBottom: "10px",
});

export const textInputCss = css({
  width: "100%",

  input: {
    fontSize: "16px",
    fontFamily: "$openSans",
    fontWeight: "400",
  },

  div: {
    textarea: {
      fontSize: "16px",
      fontFamily: "$openSans",
      fontWeight: "400",
    },
  },

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },
});
