import { QueryClient } from "@tanstack/react-query";

function errorHandler() {
  // We can handle things here in the future
}

const defaultOptions = {
  queries: {
    onError: errorHandler,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    staleTime: 180000,
  },
};

const queryClient = new QueryClient({ defaultOptions });
export { queryClient };
