import { useQuery } from "@tanstack/react-query";

import { devicesService } from "../../services/devicesService";

export function useDevices(patientId: number) {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery(
    [`devices-list-${patientId}`, patientId],
    async () => {
      const { data } = await devicesService.getAllDevices(patientId);

      return data;
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    devices: data,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
