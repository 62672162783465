import { createColumn } from "../../../../components/Table";

export const desktopTableColumns = [
  createColumn({
    keyName: "notes",
    header: () => "Note",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "noteType",
    header: () => "Type",
    cell: (info) => info.renderValue(),
    size: 60,
  }),
  createColumn({
    keyName: "time",
    header: () => "Date created",
    cell: (info) => info.renderValue(),
    size: 150,
  }),
  createColumn({
    keyName: "reviewedByEmail",
    header: () => "Created by",
    cell: (info) => info.renderValue(),
    size: 100,
  }),
  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 30,
  }),
];
