export function File({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66665 3.66669H9.16665L11 5.50002H18.3333C19.3416 5.50002 20.1666 6.32502 20.1666 7.33335V16.5C20.1666 17.5084 19.3416 18.3334 18.3333 18.3334H3.66665C2.65831 18.3334 1.83331 17.5084 1.83331 16.5L1.84248 5.50002C1.84248 4.49169 2.65831 3.66669 3.66665 3.66669ZM10.2391 7.33335L8.40581 5.50002H3.66665V16.5H18.3333V7.33335H10.2391Z"
      />
    </svg>
  );
}
