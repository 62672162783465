export function NotificationSuccess() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#58C488" />
      <path
        d="M12.0332 16.3333L9.02112 13.3082L7.09961 15.2427L12.0332 20.1892L21.5627 10.6337L19.6412 8.69922L12.0332 16.3333Z"
        fill="white"
      />
    </svg>
  );
}
