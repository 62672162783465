import { Button } from "@patientmpower/spiro";
import { Modal } from "antd";
import { useState } from "react";

import {
  ButtonContainer,
  CancelButton,
  confirmModalCss,
  ContentContainer,
  ModalSubtitle,
} from "../../CustomOptionsBar.styles";

type ArchiveBaselineModalProps = {
  baseline: number;
  unit?: string;
  onClose: (confirm: boolean) => void;
};

export function ArchiveBaselineModal({
  baseline,
  unit,
  onClose,
}: ArchiveBaselineModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmDelete = () => {
    setIsLoading(true);
    onClose(true);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal
      title="Confirm changes"
      open
      zIndex={3001}
      footer={null}
      width={500}
      onCancel={handleCancel}
      className={confirmModalCss()}
      centered
    >
      <ContentContainer>
        <ModalSubtitle>
          {`Are you sure you want to delete the baseline ${baseline.toFixed(
            1
          )}${
            unit ?? ""
          }? Removing this baseline could mean workflows or alerts may not be triggered.`}
        </ModalSubtitle>
        <ButtonContainer>
          <Button
            label="Confirm"
            type="button"
            onClick={handleConfirmDelete}
            isLoading={isLoading}
          />

          <CancelButton isModal onClick={handleCancel}>
            Cancel
          </CancelButton>
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  );
}
