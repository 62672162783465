export function GraphToggle({ ...rest }) {
  return (
    <svg
      {...rest}
      width="15"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4397 5.07715C11.6438 5.07715 11.1992 5.86394 11.3804 6.44856L9.4319 8.39368C9.26724 8.3445 9.02574 8.3445 8.86108 8.39368L7.46148 7.00041C7.6481 6.41578 7.20352 5.62353 6.40218 5.62353C5.60632 5.62353 5.15626 6.41032 5.34287 7.00041L2.84005 9.48644C2.25277 9.30613 1.4624 9.7487 1.4624 10.5409C1.4624 11.142 1.95638 11.6337 2.56013 11.6337C3.35598 11.6337 3.80056 10.8469 3.61944 10.2623L6.11677 7.7708C6.28142 7.81998 6.52293 7.81998 6.68758 7.7708L8.08719 9.16407C7.90057 9.7487 8.34515 10.5409 9.14649 10.5409C9.94235 10.5409 10.3924 9.75416 10.2058 9.16407L12.1598 7.22442C12.747 7.40473 13.5374 6.96216 13.5374 6.16991C13.5374 5.56889 13.0434 5.07715 12.4397 5.07715Z"
        fill="currentColor"
      />
      <path
        d="M9.14659 5.62301L9.66252 4.492L10.7932 3.98387L9.66252 3.47573L9.14659 2.34473L8.64164 3.47573L7.5 3.98387L8.64164 4.492L9.14659 5.62301Z"
        fill="currentColor"
      />
      <path
        d="M2.83456 6.71628L3.10899 5.62352L4.20672 5.35033L3.10899 5.07714L2.83456 3.98438L2.56013 5.07714L1.4624 5.35033L2.56013 5.62352L2.83456 6.71628Z"
        fill="currentColor"
      />
    </svg>
  );
}
