import { SectionButton, Underline } from "../../WorkflowsBuilder.styles";

interface ISectionButtonComponent {
  isSelected: boolean;
  onClick: () => void;
  label: string;
}

export function SectionButtonComponent({
  isSelected,
  onClick,
  label,
}: ISectionButtonComponent) {
  return (
    <SectionButton
      onClick={onClick}
      onKeyDown={(e) => e.key === "Enter" && onClick()}
      tabIndex={0}
    >
      <p>{label}</p>
      <Underline isActive={isSelected} />
    </SectionButton>
  );
}
