import { Spin } from "antd";
import { FormikProps } from "formik";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";

import { IArticle, IArticleImage } from "../../../../../../@types/Articles";
import { ReactComponent as MissingImage } from "../../../../../../assets/images/missing-image.svg";
import {
  Card,
  CardContainer,
  CardContent,
  CardDescription,
  CardDetailsContainer,
  CardImageContainer,
  CardSubtitle,
  CardTitle,
  ContentContainer,
  IFrameContainer,
  spinCss,
} from "./PreviewSection.styles";

interface IPreviewSectionProps {
  formik: FormikProps<IArticle>;
  images: IArticleImage[];
}

export function PreviewSection({ formik, images }: IPreviewSectionProps) {
  const [isArticleLoading, setIsArticleLoading] = useState(true);

  const handleCardImage = () => {
    const cardImage = images.find((x) => x.key === formik.values.imageName);

    if (cardImage) {
      return <img src={cardImage.preSignedUrl} alt={cardImage.key} />;
    }

    return <MissingImage />;
  };

  return (
    <ContentContainer>
      <Card>
        <CardTitle>Care Plan</CardTitle>
        <CardContainer>
          <CardImageContainer>{handleCardImage()}</CardImageContainer>
          <CardDetailsContainer>
            <CardSubtitle>
              {formik.values.title !== "" ? formik.values.title : "Title"}
            </CardSubtitle>
            <CardDescription>
              {formik.values.description !== ""
                ? formik.values.description
                : "The card description will appear here when added in the content section"}
            </CardDescription>
          </CardDetailsContainer>
        </CardContainer>
      </Card>

      <Card>
        {formik.values.articleUrl === undefined ? (
          <>
            <CardTitle>
              {formik.values.title !== "" ? formik.values.title : "Title"}
            </CardTitle>
            <CardContent>
              {formik.values.articleText !== ""
                ? ReactHtmlParser(formik.values.articleText ?? "")
                : "The article content will appear here when added in the content section"}
            </CardContent>
          </>
        ) : (
          <>
            {isArticleLoading ? (
              <Spin className={spinCss()} size="large" />
            ) : null}
            <IFrameContainer isLoading={isArticleLoading}>
              <iframe
                title="article-preview"
                src={formik.values.articleUrl}
                style={{ width: "330px", height: "620px", border: "0px" }}
                onLoad={() => setIsArticleLoading(false)}
              />
            </IFrameContainer>
          </>
        )}
      </Card>
    </ContentContainer>
  );
}
