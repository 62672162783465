import dayjs from "dayjs";
import { createContext, ReactNode, useMemo } from "react";

type PatientPageContextData = {
  globalDateFilter: {
    fromDate: dayjs.Dayjs;
    toDate: dayjs.Dayjs;
  };
};

type PatientPageProviderProps = {
  children: ReactNode;
};

export const PatientPageContext = createContext({} as PatientPageContextData);

export function PatientPageProvider({ children }: PatientPageProviderProps) {
  const globalDateFilter = {
    fromDate: dayjs().subtract(2, "month"),
    toDate: dayjs(),
  };

  const patientPageValues = useMemo(() => {
    return {
      globalDateFilter,
    };
  }, []);

  return (
    <PatientPageContext.Provider value={patientPageValues}>
      {children}
    </PatientPageContext.Provider>
  );
}
