export function Plus({ ...rest }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66659 5.66659H5.66659V9.66659H4.33325V5.66659H0.333252V4.33325H4.33325V0.333252H5.66659V4.33325H9.66659V5.66659Z"
        fill="white"
      />
    </svg>
  );
}
