export function Vitals({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        d="M2.6577 8.07439V15.4256H6.34229V8.07439H2.6577ZM8.1577 2.57438V15.4256H11.8423V2.57438H8.1577ZM13.6577 9.90772V15.4256H17.3423V9.90772H13.6577ZM17.3423 17.2411H2.6577C2.15847 17.2411 1.73109 17.0633 1.37556 16.7078C1.02004 16.3523 0.842285 15.9249 0.842285 15.4256V8.07439C0.842285 7.57515 1.02004 7.14777 1.37556 6.79224C1.73109 6.43673 2.15847 6.25897 2.6577 6.25897H6.34229V2.57438C6.34229 2.07515 6.52004 1.64777 6.87556 1.29224C7.23109 0.936729 7.65847 0.758972 8.1577 0.758972H11.8423C12.3415 0.758972 12.7689 0.936729 13.1244 1.29224C13.4799 1.64777 13.6577 2.07515 13.6577 2.57438V8.09231H17.3423C17.8415 8.09231 18.2689 8.27006 18.6244 8.62558C18.9799 8.98111 19.1577 9.40849 19.1577 9.90772V15.4256C19.1577 15.9249 18.9799 16.3523 18.6244 16.7078C18.2689 17.0633 17.8415 17.2411 17.3423 17.2411Z"
        fill="#666666"
      />
    </svg>
  );
}
