import { Button, RichTextEditor } from "@patientmpower/spiro";
import { useState } from "react";

import { ICreateNoteProps } from "../../../../../../@types/Note";
import { cleanHTML } from "../../../../../../hooks/queries/notes";
import { noteService } from "../../../../../../services/noteService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  CancelButton,
  Container,
  Title,
  SubmitContainer,
  TextAreaContainer,
} from "./CreateNoteModal.styles";

type CreateNoteModalProps = {
  patientId: number;
  setNotification: (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => void;
  onClose: () => void;
};

export function CreateNoteModal({
  patientId,
  setNotification,
  onClose,
}: CreateNoteModalProps) {
  const [noteContent, setNoteContent] = useState("");

  const handleInputChange = (value: string) => {
    setNoteContent(value);
  };

  const handleCreateNote = async () => {
    const cleanNoteContent: string = await cleanHTML(noteContent);
    mixpanelActions.track("User Action: Save Note");

    const dataToCreateNote: ICreateNoteProps = {
      noteContent: cleanNoteContent,
      patientId,
    };

    noteService.createNote(dataToCreateNote).then((addedSuccessfully) => {
      const { data } = addedSuccessfully;

      if (data) {
        setNotification(true, "Note successfully added", "success");
      } else {
        setNotification(true, "Error adding note", "error");
      }
      onClose();
    });
  };

  const handleCancelClick = () => {
    onClose();
  };

  return (
    <Container>
      <Title>Add Note</Title>
      <TextAreaContainer>
        <RichTextEditor
          value={noteContent}
          height={300}
          placeholderTxt="Enter your note here..."
          onValueChange={(value) => handleInputChange(value)}
        />
      </TextAreaContainer>
      <SubmitContainer>
        <Button label="Save note" type="button" onClick={handleCreateNote} />
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      </SubmitContainer>
    </Container>
  );
}
