/* eslint-disable no-debugger */
import { AxiosResponse } from "axios";

import { IPaginatedResponse } from "../@types/PaginatedResponse";
import { api } from "./api";

export const medicationService = {
  async getMedications(patientId: number): Promise<AxiosResponse> {
    const url = "/Portal/Medications";

    const urlParamsParsed = new URLSearchParams();

    urlParamsParsed.append("patientId", patientId.toString());

    const medicationsResponse = await api.get<IPaginatedResponse<any>>(url, {
      params: urlParamsParsed,
    });

    return medicationsResponse;
  },
};
