export function CircleUnchecked({ ...rest }) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle
        cx="10"
        cy="10"
        r="9.3"
        fill="#F4F4F4"
        stroke="#727272"
        strokeWidth="1.4"
      />
    </svg>
  );
}
