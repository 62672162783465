import axios from "axios";

import { IToken } from "../@types/Login";
import { AUTH_TOKEN_STORAGE } from "../constants/localStorageKeys";
import { authService } from "./authService";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export const checkIfUserIsAuthenticated = async ({
  status,
}: {
  status: number;
}) => {
  const isUserUnauthenticated = status === 401;

  const tokenResponse = localStorage.getItem(AUTH_TOKEN_STORAGE);

  if (tokenResponse !== null && isUserUnauthenticated) {
    const parsedTokenResponse = JSON.parse(tokenResponse) as IToken;

    try {
      const refreshTokenResponse = await authService.refreshToken(
        parsedTokenResponse.refreshToken
      );

      if (refreshTokenResponse.status !== 400) {
        parsedTokenResponse.idToken = refreshTokenResponse.data.idToken;

        localStorage.setItem(
          AUTH_TOKEN_STORAGE,
          JSON.stringify(parsedTokenResponse)
        );

        api.defaults.headers.common.Authorization = `${parsedTokenResponse.tokenType} ${parsedTokenResponse.idToken}`;

        return true;
      }
    } catch (error) {
      console.error("Error refreshing token", error);
      return false;
    }
  }

  if (isUserUnauthenticated) {
    localStorage.clear();
    window.location.assign("/login");
    return false;
  }

  return false;
};

api.interceptors.request.use((request) => {
  const tokenResponse = localStorage.getItem(AUTH_TOKEN_STORAGE);

  if (tokenResponse) {
    const parsedTokenResponse = JSON.parse(tokenResponse) as IToken;

    if (request.headers && parsedTokenResponse.idToken) {
      request.headers.Authorization = `${parsedTokenResponse.tokenType} ${parsedTokenResponse.idToken}`;
    }
  }

  return request;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;

      const tokenRefreshed = await checkIfUserIsAuthenticated({
        status: error.response.status,
      });

      if (tokenRefreshed) {
        const tokenResponse = localStorage.getItem(AUTH_TOKEN_STORAGE);

        if (tokenResponse) {
          const parsedTokenResponse = JSON.parse(tokenResponse) as IToken;

          originalRequest.headers.Authorization = `${parsedTokenResponse.tokenType} ${parsedTokenResponse.idToken}`;

          return api(originalRequest);
        }
      }
    }

    return error.response;
  }
);
