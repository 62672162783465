import { useEffect, useState } from "react";

const getIsMobile = () => window.innerWidth <= 768;
// Small screen property for larger than mobile, but still small width
const getIsSmallScreen = () =>
  window.innerWidth <= 1100 && window.innerWidth > 768;

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [isSmallScreen, setIsSmallScreen] = useState(getIsMobile());
  const [widthView, setWidthView] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
      setWidthView(window.innerWidth);
      setIsSmallScreen(getIsSmallScreen());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return { isMobile, widthView, isSmallScreen };
}
