import { css } from "@patientmpower/spiro";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";

import { IPatientInfo } from "../../../../@types/Patient";
import { ChevronLeft } from "../../../../assets/icons/ChevronLeft";
import { ChevronRight } from "../../../../assets/icons/ChevronRight";
import { ToastNotification } from "../../../../components/ToastNotification";
import { PATIENT_IDS } from "../../../../constants/localStorageKeys";
import { patientListVariants } from "../../../../constants/patientListVariants";
import { useFlagPatient } from "../../../../hooks/mutations/patients";
import {
  usePatientInfo,
  usePatientMonitoringInfo,
} from "../../../../hooks/queries/patients";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { usePatientPage } from "../../../../hooks/usePatientPage";
import { getShortFormattedDate } from "../../../../utils/dateFormatter";
import { FlagPatientButton } from "../../../PatientList/components/PatientListSortedTable/components/FlagPatientButton/FlagPatientButton";
import { DownloadPatientData } from "../DownloadPatientData";
import {
  Text,
  Title,
  Container,
  DesktopContainer,
  TitleContainer,
  PatientCard,
  MobileTitleContainer,
  DownloadButton,
  RightHeadingContainer,
  PatientNavigationContainer,
  tooltipStyles,
  ChevronButton,
  DateContainer,
} from "./PatientSubNav.styles";

dayjs.extend(relativeTime);

type PatientSubNavProps = {
  isLateralPatientMenuOpenOnDesktop: boolean;
};

const bottomSheetCss = css({
  zIndex: "1000px",
});

export function PatientSubNav({
  isLateralPatientMenuOpenOnDesktop,
}: PatientSubNavProps) {
  const { isMobile } = useIsMobile();
  const { openModal, closeModal } = useModal();
  const [showNotification, setShowNotification] = useState(false);
  const [format, setFormat] = useState("");
  const [patientCards, setPatientCards] = useState<
    Array<{ name: string; description: string }>
  >([]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const mutate = useFlagPatient();

  useEffect(() => {
    setIsMenuOpen(isLateralPatientMenuOpenOnDesktop);
  }, [isLateralPatientMenuOpenOnDesktop]);

  const navigate = useNavigate();
  const { patientId } = useParams();
  const location = useLocation();
  const { globalDateFilter } = usePatientPage();

  const queryClient = useQueryClient();

  const { patient } = usePatientInfo({ patientId });
  const { patientMonitoringInfo } = usePatientMonitoringInfo({ patientId });
  const [previousPatientId, setPreviousPatientId] = useState(null);
  const [nextPatientId, setNextPatientId] = useState(null);
  const [variant, setVariant] = useState("spirometry");
  const patientIds = localStorage.getItem(PATIENT_IDS);

  useEffect(() => {
    let patientIdsParsed = null;

    patientIdsParsed = patientIds && JSON.parse(patientIds);
    if (patientIdsParsed !== null) {
      // Find the index of the current patient id in the list
      const currentPatientIndex = patientIdsParsed.indexOf(Number(patientId));
      // Set the previous and next patient IDs
      if (currentPatientIndex !== 0) {
        setPreviousPatientId(patientIdsParsed[currentPatientIndex - 1]);
      } else {
        setPreviousPatientId(null);
      }
      if (currentPatientIndex !== patientIdsParsed.length - 1) {
        setNextPatientId(patientIdsParsed[currentPatientIndex + 1]);
      } else {
        setNextPatientId(null);
      }
    }
    if (location.pathname.includes(patientListVariants.ALERTS)) {
      setVariant("alerts");
    }
  }, [patient]);

  useEffect(() => {
    if (!patient && !patientMonitoringInfo) return;

    setPatientCards([
      {
        name: "Hospital ID",
        description: patient?.hospitalId || "-",
      },
      {
        name: "Email",
        description: patient?.user?.email || "-",
      },
      {
        name: "Last Review",
        description: patientMonitoringInfo?.latestReview || "-",
      },
      {
        name: "Last Reviewed By",
        description: patientMonitoringInfo?.latestReviewedBy || "-",
      },
      {
        name: "Phone",
        description: patient?.user?.phoneNumber || "-",
      },
      {
        name: "Hospital",
        description: patientMonitoringInfo?.hospitalName || "-",
      },
      {
        name: "Last Used",
        description: patient.user?.lastUsed || "-",
      },
    ]);
  }, [patient, patientMonitoringInfo]);

  const formattedDateRange = `${getShortFormattedDate(
    globalDateFilter.fromDate.format()
  )} - ${getShortFormattedDate(globalDateFilter.toDate.format())}`;

  const patientBirthDate = dayjs(patient.birthDate);
  const patientAge = dayjs().diff(patientBirthDate, "year");

  const handleFlagPatient = async (patientId: number, flagged: boolean) => {
    await mutate({
      patientId,
      flagged,
    });

    queryClient.setQueryData(
      [`patient-info-${patientId}`, patientId.toString()],
      (patientInfo?: IPatientInfo) => {
        if (patientInfo) {
          return { ...patientInfo, flagged };
        }
        return patientInfo;
      }
    );
  };

  const handleModalClose = (success?: boolean, format?: string) => {
    closeModal();

    if (success && format) {
      setFormat(format);
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 4000);
    }
  };

  const handleDownloadReport = () => {
    openModal(
      <DownloadPatientData patient={patient} onClose={handleModalClose} />,
      {
        width: "100%",
        height: "100%",
        showCloseButton: true,
      }
    );
  };

  const handlePreviousPatient = () => {
    navigate(`/patients/${previousPatientId}/${variant}`);
  };

  const handleNextPatient = () => {
    navigate(`/patients/${nextPatientId}/${variant}`);
  };

  if (isMobile) {
    const mobileTopHeaderSize = 69;
    const mobileBottomHeaderSize = 87;

    return (
      <BottomSheet
        open
        data-body-scroll-lock-ignore="true"
        blocking={false}
        skipInitialTransition
        scrollLocking={false}
        className={bottomSheetCss()}
        defaultSnap={() => mobileBottomHeaderSize}
        snapPoints={({ maxHeight }) => [
          mobileBottomHeaderSize,
          maxHeight - mobileTopHeaderSize,
        ]}
        header={
          <MobileTitleContainer>
            <Title>{patient.user?.name}</Title>
            <span style={{ color: "#666666" }}>•</span>
            <Title>{patient?.birthDate && patientAge}</Title>
            <Title>{patient?.gender && patient.gender.toUpperCase()}</Title>
          </MobileTitleContainer>
        }
      >
        {patientCards.map((card, index) => (
          <PatientCard key={`${card.description}_${index}`}>
            <p>
              <strong>{card.name}</strong>
            </p>
            <p>{card.description}</p>
          </PatientCard>
        ))}
        {showNotification ? (
          <ToastNotification
            message={`${format} successfully downloaded`}
            type="success"
          />
        ) : null}
      </BottomSheet>
    );
  }

  return (
    <DesktopContainer isLateralPatientMenuOpenOnDesktop={isMenuOpen}>
      <Container>
        <TitleContainer>
          <Title>{patient.user?.name}</Title>
          <span>•</span>
          <Title>{patient?.birthDate && patientAge}</Title>
          <Title>{patient?.gender && patient.gender.toUpperCase()}</Title>
        </TitleContainer>
        <RightHeadingContainer>
          <Tooltip
            title={patient.flagged ? "Remove Flag" : "Flag this patient"}
            zIndex={3002}
            color="#4B4B4B"
            arrow={false}
            placement="bottomLeft"
            overlayInnerStyle={tooltipStyles}
          >
            <div>
              <FlagPatientButton
                onClick={async (e) => {
                  e.stopPropagation();

                  await handleFlagPatient(patient.id, !patient.flagged);
                }}
                flagged={patient.flagged}
                isPatientsPage
              />
            </div>
          </Tooltip>
          <DateContainer>
            <Text>{formattedDateRange}</Text>
          </DateContainer>
          <DownloadButton onClick={handleDownloadReport}>
            Download
          </DownloadButton>
          <PatientNavigationContainer>
            {previousPatientId !== null ? (
              <Tooltip
                title="Previous patient"
                zIndex={3002}
                color="#4B4B4B"
                arrow={false}
                placement="bottomLeft"
                align={{ offset: [-90, 5] }}
                overlayInnerStyle={tooltipStyles}
              >
                <ChevronButton onClick={handlePreviousPatient}>
                  <ChevronLeft opacity="1.0" />
                </ChevronButton>
              </Tooltip>
            ) : (
              <ChevronButton disabled>
                <ChevronLeft opacity="0.38" />
              </ChevronButton>
            )}
            {nextPatientId !== null ? (
              <Tooltip
                title="Next patient"
                zIndex={3002}
                color="#4B4B4B"
                arrow={false}
                placement="bottomLeft"
                overlayInnerStyle={tooltipStyles}
              >
                <ChevronButton onClick={handleNextPatient}>
                  <ChevronRight opacity="1.0" />
                </ChevronButton>
              </Tooltip>
            ) : (
              <ChevronButton disabled>
                <ChevronRight opacity="0.38" />
              </ChevronButton>
            )}
          </PatientNavigationContainer>
        </RightHeadingContainer>
        {showNotification ? (
          <ToastNotification
            message={`${format} successfully downloaded`}
            type="success"
          />
        ) : null}
      </Container>
    </DesktopContainer>
  );
}
