export function DoubleFlag({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4167 0.916748H8.24083C7.2325 0.916748 6.41667 1.74175 6.41667 2.75008H15.5833C16.5917 2.75008 17.4167 3.57508 17.4167 4.58341V16.5001L19.25 17.4167V2.75008C19.25 1.74175 18.425 0.916748 17.4167 0.916748ZM13.75 6.41675V18.3059L9.89083 16.6467L9.16667 16.3351L8.4425 16.6467L4.58333 18.3059V6.41675H13.75ZM4.58333 4.58341H13.75C14.7583 4.58341 15.5833 5.40841 15.5833 6.41675V21.0834L9.16667 18.3334L2.75 21.0834V6.41675C2.75 5.40841 3.575 4.58341 4.58333 4.58341Z"
      />
    </svg>
  );
}
