export function ArrowLeft({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      {...rest}
    >
      <path
        d="M7.08331 4.25L2.83331 8.5M2.83331 8.5L7.08331 12.75M2.83331 8.5H13.4583"
        stroke="#0E0B19"
        strokeLinecap="round"
      />
    </svg>
  );
}
