import { createColumn } from "../../../../../../components/Table";

export const manageAlertsTableColumns = [
  createColumn({
    keyName: "measurementType",
    header: () => "Type",
    cell: (info) => info.renderValue(),
    size: 250,
  }),
  createColumn({
    keyName: "trigger",
    header: () => "Trigger",
    cell: (info) => info.renderValue(),
    size: 200,
  }),
  createColumn({
    keyName: "timeFrame",
    header: () => "Time frame",
    cell: (info) => info.renderValue(),
    size: 140,
  }),
  createColumn({
    keyName: "hcpNotification",
    header: () => "HCP notification",
    cell: (info) => info.renderValue(),
    size: 180,
  }),
  createColumn({
    keyName: "hcpMessage",
    header: () => "HCP message",
    cell: (info) => info.renderValue(),
    size: 800,
  }),
  createColumn({
    keyName: "empty",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 10,
  }),
  createColumn({
    keyName: "created",
    header: () => "Created",
    cell: (info) => info.renderValue(),
    size: 240,
  }),
  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 10,
  }),
];
