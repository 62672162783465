import i18n, { LanguageDetectorModule } from "i18next";
import { initReactI18next } from "react-i18next";

import { IUserPreferences } from "../@types/Preferences";
import { AUTH_UNIT_PREFERENCES } from "../constants/localStorageKeys";

const handleLanguage = () => {
  let lang: string | null = "";

  const urlParams = new URLSearchParams(window.location.search);
  lang = urlParams.get("language");

  if (!lang) {
    const portalPreferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);
    if (portalPreferences) {
      const parsedPortalPreferences = portalPreferences
        ? (JSON.parse(portalPreferences) as IUserPreferences)
        : undefined;

      lang = parsedPortalPreferences?.languageCode ?? null;
    }
  }
  return lang ?? undefined;
};

const LanguageDetector: LanguageDetectorModule = {
  type: "languageDetector",
  detect: handleLanguage,
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    fallbackLng: "en-GB",
  });

export default i18n;
