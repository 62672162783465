import * as CryptoJS from "crypto-js";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";

import { AUTH_INTERCOM_DETAILS } from "../constants/localStorageKeys";
import { useAuth } from "../hooks/useAuth";
import { useIntercomCustom } from "../hooks/useIntercomCustom";

type IntercomContextData = {
  isIntercomOpen: boolean;
  setIsIntercomOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type IntercomProviderProps = {
  children: ReactNode;
};

export const IntercomContext = createContext({} as IntercomContextData);

function Intercom() {
  const { boot, isOpen, show, hide } = useIntercom();
  const { isAuthenticated } = useAuth();
  const { isIntercomOpen, setIsIntercomOpen } = useIntercomCustom();

  const bootIntercom = () => {
    const intercomDetails = localStorage.getItem(AUTH_INTERCOM_DETAILS);

    if (intercomDetails !== null) {
      let intercomDetailsParsed = null;
      try {
        intercomDetailsParsed = intercomDetails && JSON.parse(intercomDetails);
      } catch (error) {
        console.log("error", error);
      }

      const secret = String(process.env.REACT_APP_INTERCOM_IDENTITY_SECRET);
      const hash = CryptoJS.HmacSHA256(
        String(intercomDetailsParsed.userId),
        secret
      ).toString();

      boot({
        email: intercomDetailsParsed.userEmail ?? undefined,
        horizontalPadding: 60,
        userHash: hash,
        userId: intercomDetailsParsed.userId,
        customAttributes: {
          "P id": intercomDetailsParsed.patientId,
        },
        hideDefaultLauncher: true,
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      bootIntercom();
    }
  }, [isAuthenticated]);

  const shutdownIntercom = () => {
    hide();
    setIsIntercomOpen(false);
  };

  useEffect(() => {
    if (!isOpen && isAuthenticated) {
      shutdownIntercom();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isIntercomOpen) {
      show();
    }

    if (!isIntercomOpen && isAuthenticated) {
      shutdownIntercom();
    }
  }, [isIntercomOpen]);

  return <div />;
}

export function IntercomCustomProvider({ children }: IntercomProviderProps) {
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);

  const values = useMemo(() => {
    return {
      isIntercomOpen,
      setIsIntercomOpen,
    };
  }, [isIntercomOpen]);

  return (
    <IntercomContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={values}
    >
      <IntercomProvider appId={String(process.env.REACT_APP_INTERCOM_APP_ID)}>
        <Intercom />
      </IntercomProvider>
      {children}
    </IntercomContext.Provider>
  );
}
