import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { tooltipTitles } from "../../constants/measurements";
import { StyledTooltip } from "./CustomRechartsTooltip.styles";

export function CustomRechartsTooltip({
  active,
  payload,
  data,
}: TooltipProps<ValueType, NameType> | any) {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const isUnformattedTime = payload[0].name === "unformattedTime";
  const multipleValues = payload.length > 1 && !isUnformattedTime;

  const payoadSource = payload[0].payload.source;
  const isArchived = payoadSource.includes("archived");

  const getArchivedText = () => {
    if (isArchived) {
      if (payoadSource.includes("patient")) {
        return "(archived by patient)";
      }

      return "(archived)";
    }

    return "";
  };

  if (isUnformattedTime) {
    const groupedMap = new Map<string, any>();

    data.forEach((obj: any) => {
      const time = obj.unformattedTime;
      if (!groupedMap.has(time)) {
        groupedMap.set(time, []);
      }
      groupedMap.get(time).push(obj);
    });

    let diastolic = 0;
    let systolic = 0;

    groupedMap.get(payload[0].value).forEach((data: any) => {
      diastolic = data.bp_diastolic || diastolic;
      systolic = data.bp_systolic || systolic;
    });

    if (diastolic === 0 && systolic === 0) {
      return (
        <StyledTooltip>
          <h4>
            {payload[1].value} {getArchivedText()}
          </h4>
          <p>{payload[0].payload.time}</p>
        </StyledTooltip>
      );
    }

    return (
      <StyledTooltip>
        <>
          <h4>{`${tooltipTitles.get("bp_systolic")}: ${systolic}`}</h4>
          <h4>{`${tooltipTitles.get("bp_diastolic")}: ${diastolic}`}</h4>

          {isArchived ? <h4>{getArchivedText()}</h4> : null}
        </>
        <p>{payload[0].payload.time}</p>
      </StyledTooltip>
    );
  }

  return (
    <StyledTooltip singleValue={!multipleValues}>
      {multipleValues ? (
        <>
          {payload.map((data: any, index: number) => {
            const key = data.dataKey?.toString() ?? "";
            return (
              <h4 key={`${key}_${index}`}>{`${tooltipTitles.get(key)}: ${
                data.value
              }`}</h4>
            );
          })}

          {isArchived ? <h4>{getArchivedText()}</h4> : null}
        </>
      ) : (
        <h4>
          {payload[0].value} {getArchivedText()}
        </h4>
      )}
      <p>{payload[0].payload.time}</p>
    </StyledTooltip>
  );
}
