import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

const customThresholds = [
  { l: "s", r: 1 },
  { l: "m", r: 1 },
  { l: "mm", r: 59, d: "minute" },
  { l: "h", r: 1 },
  { l: "hh", r: 23, d: "hour" },
  { l: "d", r: 1 },
  { l: "dd", r: 29, d: "day" },
  { l: "M", r: 1 },
  { l: "MM", r: 11, d: "month" },
  { l: "y", r: 1 },
  { l: "yy", d: "year" },
];

const dayJsConfig = {
  customThresholds,
  rounding: Math.floor,
};

dayjs.extend(relativeTime, dayJsConfig);

export function getLongFormattedDate(date: string | undefined) {
  if (!date) {
    return "";
  }

  const dateObj = new Date(date);

  return dayjs(dateObj).format("MMM D, YYYY, hh:mma");
}

export function getLongFormattedDateISO(date: string) {
  if (!date) {
    return "";
  }

  const dateObj = new Date(date).toISOString();

  return dayjs(dateObj).format("MMM D, YYYY, hh:mma");
}

export function getShortFormattedDate(date: string) {
  const dateObj = new Date(date);

  return dayjs(dateObj).format("MMM D, YYYY");
}

export function getRelativeTimeSinceDate(dateString: string) {
  const lastUsedDate = dayjs(dateString, "MMM D, YYYY, hh:mma");
  return lastUsedDate.fromNow();
}
