import { IconButton, styled } from "@patientmpower/spiro";

export const PatientMenuCardContainer = styled("div", {
  width: "100%",
  minHeight: 80,

  padding: "16px 22px 16px 28px",

  borderBottom: "1px solid $gray-40",

  fontFamily: "$openSans",

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "&[dragging='true']": {
    backgroundColor: "$gray-60",
    opacity: "0.7",
  },

  variants: {
    isVisible: {
      true: {
        backgroundColor: "$white",
      },
      false: {
        backgroundColor: "$gray-30",
      },
    },
  },
});

export const PatientMenuCardContent = styled("div", {
  width: "100%",

  p: {
    fontSize: "12px",
    fontWeight: "600",
    color: "$gray-90",
    paddingLeft: 8,
  },

  button: {
    width: "100%",
    marginTop: 4,
    padding: "3px 8px 4px",

    fontFamily: "$openSans",
    fontSize: "12px",
    fontWeight: "400",

    textAlign: "left",

    border: "none",
    borderRadius: 6,

    color: "$gray-90",
    backgroundColor: "transparent",

    "&:hover": {
      cursor: "pointer",
      // backgroundColor: "$primary-20",
    },
  },
});

export const PatientMenuCardControls = styled("div", {
  display: "flex",
  alignItems: "center",

  columnGap: "$8",
});

export const GrabButton = styled("button", {
  cursor: "grab",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  border: "none",
  borderRadius: "50%",
  backgroundColor: "transparent",

  width: 28,
  height: 28,
  padding: 8,

  "&:active": {
    cursor: "grabbing",
  },
});

export const VisibilityButton = styled(IconButton, {
  width: "22px !important",
  height: "22px !important",
  variants: {
    isActive: {
      true: {
        backgroundColor: "$gray-60",
      },
      false: {
        backgroundColor: "$success-light",

        "&:hover": {
          backgroundColor: "$success-light",
          opacity: 0.6,
        },
      },
    },
  },
});

export const DatePickerContainer = styled("div", {
  top: 150,
  right: 20,
  zIndex: 3000,
  borderRadius: "16px",
  position: "fixed",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.22)",
  border: "1px solid var(--colors-gray-50)",

  ".react-datepicker__navigation": {
    height: "20px !important",
  },
});

export const CopyContainer = styled("span", {
  padding: "4px 7px",
  borderRadius: "50%",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$gray-30",
  },
});
