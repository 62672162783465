import { Checkbox } from "@patientmpower/spiro";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import {
  IQuestionDisplayProps,
  ISurveyAnswer,
  ISurveyQuestionOption,
} from "../../../../../../../@types/Surveys";
import { useDynamicsurveys } from "../../../../../../../hooks/useDynamicSurveys";
import { ClickableOption } from "../FiveQuestionsAboutSupOxDisplay/FiveQuestionsAboutSupOxDisplay.styles";
import { OptionsSelectionContainer } from "../SingleSelectQuestionDisplay/SingleSelectQuestionDisplay.styles";
import {
  FormAnswerField,
  Title,
  Description,
} from "../TextQuestionDisplay/TextQuestionDisplay.styles";

interface ISurveyQuestionOptionAnswer {
  value: number;
  optionId: string;
}

export function MultiSelectQuestionDisplay({
  title,
  description,
  options,
  questionId,
}: IQuestionDisplayProps) {
  const [selectedValues, setSelectedValues] = useState<
    ISurveyQuestionOptionAnswer[]
  >([]);
  const { surveyAnswerArray, updateSurveyAnswerArray } = useDynamicsurveys();

  const multiSelectFormik = useFormik({
    initialValues: {
      answer: "",
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    const storedAnswer = surveyAnswerArray.find(
      (surveyAnswer) => surveyAnswer.surveyQuestionId === questionId
    );

    if (storedAnswer !== undefined && storedAnswer.answerValue !== "") {
      //  const optionsValuesList = storedAnswer.answerValue.split("|");
      //  const optionsValuesListParsed = optionsValuesList.map(Number);
      const optionsListParsed: ISurveyQuestionOptionAnswer[] = [];

      storedAnswer.surveyQuestionOptionIds?.forEach((optionId) => {
        optionsListParsed.push({
          value: options?.find((option) => option.id === optionId).value,
          optionId,
        });
      });

      if (optionsListParsed !== undefined) {
        setSelectedValues(optionsListParsed);
      }
    }
  }, []);

  const handleOptionClick = (option: ISurveyQuestionOption) => {
    const updatedSelectedValues = [...selectedValues];
    if (
      selectedValues.find(
        (selectedValue) => selectedValue.optionId === option.id
      ) === undefined
    ) {
      updatedSelectedValues.push({
        value: option.value,
        optionId: option.id ?? "",
      });
    } else {
      const indexToRemove = selectedValues.findIndex(
        (selectedValue) => selectedValue.optionId === option.id
      );
      updatedSelectedValues.splice(indexToRemove, 1);
    }
    updatedSelectedValues.sort();
    setSelectedValues(updatedSelectedValues);
  };

  const updateAnswerArray = (value: string) => {
    const answerIdArray: string[] = [];

    selectedValues.forEach((answer) => {
      answerIdArray.push(answer.optionId);
    });

    const answerUpdate: ISurveyAnswer = {
      surveyQuestionId: questionId ?? "",
      answerValue: value,
      surveyQuestionOptionIds: answerIdArray,
    };
    updateSurveyAnswerArray(answerUpdate);
  };

  useEffect(() => {
    const values = selectedValues.map((selectedValues) => selectedValues.value);

    const parsedSelectedValues = values.join("|");
    multiSelectFormik.setFieldValue("answer", parsedSelectedValues);
    updateAnswerArray(parsedSelectedValues);
  }, [selectedValues]);

  const verifyOptionSelection = (multipleSelectOption: string) => {
    let isSelected = false;

    selectedValues?.forEach((selectedValue) => {
      if (selectedValue.optionId === multipleSelectOption) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  return (
    <FormAnswerField>
      <Title> {title}</Title>
      <Description> {description}</Description>
      {options?.map((option: ISurveyQuestionOption, index: number) => (
        <OptionsSelectionContainer key={index}>
          <ClickableOption
            onClick={() => {
              handleOptionClick(option);
            }}
          >
            <Checkbox
              key={`${index}`}
              label=""
              checked={verifyOptionSelection(option.id ?? "")}
              onChange={() => {}}
            />
            <p style={{ marginLeft: "10px" }}>{option.label}</p>
          </ClickableOption>
        </OptionsSelectionContainer>
      ))}
    </FormAnswerField>
  );
}
