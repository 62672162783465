import { useState } from "react";

import { AUTH_TOKEN_STORAGE } from "../constants/localStorageKeys";

export function useToken() {
  const [token, setStoredToken] = useState(() => {
    const token = localStorage.getItem(AUTH_TOKEN_STORAGE);

    if (token) {
      localStorage.setItem(AUTH_TOKEN_STORAGE, token);
    }

    return token;
  });

  const setToken = (token: string) => {
    localStorage.setItem(AUTH_TOKEN_STORAGE, JSON.stringify(token));
    setStoredToken(token);
  };

  return { token, setToken };
}
