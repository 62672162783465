import dayjs, { Dayjs } from "dayjs";

export const dateFilterOptions = [
  {
    label: "48H",
    value: (currentDate: Dayjs) => currentDate.subtract(2, "day"),
  },
  {
    label: "7D",
    value: (currentDate: Dayjs) => currentDate.subtract(7, "day"),
  },
  {
    label: "1M",
    value: (currentDate: Dayjs) => currentDate.subtract(1, "month"),
  },
  {
    label: "6M",
    value: (currentDate: Dayjs) => currentDate.subtract(6, "month"),
  },
  {
    label: "12M",
    value: (currentDate: Dayjs) => currentDate.subtract(12, "month"),
  },
  {
    label: "ALL",
    value: () => dayjs("0001-01-01T00:00:00Z"),
  },
];

export const dateFilterOptionsMobile = [
  {
    label: "24H",
    value: (currentDate: Dayjs) => currentDate.subtract(1, "day"),
  },
  {
    label: "48H",
    value: (currentDate: Dayjs) => currentDate.subtract(2, "day"),
  },
  {
    label: "7D",
    value: (currentDate: Dayjs) => currentDate.subtract(7, "day"),
  },
  {
    label: "1M",
    value: (currentDate: Dayjs) => currentDate.subtract(1, "month"),
  },
];
