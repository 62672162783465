import { AxiosResponse } from "axios";

import { IHospitalGroupOptions } from "../@types/Hospital";
import { api } from "./api";

export const hospitalService = {
  async getHospitalGroups(): Promise<AxiosResponse<IHospitalGroupOptions[]>> {
    const url = "/Portal/Hospital/HospitalGroups";

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
