import { QuestionMark } from "../../assets/icons/QuestionMark";
import { useIntercomCustom } from "../../hooks/useIntercomCustom";
import { mixpanelActions } from "../../utils/mixpanel";
import { SupportButtoncontainer } from "./SupportButton.styles";

interface ISupportButtonProps {
  style?: React.CSSProperties;
}
export function SupportButton({ style }: ISupportButtonProps) {
  const { setIsIntercomOpen } = useIntercomCustom();

  const handleSupportClick = () => {
    mixpanelActions.track("UserAction: SupportButton");
    setIsIntercomOpen((prevState) => !prevState);
  };
  return (
    <SupportButtoncontainer style={style} onClick={handleSupportClick}>
      <QuestionMark />
    </SupportButtoncontainer>
  );
}
