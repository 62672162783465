export function ChevronUp() {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71441 2.99226L2.47757 8.2291C2.29956 8.40713 2.0758 8.4982 1.80629 8.50232C1.53681 8.50643 1.30894 8.41536 1.12268 8.2291C0.936402 8.04284 0.843262 7.81703 0.843262 7.55166C0.843262 7.28627 0.936402 7.06044 1.12268 6.87418L6.90097 1.0959C7.02129 0.975574 7.14821 0.890686 7.28173 0.841228C7.41523 0.791793 7.55946 0.767075 7.71441 0.767075C7.86936 0.767075 8.01358 0.791793 8.14708 0.841228C8.28061 0.890686 8.40753 0.975574 8.52785 1.0959L14.3061 6.87418C14.4842 7.05221 14.5752 7.27597 14.5794 7.54545C14.5835 7.81496 14.4924 8.04284 14.3061 8.2291C14.1199 8.41536 13.8941 8.50849 13.6287 8.50849C13.3633 8.50849 13.1375 8.41536 12.9513 8.2291L7.71441 2.99226Z"
        fill="#666666"
      />
    </svg>
  );
}
