import { Dropdown, DropdownTextItem } from "@patientmpower/spiro";
import { useNavigate, useParams } from "react-router-dom";

import { IRoutePattern } from "../../@types/Route";
import {
  patientListChildRoutes,
  patientPageChildRoutes,
} from "../../routes/child.routes";

type DropdownRouterProps = {
  childRoutesOf: "PatientList" | "PatientPage"; // required param
};

export function DropdownRouter({ childRoutesOf }: DropdownRouterProps) {
  const navigate = useNavigate();
  const { patientId } = useParams();

  const isChildRoutesOfPatientList = childRoutesOf === "PatientList";

  type DropdonwRouterInfo = { name: string; path: string };

  let childRoutesInfos: DropdonwRouterInfo[] = [];

  const childRoutesToParse = isChildRoutesOfPatientList
    ? patientListChildRoutes
    : patientPageChildRoutes;

  function childRoutesToDropdonwRouterInfo(childRoutes: IRoutePattern[]) {
    return childRoutes
      .filter(({ disabled }) => !disabled)
      .map(({ name, path }) => ({ name, path })) as DropdonwRouterInfo[];
  }

  childRoutesInfos = childRoutesToDropdonwRouterInfo(childRoutesToParse);

  const changeRoute = (path: string) => {
    if (isChildRoutesOfPatientList) {
      navigate(path);
      return;
    }

    if (patientId) {
      const uriPatientIdParam = ":patientId";
      const pathWithValidId = path.replace(uriPatientIdParam, patientId);
      navigate(pathWithValidId);
      return;
    }

    navigate("not-found");
  };

  return (
    <Dropdown
      trigger={childRoutesInfos.at(0)?.name}
      onValueChange={changeRoute}
    >
      {childRoutesInfos.map(({ name, path }, index) => (
        <DropdownTextItem
          key={`${name.toLocaleLowerCase()}_${index}`}
          text={name}
          value={path}
        />
      ))}
    </Dropdown>
  );
}
