import { Table } from "@patientmpower/spiro";

import { IPatientMedicationsResponse } from "../../../../@types/Patient";
import { ReactComponent as NoMedicationsImage } from "../../../../assets/images/man-woman-phone.svg";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { desktopTableColumns } from "../MedicationsSection/patientMedicationsTableColumns";
import {
  ImageContainer,
  MedicationsTableContainer,
  MedicationsTableInnerContainer,
  NoContentMessage,
} from "./PatientMedicationsTable.style";

type PatientMedicationsTableProps = {
  content: IPatientMedicationsResponse[];
  isLoading?: boolean;
  isError?: boolean;
  highlightedRows: boolean[];
  refreshTable: () => void;
};

export function PatientMedicationsTable({
  isLoading,
  isError,
  content,
  highlightedRows,
  refreshTable,
}: PatientMedicationsTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={4} />;
  }

  if (isError) {
    return (
      <ImageContainer>
        <p>
          Error getting medications, please{" "}
          <button type="button" onClick={refreshTable}>
            try again
          </button>
          . If the issue continues contact support.
        </p>
      </ImageContainer>
    );
  }

  if (content && content.length > 0) {
    return (
      <MedicationsTableContainer>
        <MedicationsTableInnerContainer>
          <Table
            data={content}
            columns={desktopTableColumns}
            highlightedRows={highlightedRows}
            highlightColor="rgba(233, 230, 244, .48)"
          />
        </MedicationsTableInnerContainer>
      </MedicationsTableContainer>
    );
  }

  return (
    <ImageContainer>
      <NoMedicationsImage width={181} height={158} />
      <NoContentMessage>
        Your patient has not added any medications yet. Medication will appear
        here.
      </NoContentMessage>
    </ImageContainer>
  );
}
