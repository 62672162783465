import { Button, TextArea, TextField } from "@patientmpower/spiro";
import { useFormik } from "formik";

import {
  ModalContainer,
  CancelButton,
} from "../../../../../../Settings/components/SecurityModal/SecurityModal.styles";
import {
  FormField,
  FormInfoContainer,
  textInputCss,
} from "../../SurveyBuilder.styles";
import {
  ModalContentContainer,
  HandleQuestionFormContainer,
} from "../AddQuestionModal/AddQuestionModal.styles";

interface IEditSurveyInfoModal {
  onClose(): void;
  onSurveyTitleChange: any;
  onSurveyDescriptionChange: any;
  title: string;
  description: string;
}

export function EditSurveyInfoModal({
  onClose,
  onSurveyTitleChange,
  onSurveyDescriptionChange,
  title,
  description,
}: IEditSurveyInfoModal) {
  const surveyInfoFormik = useFormik({
    initialValues: {
      surveyTitle: title,
      surveyDescription: description,
    },
    onSubmit: () => {},
  });

  const saveChanges = () => {
    onSurveyTitleChange(surveyInfoFormik.values.surveyTitle);
    onSurveyDescriptionChange(surveyInfoFormik.values.surveyDescription);
    onClose();
  };
  return (
    <ModalContainer>
      <ModalContentContainer>
        <FormInfoContainer>
          <FormField>
            <p>Survey title</p>
            <TextField
              maxLength={200}
              label=""
              name="surveyTitle"
              placeholder="title"
              value={surveyInfoFormik.values.surveyTitle}
              onChange={surveyInfoFormik.handleChange}
              backgroudColor="white"
              className={textInputCss()}
              onBlur={surveyInfoFormik.handleBlur}
            />
          </FormField>
          <FormField>
            <p>Survey description</p>
            <TextArea
              name="surveyDescription"
              placeholder="description..."
              value={surveyInfoFormik.values.surveyDescription}
              onChange={surveyInfoFormik.handleChange}
              backgroudColor="white"
              className={textInputCss()}
              rows={3}
              cols={150}
            />
          </FormField>
        </FormInfoContainer>
        <HandleQuestionFormContainer>
          <Button label="Save" type="button" onClick={saveChanges} />
          <CancelButton
            style={{
              border: "2px solid rgba(248, 248, 248, 0.8)",
              backgroundColor: "rgba(248, 248, 248, 0.8)",
            }}
            onClick={() => onClose()}
          >
            Cancel
          </CancelButton>
        </HandleQuestionFormContainer>
      </ModalContentContainer>
    </ModalContainer>
  );
}
