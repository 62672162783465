import { createColumn } from "@patientmpower/spiro";

export const surveyTableColumns = [
  createColumn({
    keyName: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 300,
  }),
  createColumn({
    keyName: "createdBy",
    header: () => "Created by",
    cell: (info) => info.renderValue(),
    size: 90,
  }),

  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 30,
  }),
];
