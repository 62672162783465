export function ChevronRight(props: any) {
  const { opacity } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M3.72203 12.1996C3.42588 12.478 3.42588 12.9294 3.72203 13.2079C4.01818 13.4863 4.49833 13.4863 4.79448 13.2079L10.8611 7.50415C11.1573 7.22572 11.1573 6.7743 10.8611 6.49587L4.79448 0.792166C4.49833 0.513736 4.01818 0.513736 3.72203 0.792166C3.42588 1.07059 3.42588 1.52202 3.72203 1.80045L9.25241 6.99995L3.72203 12.1996Z"
          fill="#939393"
        />
      </g>
    </svg>
  );
}
