export function CustomModalChartTick(props: any) {
  const { x, y, tickFormatter, payload } = props;

  const date = tickFormatter(payload.value);
  const splittedDate = date.split(",");

  const tickToShow: any[] = [];

  splittedDate.forEach((value: string, index: number) => {
    tickToShow.push(
      <tspan
        textAnchor="middle"
        x="0"
        dy={`${0.71 + 0.71 * index}em`}
        key={`${date.toString()}-${index}`}
      >
        {value}
      </tspan>
    );
  });

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} fill="#727272" fontSize="12px" fontWeight="400">
        {tickToShow}
      </text>
    </g>
  );
}
