import { Button, TextField } from "@patientmpower/spiro";
import { useFormik } from "formik";
import * as yup from "yup";

import { ModalTitle } from "../../../../../../../Settings/components/SecurityModal/SecurityModal.styles";
import {
  AddLinkModalContainer,
  CancelButton,
  NoteSection,
  SubmitContainer,
  textFieldCss,
  URLContainer,
} from "./AddLinkModal.styles";

interface IAddLinkModalProps {
  onClose: (url?: string) => void;
}

export function AddLinkModal({ onClose }: IAddLinkModalProps) {
  const handleOnSubmitForm = (values: any) => {
    onClose(values.url);
  };

  const formValidationSchema = yup.object().shape({
    url: yup
      .string()
      .url("Please enter a valid URL with the 'https://' protocol.")
      .required("URL is required."),
  });

  const formik = useFormik({
    initialValues: {
      url: undefined,
    },
    onSubmit: handleOnSubmitForm,
    validationSchema: formValidationSchema,
  });

  return (
    <AddLinkModalContainer>
      <ModalTitle style={{ width: "fit-content" }}>Enter URL</ModalTitle>

      <URLContainer>
        <TextField
          label="URL (https://www.example.com)"
          name="url"
          placeholder="URL"
          backgroudColor="white"
          className={textFieldCss()}
          onChange={formik.handleChange}
          errorMessage={formik.touched.url ? formik.errors.url ?? "" : ""}
        />
      </URLContainer>

      <NoteSection>
        <p style={{ fontWeight: 500 }}>Please note</p>
        <p>
          Patients opening this article will get redirected to the URL. Any text
          added to the text box will be removed.
        </p>
      </NoteSection>

      <SubmitContainer>
        <Button label="Confirm" type="button" onClick={formik.handleSubmit} />
        <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
      </SubmitContainer>
    </AddLinkModalContainer>
  );
}
