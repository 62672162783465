import {
  Button,
  TextArea,
  TextField,
  SelectDropdown,
} from "@patientmpower/spiro";
import { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { ICreateMessageProps } from "../../../../../../@types/Message";
import { Close } from "../../../../../../assets/icons/Close";
import { SwitchOff } from "../../../../../../assets/icons/SwitchOff";
import { messageService } from "../../../../../../services/messageService";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import {
  CancelButton,
  Container,
  Title,
  SubmitContainer,
  TextAreaContainer,
  TextFieldContainer,
  DropdownContainer,
  FirstRowContainer,
  BottomDropdownContainer,
  OptionsContainer,
  DeleteButton,
  OptionLabel,
  OptionsRowContainer,
  OptionsContainerScroll,
  NewOptionButton,
  SendMethodDropdown,
  optionTextFieldStyles,
  ErrorMessage,
  ContentContainer,
} from "./CreateMessageModal.styles";

type CreateMessageModalProps = {
  patientId: number;
  patientPhoneNumber: string | undefined;
  hasRegisteredDevices: boolean;
  setNotification: (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => void;
  onClose: () => void;
};

const useRefs = () => {
  // Keep track of references by key for the options
  const refsByKey = useRef<Record<string, HTMLInputElement | null>>({});

  const setRef = (element: HTMLInputElement | null, key: number) => {
    refsByKey.current[key] = element;
  };

  return { refsByKey: refsByKey.current, setRef };
};

export function CreateMessageModal({
  patientId,
  patientPhoneNumber,
  hasRegisteredDevices,
  setNotification,
  onClose,
}: CreateMessageModalProps) {
  const [messageContent, setMessageContent] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageType, setMessageType] = useState("FreeText");
  const [responseTimeFrame, setResponseTimeFrame] = useState("TwentyFourHours");
  const [sendMethod, setSendMethod] = useState("App");
  const initialUserEnteredOptions = [
    {
      id: 1,
      value: "Option 1",
      name: "option-1",
      key: uuidv4(),
    },
  ];
  const [userEnteredOptions, setUserEnteredOptions] = useState(
    initialUserEnteredOptions
  );
  const [errorTitle, setErrorTitle] = useState("");
  const [errorOptionValue, setErrorOptionValue] = useState("");
  const { refsByKey, setRef } = useRefs();
  const [updatingRefId, setUpdatingRefId] = useState(0); // Set to 0 as this ID is not available
  const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);

  const handleInputChange = (value: string) => {
    setMessageContent(value);
  };

  const handleTitleChange = (value: string) => {
    if (value !== "") {
      setErrorTitle("");
    }
    setMessageTitle(value);
  };

  const optionsMessageType = [
    { label: "Text response", value: "FreeText", key: uuidv4() },
    { label: "Multiple choice", value: "Options", key: uuidv4() },
    { label: "No answer", value: "NoAnswer", key: uuidv4() },
  ];

  const optionsTimeFrame = [
    {
      label: "Must respond within 24h",
      value: "TwentyFourHours",
      key: uuidv4(),
    },
    {
      label: "Must respond within 48h",
      value: "FortyEightHours",
      key: uuidv4(),
    },
    {
      label: "Must respond within 72h",
      value: "SeventyTwoHours",
      key: uuidv4(),
    },
    {
      label: "Must respond within one week",
      value: "OneWeek",
      key: uuidv4(),
    },
  ];

  const optionsSendMethod = [];
  if (hasRegisteredDevices) {
    // Allow App if the patient has at least one registered device
    optionsSendMethod.push({ label: "App", value: "App", key: uuidv4() });
  }

  if (patientPhoneNumber !== null && patientPhoneNumber !== "") {
    // Allow SMS if the patient has a phone number
    /* SMS not available currently
    optionsSendMethod.push({ label: "SMS", value: "SMS", key: uuidv4() });
    */
  }

  const handleMessageOptionChange = (id: number, newValue: string) => {
    setErrorOptionValue("");
    const newUserEnteredOptions = [...userEnteredOptions];
    const option = newUserEnteredOptions.find((a) => a.id === id);
    if (option) {
      option.value = newValue;
    }
    setUserEnteredOptions(newUserEnteredOptions);
  };

  const checkIfIdExists = (newId: number) => {
    const option = userEnteredOptions.find((a) => a.id === newId);
    if (option) {
      return true;
    }
    return false;
  };

  const getNewId = () => {
    let newId = userEnteredOptions.length + 1;
    if (!checkIfIdExists(newId)) {
      return newId;
    }
    for (let i = 1; i <= 50; i += 1) {
      if (!checkIfIdExists(i)) {
        newId = i;
        break;
      }
    }
    return newId;
  };

  const onClickFocus = (id: number) => {
    refsByKey[id]?.focus();
    refsByKey[id]?.select();
  };

  useEffect(() => {
    if (updatingRefId !== 0) {
      onClickFocus(updatingRefId);
      setUpdatingRefId(0);
    }
  }, [updatingRefId]);

  const handleAddNewOption = () => {
    if (userEnteredOptions.length > 50) {
      // Limit the number of options at 50
      return;
    }
    const newId = getNewId();

    setUserEnteredOptions([
      ...userEnteredOptions,
      {
        id: newId,
        value: `Option ${newId}`,
        name: `option-${newId}`,
        key: uuidv4(),
      },
    ]);
    // Set the ID to use in focus
    setUpdatingRefId(newId);
  };

  const handleDeleteOption = (optionId: number) => {
    setErrorOptionValue("");
    setUserEnteredOptions(userEnteredOptions.filter((a) => a.id !== optionId));
  };

  const sendMessageApi = async (dataToCreateMessage: ICreateMessageProps) => {
    setIsMessageLoading(true);
    messageService
      .createMessage(dataToCreateMessage)
      .then((addedSuccessfully) => {
        const { status } = addedSuccessfully;

        if (status === 200) {
          setNotification(true, "Message successfully added", "success");
        } else {
          setNotification(true, "Error adding message", "error");
        }
        onClose();
      });
    setIsMessageLoading(true);
  };

  const handleCreateMessage = async () => {
    mixpanelActions.track("User Action: Save Message");
    let error = false;

    if (messageTitle === "") {
      setErrorTitle("Please enter a heading.");
      error = true;
    }

    if (messageType === "Options") {
      // Check for duplicate values in option values
      const valueArr = userEnteredOptions.map(function getValue(item) {
        return item.value;
      });
      const duplicateItems = valueArr.filter(
        (item, index) => valueArr.indexOf(item) !== index
      );
      if (duplicateItems.length > 0) {
        setErrorOptionValue(
          `Option value "${duplicateItems}" entered multiple times`
        );
        error = true;
      }
    }

    if (error) {
      // Error Message will be displayed
      return;
    }

    if (messageType === "Options") {
      // Create the options array with just the text
      const messageOptions = [];
      for (let i = 0; i < userEnteredOptions.length; i += 1) {
        messageOptions.push({
          Text: `${userEnteredOptions[i].value}`,
          Index: i,
        });
      }
      const dataToCreateMessage: ICreateMessageProps = {
        messageBody: messageContent,
        messageTitle,
        timeFrame: responseTimeFrame,
        responseType: messageType,
        patientId,
        sentProvider: sendMethod,
        responseOptions: messageOptions,
      };

      sendMessageApi(dataToCreateMessage);
    } else if (messageType === "NoAnswer") {
      // No response time for NoAnswer
      const dataToCreateMessage: ICreateMessageProps = {
        messageBody: messageContent,
        messageTitle,
        responseType: messageType,
        patientId,
        sentProvider: sendMethod,
      };

      sendMessageApi(dataToCreateMessage);
    } else {
      const dataToCreateMessage: ICreateMessageProps = {
        messageBody: messageContent,
        messageTitle,
        timeFrame: responseTimeFrame,
        responseType: messageType,
        patientId,
        sentProvider: sendMethod,
      };

      sendMessageApi(dataToCreateMessage);
    }
  };

  const handleCancelClick = () => {
    onClose();
  };

  return optionsSendMethod.length > 0 ? (
    <Container>
      <Title>New message</Title>
      <FirstRowContainer>
        <TextFieldContainer>
          <TextField
            label=""
            name="title"
            value={messageTitle}
            placeholder="Message heading"
            errorMessage={errorTitle}
            onChange={(event) => handleTitleChange(event.target.value)}
          />
        </TextFieldContainer>
        <DropdownContainer>
          <SelectDropdown
            options={optionsMessageType}
            value={messageType}
            width={150}
            onValueChange={(value: string | string[]) => {
              setMessageType(value.toString());
            }}
          />
        </DropdownContainer>
      </FirstRowContainer>
      <TextAreaContainer>
        <TextArea
          cols={1000}
          rows={5}
          value={messageContent}
          placeholder="Message body"
          onChange={(event) => handleInputChange(event.target.value)}
        />
      </TextAreaContainer>
      {messageType === "Options" && (
        <OptionsContainer>
          <OptionsContainerScroll>
            {userEnteredOptions.map((option) => (
              <OptionsRowContainer key={option.key}>
                <OptionLabel
                  htmlFor={option.name}
                  onClick={() => onClickFocus(option.id)}
                >
                  <SwitchOff width={16} height={16} />
                </OptionLabel>
                <TextField
                  label=""
                  value={option.value}
                  name={option.name}
                  key={option.key}
                  className={optionTextFieldStyles()}
                  type="text"
                  inputPadding="5px 10px"
                  innerRef={(element: HTMLInputElement) =>
                    setRef(element, option.id)
                  }
                  onChange={(event) =>
                    handleMessageOptionChange(option.id, event.target.value)
                  }
                />
                {userEnteredOptions.length > 1 && (
                  <DeleteButton onClick={() => handleDeleteOption(option.id)}>
                    <Close />
                  </DeleteButton>
                )}
              </OptionsRowContainer>
            ))}
          </OptionsContainerScroll>
          <NewOptionButton onClick={handleAddNewOption}>
            <>
              <SwitchOff width={16} height={16} />
              <p>Add another option</p>
            </>
          </NewOptionButton>
          {errorOptionValue !== "" && (
            <ErrorMessage>{errorOptionValue}</ErrorMessage>
          )}
        </OptionsContainer>
      )}
      <hr
        style={{
          marginLeft: "45px",
          marginRight: "35px",
          background: "#E6E6E6",
          height: "1px",
          border: "none",
          marginTop: "8px",
        }}
      />
      <BottomDropdownContainer>
        {messageType === "NoAnswer" ? (
          <SelectDropdown options={optionsTimeFrame} disabled width={250} />
        ) : (
          <SelectDropdown
            options={optionsTimeFrame}
            value={responseTimeFrame}
            width={250}
            onValueChange={(value: string | string[]) => {
              setResponseTimeFrame(value.toString());
            }}
          />
        )}
        <SendMethodDropdown>
          <SelectDropdown
            options={optionsSendMethod}
            value={sendMethod}
            width={100}
            onValueChange={(value: string | string[]) => {
              setSendMethod(value.toString());
            }}
          />
        </SendMethodDropdown>
      </BottomDropdownContainer>
      <SubmitContainer>
        <Button
          isLoading={isMessageLoading}
          label="Send"
          type="button"
          onClick={handleCreateMessage}
        />
        <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
      </SubmitContainer>
    </Container>
  ) : (
    <Container>
      <Title>Message cannot be created</Title>
      <ContentContainer>
        Messages cannot be created until a patient has a registered device
      </ContentContainer>
      <SubmitContainer>
        <Button label="OK" type="button" onClick={handleCancelClick} />
      </SubmitContainer>
    </Container>
  );
}
