import { useQuery } from "@tanstack/react-query";

import { workflowsService } from "../../services/worflowsService";

export function useWorkflows(hospitalGroupId?: number) {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery(
    [`workflows-list-${hospitalGroupId}`],
    async () => {
      const { data } = await workflowsService.getAllWorflows(hospitalGroupId);

      return data;
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    workflows: data?.workflows,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}

export function useWorkflowsSelectOptions() {
  const { data, isError, isFetching, refetch, isRefetching } = useQuery(
    [`workflows-select-options`],
    async () => {
      const ruleTypesServerResponse =
        await workflowsService.getWorflowsRuleTypes();

      const ruleConditionTriggersServerResponse =
        await workflowsService.getWorflowsRuleConditionTriggers();

      const ruleConditionComparatorsServerResponse =
        await workflowsService.getWorflowsRuleConditionComparators();

      const responseTypesServerResponse =
        await workflowsService.getWorflowsResponseTypes();

      const responseTimesServerResponse =
        await workflowsService.getWorflowsResponseTimes();

      const actionTypesServerResponse =
        await workflowsService.getWorflowsActionTypes();

      return {
        ruleTypes: ruleTypesServerResponse.data,
        ruleConditionTriggers: ruleConditionTriggersServerResponse.data,
        ruleConditionComparators: ruleConditionComparatorsServerResponse.data,
        responseTypes: responseTypesServerResponse.data,
        responseTimes: responseTimesServerResponse.data,
        actionTypes: actionTypesServerResponse.data,
      };
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    ruleTypes: data?.ruleTypes,
    ruleConditionTriggers: data?.ruleConditionTriggers,
    ruleConditionComparators: data?.ruleConditionComparators,
    responseTypes: data?.responseTypes,
    responseTimes: data?.responseTimes,
    actionTypes: data?.actionTypes,
    isFetching,
    isError,
    refetch,
    isRefetching,
  };
}
