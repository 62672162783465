import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  width: "100%",
  minHeight: "100px",

  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",

  "@sm": {
    marginTop: "20px",
    minHeight: "10px",
    flexDirection: "column",
    height: "100%",
    alignItems: "flex-start",
  },
});

export const MainValuesContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  minWidth: "363px",

  "@media only screen and (max-width: 1250px) and (min-width: 768px)": {
    minWidth: "200px",
  },

  "@sm": {
    minWidth: "0px",
    width: "100%",
    margin: "38px 0 0 22px",
    justifyContent: "space-evenly",
  },
});

export const Divider = styled("div", {
  width: "88px",
  height: "1px",

  transform: "rotate(90deg)",
  flexShrink: 0,
  borderRadius: "0.5px",
  background: "#E6E6E6",

  "@sm": {
    marginTop: "25px",
    width: "95%",
    marginLeft: "22px",
    transform: "rotate(0deg)",
  },
});

export const ChartLegendContainer = styled("div", {
  "@sm": {
    paddingLeft: "64px",
  },
});

export const MainValueContainer = styled("div", {
  minWidth: "73px",

  display: "flex",
  flexDirection: "column",
  marginLeft: "48px",

  "@media only screen and (max-width: 1250px) and (min-width: 768px)": {
    marginLeft: "5px",
  },

  "@sm": {
    marginLeft: "0px",
    alignItems: "flex-start",
  },
});

export const MainValueContainerFirst = styled("div", {
  minWidth: "73px",

  display: "flex",
  flexDirection: "column",
  marginLeft: "48px",

  "@sm": {
    marginLeft: "0px",
    alignItems: "flex-start",
  },
});

export const NumberLabel = styled("div", {
  color: "#666",
  fontFamily: "$openSans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "22px",

  variants: {
    disabled: {
      true: {
        opacity: 0.3,
      },
    },
  },
});

export const TextLabel = styled("div", {
  color: "#666",
  fontFamily: "$openSans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "16px",

  marginTop: "2px",

  variants: {
    disabled: {
      true: {
        opacity: 0.3,
      },
    },
  },
});

export const DateLabel = styled("div", {
  color: "#939393",
  fontFamily: "$openSans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "16px",

  marginTop: "5px",

  variants: {
    disabled: {
      true: {
        opacity: 0.3,
      },
    },
  },
});
