import { css, styled } from "@patientmpower/spiro";

export const ModalContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "24px 26px",

  "@sm": {
    height: "350px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
  },
});

export const ModalTitle = styled("p", {
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "600",
  color: "#212121",

  "@sm": {
    marginBottom: "20px",
  },
});

export const TypeContainer = styled("div", {
  width: "100%",
  height: "134px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "20px 20px 20px 28px",
  borderRadius: "8px",
  background: "rgba(248, 248, 248, 0.8)",
  margin: "16px 0 8px 0",

  "@sm": {
    padding: "10px 10px 10px 14px",
    margin: "4px 0 2px 0",
    height: "120px",
    justifyContent: "center",
  },
});

export const TypeSelectContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  marginBottom: "20px",

  "@sm": {
    paddingLeft: "10px",
  },
});

export const TypeTitle = styled("p", {
  color: "#757575",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "22px",
  marginRight: "28px",
});

export const NoteContainer = styled("div", {
  width: "100%",
  height: "96px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "20px 20px 20px 28px",
  borderRadius: "8px",
  background: "rgba(233, 230, 244, 0.6)",
  marginBottom: "32px",

  "@sm": {
    height: "110px",
  },
});

export const NoteTitle = styled("p", {
  color: "#5D508A",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "22px",
});

export const NoteSubtitle = styled("p", {
  color: "#5D508A",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
});

export const CodeContainer = styled("div", {
  width: "100%",
  height: "150px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "20px 20px 20px 28px",
  borderRadius: "8px",
  background: "rgba(248, 248, 248, 0.8)",
  margin: "16px 0 32px 0",
});

export const CodeTitle = styled("p", {
  color: "#757575",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "22px",
});

export const CodeSubtitle = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  color: "#666666",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "22px",
  margin: "8px 0 0 8px",
});

export const ResendCodeHyperlink = styled("span", {
  color: "#7562A8",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "22px",
  cursor: "pointer",
  marginLeft: "5px",
});

export const ButtonContainer = styled("div", {
  height: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: "10px",
});

export const CancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "5px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },

  variants: {
    isModal: {
      true: {
        padding: "0 $10",
      },
    },
  },
});

export const InputClass = css({
  width: "76%",

  "@sm": {
    width: "100%",
  },
});

export const reactPhoneInputCss = css({
  width: "348px !important",
  height: "42px !important",
  zIndex: "2000",

  input: {
    border: "2px solid $gray-50 !important",
    borderRadius: "6px",
    paddingLeft: "11px",
    paddingRight: "11px",

    color: "$gray-90 !important",
    fontFamily: "$openSans !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    lineHeight: "22px !important",

    "&:hover": {
      borderColor: "$primary-30 !important",
    },
  },

  ".form-control": {
    width: "100% !important",
    height: "100% !important",

    "&:focus": {
      boxShadow: "none !important",
    },
  },

  ".selected-flag": {
    "&::before": {
      borderColor: "transparent !important",
      boxShadow: "none !important",
    },
  },

  ".flag-dropdown": {
    ".country-list": {
      width: "348px !important",

      ".search": {
        ".search-box": {
          width: "318px",
          paddingTop: "5px",

          "&::placeholder": {
            color: "$gray-80",
          },
        },
      },

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
});
