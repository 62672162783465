import { styled } from "@patientmpower/spiro";

export const ChangeContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "24px 15px 12px",

  p: {
    fontSize: "12px",
    fontFamily: "$openSans",
    fontWeight: "400",
    lineHeight: "16px",
    color: "$gray-90",
  },
});

export const PropTitle = styled("p", {
  fontSize: "12px",
  fontFamily: "$openSans",
  fontWeight: "600 !important",
  lineHeight: "16px",
  color: "$gray-90",
});

export const Content = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "6px",
});

export const NoteContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "20px 20px 20px 28px",
  borderRadius: "8px",
  background: "rgba(233, 230, 244, 0.6)",
  marginTop: "32px",
  gap: "4px",

  "@sm": {
    height: "110px",
  },
});

export const NoteTitle = styled("p", {
  color: "#5D508A",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "22px",
});

export const NoteSubtitle = styled("p", {
  color: "#5D508A",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px",
});
