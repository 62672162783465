import { useDrag } from "react-dnd";

import { ResponseIcon } from "../../../../../../assets/icons/ResponseIcon";
import { RuleIcon } from "../../../../../../assets/icons/RuleIcon";
import {
  DraggableNodeContainer,
  IconContainer,
  NodeLibraryContainer,
} from "./NodesLibrary.styles";

function DraggableNode({ type, label }: any) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "NODE",
    item: { type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <DraggableNodeContainer ref={drag} isDragging={isDragging}>
      <IconContainer>
        {type === "ruleNode" ? (
          <RuleIcon />
        ) : (
          <ResponseIcon style={{ marginTop: "1px" }} />
        )}
      </IconContainer>
      {label}
    </DraggableNodeContainer>
  );
}

export function NodeLibrary() {
  return (
    <NodeLibraryContainer>
      <DraggableNode type="ruleNode" label="Rule" />
      <DraggableNode type="responseNode" label="Response" />

      <p>Drag and drop the items above to build your workflow</p>
    </NodeLibraryContainer>
  );
}
