import { useEffect, useState } from "react";

const DEFAULT_SCALE = 1;
const ACTIVE_SCALE = 1.5;

export function SquareDot(props: any) {
  const { cx, cy, fill, legend } = props;

  const [active, setActive] = useState(false);
  const [scale, setScale] = useState(DEFAULT_SCALE);

  useEffect(() => {
    if (!legend) setScale(active ? ACTIVE_SCALE : DEFAULT_SCALE);
  }, [active]);

  return (
    <svg
      x={cx && cx - 8 * scale}
      y={cy && cy - 8 * scale}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      cursor="pointer"
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <rect
        x="5.20001"
        y="5.20001"
        width="6"
        height="6"
        fill={fill}
        transform={`scale(${scale})`}
      />
    </svg>
  );
}
