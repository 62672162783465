export function SwitchOn(props: any) {
  const { width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.003" cy="7.9991" r="4.30769" fill="#7562A8" />
      <circle cx="8" cy="8" r="7.4" stroke="#9182C6" strokeWidth="1.2" />
    </svg>
  );
}
