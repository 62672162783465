import { IOption } from "@patientmpower/spiro";
import { Tooltip } from "antd";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";

import { IArticle } from "../../../../../../@types/Articles";
import { CircleChecked } from "../../../../../../assets/icons/CircleChecked";
import { CircleUnchecked } from "../../../../../../assets/icons/CircleUncheked";
import { getLongFormattedDate } from "../../../../../../utils/dateFormatter";
import {
  ContentContainer,
  ContentRow,
  ContentSubRow,
  DescriptionText,
  RulesButton,
} from "./SettingsSection.styles";

interface ISettingsSectionProps {
  formik: FormikProps<IArticle>;
  wardOptions: IOption[];
}

export function SettingsSection({
  formik,
  wardOptions,
}: ISettingsSectionProps) {
  const [wards, setWards] = useState<string>();
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    const selectedWards: string[] = [];

    wardOptions.forEach((ward) => {
      if (formik.values.wards?.includes(ward.value)) {
        selectedWards.push(ward.label);
      }
    });

    setWards(selectedWards.join(", "));
  }, [formik, wardOptions]);

  useEffect(() => {
    setIsPublished(formik.values.published);
  }, []);

  return (
    <ContentContainer>
      <ContentRow>
        <ContentSubRow>
          <p>No of reads</p>
          <DescriptionText>{formik.values.numberOfReads}</DescriptionText>
        </ContentSubRow>
        <ContentSubRow>
          <p>Author</p>
          <DescriptionText>
            {formik.values.createdBy ? formik.values.createdBy : "-"}
          </DescriptionText>
        </ContentSubRow>
      </ContentRow>
      <ContentRow>
        <ContentSubRow>
          <p>Last edited</p>
          <DescriptionText>
            {formik.values.lastEdited
              ? getLongFormattedDate(formik.values.lastEdited)
              : "-"}
          </DescriptionText>
        </ContentSubRow>
        <ContentSubRow>
          <p>Wards</p>
          <DescriptionText>
            {wards === (undefined || "") ? "-" : wards}
          </DescriptionText>
        </ContentSubRow>
      </ContentRow>
      <ContentRow>
        <ContentSubRow>
          <p>Rules</p>
          <Tooltip
            title="Feature disabled. Coming soon..."
            arrow={false}
            placement="top"
            zIndex={3000}
            overlayInnerStyle={{
              padding: "6px 12px",
              minHeight: "40px",
              width: "max-content",
              borderRadius: "4px !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            <RulesButton>Add rules (optional)</RulesButton>
          </Tooltip>
        </ContentSubRow>
        <ContentSubRow>
          <p>Published</p>
          <DescriptionText>
            {isPublished ? <CircleChecked /> : <CircleUnchecked />}
          </DescriptionText>
        </ContentSubRow>
      </ContentRow>
    </ContentContainer>
  );
}
