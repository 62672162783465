import { css, styled } from "@patientmpower/spiro";

export const AddDeviceModalContainer = styled("div", {
  width: "611px",
  height: "325px",
  display: "flex",
  flexDirection: "column",
  padding: "24px",

  "@media screen and (max-width: 700px)": {
    width: "calc(100vw - 80px)",
    overflow: "scroll",
  },
});

export const AddDeviceTitle = styled("div", {
  color: "#212121",
  fontFamily: "$openSans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "22px",

  paddingLeft: "16px",
  marginBottom: "15px",
});

export const InputsContainer = styled("div", {
  height: "100%",
  width: "100%",
  borderRadius: "8px",
  background: "rgba(248, 248, 248, 0.80)",
  marginBottom: "28px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "20px",
  gap: "30px",

  ".ant-select": {
    height: "42px !important",
  },

  fontFamily: "$openSans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "22px",
});

export const InputRow = styled("div", {
  width: "100%",
  height: "42px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",

  p: {
    width: "150px",
    color: "#757575",
  },

  div: {
    ".ant-select-selection-placeholder": {
      fontWeight: "400 !important",
    },

    ".ant-select-selector": {
      borderWidth: "2px !important",
    },

    ".ant-select-arrow": {
      top: "66% !important",
    },
  },
});

export const SubmitContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
});

export const AddDeviceCancelButton = styled("button", {
  height: "$13",
  padding: "0 $13",
  marginLeft: "5px",

  borderRadius: "20px",
  border: "2px solid $white",

  backgroundColor: "$white",

  gap: "$4",

  display: "flex",
  alignItems: "center",

  color: "$black",
  fontWeight: 600,
  lineHeight: "22px",
  fontSize: "$text-lg",
  fontFamily: "$openSans",

  cursor: "pointer",

  "&:focus": {
    outline: "none",
    border: "2px solid $primary-30",
  },

  "@sm": {
    width: "80px",
    height: "26px",
    fontSize: "12px",
    marginLeft: "40px",
    paddingLeft: "19px",
  },
});

export const ErrorMessage = styled("p", {
  fontSize: "12px !important",
  fontWeight: "600 !important",

  lineHeight: "16px !important",

  color: "$error-light !important",

  width: "100% !important",
  position: "absolute",
  top: "49px",
  right: "-157px",
});

// CSS

export const inputCss = css({
  width: "225px",
  height: "42px",

  span: {
    margin: "0px",
  },

  p: {
    width: "100% !important",
  },

  input: {
    border: "2px solid $gray-50",
    borderRadius: "6px",
    height: "36px",

    paddingLeft: "11px",
    paddingRight: "11px",
    fontFamily: "$openSans",
    fontSize: "14px",
    fontWeight: "400",

    "&:hover": {
      border: "2px solid $primary-30",
    },
  },
});
