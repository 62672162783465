import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ISurvey } from "../../../@types/Surveys";
import { BlackPlus } from "../../../assets/icons/BlackPlus";
import { Pencil } from "../../../assets/icons/Pencil";
import { CustomIconButton } from "../../../components/CustomIconButton";
import { useSurveys } from "../../../hooks/queries/surveys";
import { useModal } from "../../../hooks/useModal";
import { PageLayout } from "../../../layout/components/PageLayout";
import { AssignToHospitalModal } from "./Components/AssignToHospitalModal";
import { SurveyListTable } from "./Components/SurveyListTable/SurveyListTable";
import {
  SurveysListTableContainer,
  AddSurveyButton,
} from "./SurveyListPage.styles";

export function SurveysPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { surveys, isRefetching, isFetching, refetch } = useSurveys();
  const handleAddSurveyClick = () => {
    navigate("/surveys/new");
  };

  const handleAnswerClick = () => {
    navigate("/surveys/answer");
  };

  const handleEditClick = (surveyId: string) => {
    navigate("/surveys/new", {
      state: surveyId,
    });
  };

  const handleAssignQuestioToHospitalClick = (survey: ISurvey) => {
    openModal(
      <AssignToHospitalModal
        refetch={refetch}
        onClose={closeModal}
        survey={survey}
      />,
      {
        width: "60vw",
        maxHeight: "95vh",
      }
    );
  };

  const parseDataToSurveyTable = (data: any) => {
    const formatttedDataTable: any[] = [];
    data.map((survey: ISurvey, index: number) =>
      formatttedDataTable.push({
        name: survey.name,
        createdBy: survey.createdBy,
        assignToHospital: (
          <div>
            <CustomIconButton
              key={index}
              onClick={() => {
                handleAssignQuestioToHospitalClick(survey);
              }}
            >
              <BlackPlus />
            </CustomIconButton>
          </div>
        ),
        edit: (
          <CustomIconButton
            key={index}
            onClick={() => {
              handleEditClick(survey.id ?? "");
            }}
          >
            <Pencil />
          </CustomIconButton>
        ),
      })
    );
    return formatttedDataTable;
  };

  useEffect(() => {
    if (!isFetching && !isRefetching && surveys) {
      const formattedData = parseDataToSurveyTable(surveys);
      setTableData(formattedData);
      setLoading(false);
    }
  }, [surveys]);

  return (
    <PageLayout>
      <SurveysListTableContainer>
        <AddSurveyButton onClick={handleAddSurveyClick}>
          Add survey
        </AddSurveyButton>
        <AddSurveyButton style={{ right: "100px" }} onClick={handleAnswerClick}>
          Answer
        </AddSurveyButton>
        <SurveyListTable isLoading={loading} content={tableData} />
      </SurveysListTableContainer>
    </PageLayout>
  );
}
