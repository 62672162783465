import { MeasurementTypes } from "../@types/Measurements";
import { SourceLegend } from "../@types/Source";
import { sourceLegends } from "../constants/sourceConstants";

export function getSourceLegends(
  measurementType: MeasurementTypes,
  source: string
): SourceLegend {
  const sourceLegend =
    sourceLegends[source.includes("archived") ? "archived" : source];
  let { color } = sourceLegend || {};

  if (source === "a&d_ua-651ble" || source === "bodytrace-bp") {
    if (measurementType === "bp_systolic") {
      color = sourceLegend.systolicColor;
    } else if (measurementType === "bp_diastolic") {
      color = sourceLegend.diastolicColor;
    }
  }

  return {
    ...sourceLegend,
    color,
  };
}
