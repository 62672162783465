export function TableToggle({ ...rest }) {
  return (
    <svg
      {...rest}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 0.75H1.91667C1.275 0.75 0.75 1.275 0.75 1.91667V10.0833C0.75 10.725 1.275 11.25 1.91667 11.25H10.6667C11.3083 11.25 11.8333 10.725 11.8333 10.0833V1.91667C11.8333 1.275 11.3083 0.75 10.6667 0.75ZM10.6667 1.91667V3.66667H1.91667V1.91667H10.6667ZM4.83333 10.0833H7.75V4.83333H4.83333V10.0833ZM1.91667 4.83333H3.66667V10.0833H1.91667V4.83333ZM8.91667 4.83333V10.0833H10.6667V4.83333H8.91667Z"
        fill="currentColor"
      />
    </svg>
  );
}
