import { PageLayout } from "../../../layout/components/PageLayout";
import { SurveyBuilder } from "./Components/SurveyBuilder";
import { SurveyContainer } from "./CreateSurveyPage.styles";

export function CreateSurveyPage() {
  return (
    <PageLayout>
      <SurveyContainer>
        <SurveyBuilder />
      </SurveyContainer>
    </PageLayout>
  );
}
