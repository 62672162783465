/* eslint-disable no-debugger */
import axios, { AxiosError, AxiosResponse } from "axios";

import { IPaginatedResponse } from "../@types/PaginatedResponse";
import {
  IAddPatientRequest,
  IAddPatientResponse,
  IArchivePatientRequest,
  IPatient,
  IPatientDetailsDropdownItems,
  IPatientListVariants,
} from "../@types/Patient";
import { IPatchOperation } from "../@types/Request";
import { patientListVariants } from "../constants/patientListVariants";
import { api } from "./api";

export const patientService = {
  async getPatients({
    variant,
  }: IPatientListVariants): Promise<
    AxiosResponse<IPaginatedResponse<IPatient>>
  > {
    const url = "/Legacy/Patients";
    const urlParamsParsed = new URLSearchParams();
    if (variant === patientListVariants.FLAGGED) {
      urlParamsParsed.append("selector", patientListVariants.FLAGGED);
    } else if (variant === patientListVariants.ALERTS) {
      urlParamsParsed.append("selector", patientListVariants.ALERTS);
    } else if (variant === patientListVariants.ARCHIVED) {
      urlParamsParsed.append("selector", patientListVariants.ARCHIVED);
    }

    const patientsResponse = await api.get<IPaginatedResponse<any>>(url, {
      params: urlParamsParsed,
    });

    return patientsResponse;
  },

  async getPatientById(id: string): Promise<AxiosResponse> {
    const url = `/Legacy/Patients/${id}`;
    const patient = await api.get(url);
    return patient;
  },

  async flagPatient({
    id: patientId,
    flagged,
  }: {
    id: string | number;
    flagged: boolean;
  }): Promise<AxiosResponse> {
    const url = `/Portal/Patients/${patientId}/Flag?flagged=${flagged}`;
    const urlParamsParsed = new URLSearchParams();

    return api.post(url, { params: urlParamsParsed });
  },

  async getPatientMonitoringInfo(id: string): Promise<AxiosResponse> {
    const url = `/Legacy/MonitoringPortal/Patients/${id}`;
    const patient = await api.get(url);
    return patient;
  },

  async getPatientAlerts(
    id: string | number,
    page?: number,
    limit?: number,
    select?: string,
    orderby?: string
  ): Promise<AxiosResponse> {
    const url = "/Legacy/MeasurementAlertTrigger";
    const urlParamsParsed = new URLSearchParams();

    if (id) {
      urlParamsParsed.append("id", id.toString());
    }
    if (page) {
      urlParamsParsed.append("page", page.toString());
    }
    if (limit) {
      urlParamsParsed.append("limit", limit.toString());
    }
    if (select) {
      urlParamsParsed.append("select", select);
    }
    if (orderby) {
      urlParamsParsed.append("orderby", orderby);
    }

    const patientsResponse = await api.get(url, {
      params: urlParamsParsed,
    });

    return patientsResponse;
  },

  async resolveTriggerAlert(
    idList: string[],
    patientId: number,
    outcome?: any,
    note?: string
  ): Promise<AxiosResponse> {
    const url = "/Legacy/TriggeredMeasurementAlert/ResolveAlerts";

    const patientsResponse = await api.post(url, {
      triggerId: idList,
      outcome,
      note,
      patientId,
    });

    return patientsResponse;
  },

  async getPatientPdf(
    patientId: number,
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse> {
    const url = "/Portal/Patients/GetPatientReport";

    const result = await api.post(url, {
      patientId,
      startDate,
      endDate,
    });

    return result;
  },

  async updatePatientDetailsAndCardsOrder(
    patientId: number,
    operations: IPatchOperation[],
    cardsOrderJson: string
  ): Promise<AxiosResponse> {
    const url = "/Portal/Patients/UpdatePatientDetailsAndCardsOrder";

    const result = await api
      .post(url, {
        patientId,
        operations,
        cardsOrderJson,
      })
      .catch((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (result) {
      return result;
    }

    throw Error;
  },

  async getPatientDetailsDropDownItems(): Promise<
    AxiosResponse<IPatientDetailsDropdownItems>
  > {
    const url = "/Portal/Patients/GetPatientDetailsDropdownItems";

    const result = await api.get<IPatientDetailsDropdownItems>(url);

    return result;
  },

  async addPatient(
    patient: IAddPatientRequest
  ): Promise<AxiosResponse<IAddPatientResponse>> {
    const url = "/Portal/Patients/AddPatient";

    const serverResponse = await api
      .post<IAddPatientResponse>(url, patient)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async validatePhoneNumber(
    phoneNumber: string
  ): Promise<AxiosResponse<string>> {
    const url = "/Portal/Patients/ValidatePhoneNumber";

    const serverResponse = await api
      .post<string>(url, phoneNumber)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async confirmValidatePhoneNumber(
    otpCode: string
  ): Promise<AxiosResponse<string>> {
    const url = "/Portal/Patients/ConfirmNumberValidation";

    const serverResponse = await api
      .post<string>(url, otpCode)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async archivePatient(
    request: IArchivePatientRequest
  ): Promise<AxiosResponse<string>> {
    const url = "/Portal/Patients/ChangeArchiveStatus";

    const serverResponse = await api
      .post<string>(url, request)
      .then((response: AxiosResponse | AxiosError) => {
        if (axios.isAxiosError(response)) {
          return response.response;
        }
        return response;
      });

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
