import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  display: "flex",

  height: "100%",

  "@media only screen and (max-width: 1100px) and (min-width: 900px)": {
    overflowX: "scroll",
    width: "900px",
  },

  "@media only screen and (max-width: 899px)": {
    width: "calc(100vw - 70px)",
    overflowX: "scroll",
  },
});

export const MeasurementContainer = styled("div", {
  height: "100%",
  width: "100%",

  padding: "24px 0px 0 0",
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",

  color: "$black",
});

export const Subtitle = styled("h2", {
  marginTop: "4px",
  marginBottom: "12px",

  color: "$gray-80",

  fontWeight: 400,
  fontSize: "12px",

  lineHeight: "16px",
});

export const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  marginLeft: "40px",
  marginBottom: "28px",

  "&:last-child": {
    marginBottom: "28px",
  },
});

export const SpirometryListTitle = styled("div", {
  lineHeight: "16px",
  textAlign: "end",

  marginRight: "8px",
  marginBottom: "8px",
  marginTop: "60px",

  fontWeight: 600,
  fontSize: "$text-md",

  color: "$gray-90",

  display: "flex",
  justifyContent: "flex-end",

  p: {
    paddingLeft: "11px",
  },

  "@sm": {
    width: "100%",
    justifyContent: "flex-start",
    padding: "0 16px 0 1px",
  },
});
