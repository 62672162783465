import { styled, Tile } from "@patientmpower/spiro";

export const StyledSubtitle = styled("p", {
  color: "$gray-90",
  marginTop: "8px",

  fontSize: "$text-md",
  lineHeight: "16px",

  fontFamily: "$openSans",
});

export const EmptyStateOverlay = styled("div", {
  // zIndex: 200,
  opacity: 0.64,
  position: "absolute",

  width: "88%",
  height: "70%",

  backgroundColor: "$white",
});

export const StyledTile = styled(Tile, {
  width: "376px",
  height: "340px",

  position: "relative",

  padding: "28px 32px 38px 32px",

  backgroundColor: "$white",
  cursor: "pointer",

  "@md": {
    width: "302px",
    height: "280px",
  },

  "@sm": {
    borderRadius: "$4",
    border: "1px solid $gray-40",
  },

  "&:hover": {
    boxShadow: "0 0 4px 1px rgba(0, 0, 0, 0.08)",
  },
});

export const ChartTitle = styled("h3", {
  fontSize: "$text-lg",
  fontWeight: 400,

  marginBottom: "2px",

  lineHeight: "22px",
  color: "$black",
});
