import { Table } from "@patientmpower/spiro";

import { IArticlesTableContent } from "../../../../@types/Articles";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { articlesListColumns } from "./articlesListColumns";

type ArticlesTableProps = {
  content: IArticlesTableContent[];
  isLoading?: boolean;
  onRowClick(rowContent: IArticlesTableContent): void;
};

export function ArticlesTable({
  content,
  isLoading,
  onRowClick,
}: ArticlesTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={8} rowsNumber={6} />;
  }

  return (
    <Table
      data={content}
      emptyStateText="No articles found."
      columns={articlesListColumns}
      showMaxContent
      onRowClick={onRowClick}
    />
  );
}

ArticlesTable.defaultProps = {
  isLoading: false,
};
