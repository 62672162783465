import { AxiosResponse } from "axios";

import {
  IAddArticle,
  IArticle,
  IArticleImage,
  IArticlesTableResponse,
  IHospitalTagsResponse,
  IRelatedArticles,
} from "../@types/Articles";
import { api } from "./api";

export const articlesSerivce = {
  async gethospitalArticles(
    hospitalGroupId?: number
  ): Promise<AxiosResponse<IArticlesTableResponse[]>> {
    let url = "";

    if (hospitalGroupId) {
      url = `/Shared/Articles/GetUserArticles/${hospitalGroupId}`;
    } else {
      url = "/Shared/Articles/GetUserArticles";
    }

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getHospitalTags(): Promise<AxiosResponse<IHospitalTagsResponse[]>> {
    const url = `/Shared/Articles/HospitalTags`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getHospitalGroupTags(
    hospitalGroupId: number
  ): Promise<AxiosResponse<IHospitalTagsResponse[]>> {
    const url = `/Shared/Articles/HospitalTagsByHospitalGroupId/${hospitalGroupId}`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getById(id: string): Promise<AxiosResponse<IArticle>> {
    const url = `/Shared/Articles/${id}`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async addArticle(request: IAddArticle): Promise<AxiosResponse> {
    const url = `/Shared/Articles`;

    const serverResponse = await api.post(url, request);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async updateArticle(
    articleId: string,
    request: IAddArticle
  ): Promise<AxiosResponse> {
    const url = `/Shared/Articles/${articleId}`;

    const serverResponse = await api.put(url, request);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getRelatedArticles(
    id: string
  ): Promise<AxiosResponse<IRelatedArticles[]>> {
    const url = `/Shared/Articles/${id}/RelatedArticles`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getImageLibrary(): Promise<AxiosResponse<IArticleImage[]>> {
    const url = `/Shared/Articles/ImageLibrary`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async incrementArticleReads(articleId: string): Promise<AxiosResponse> {
    const url = `/Shared/Articles/${articleId}/Read`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },

  async getAllHospitalArticles(): Promise<AxiosResponse<IRelatedArticles[]>> {
    const url = `/Portal/Articles/GetAllHospitalArticles`;

    const serverResponse = await api.get(url);

    if (serverResponse !== undefined) {
      return serverResponse;
    }

    throw Error;
  },
};
