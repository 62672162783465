import { Dropdown, DropdownTextItem } from "@patientmpower/spiro";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import {
  ISectionProps,
  PatientNotesTableContent,
} from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { Dots } from "../../../../assets/icons/Dots";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { ActionContainer } from "../../../../components/TableActionDropdownButton/TableActionDropdownButton.styles";
import { ToastNotification } from "../../../../components/ToastNotification";
import { getPatientNotesData } from "../../../../hooks/queries/notes";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { getLongFormattedDate } from "../../../../utils/dateFormatter";
import { PatientNotesTable } from "../PatientNotesTable";
import { DeleteNoteModal } from "../PatientNotesTable/components/DeleteNoteModal";
import { EditNoteModal } from "../PatientNotesTable/components/EditNoteModal";
import { NewDataCOntainer, TitleContainer } from "./NotesSection.styles";

type Notification = {
  show: boolean;
  message: string;
  type: "success" | "info" | "warning" | "error";
  width?: string;
};

export function NotesSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { openModal, closeModal } = useModal();
  const { isMobile } = useIsMobile();
  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
  });
  const [highlightedRows, setHighlightedRows] = useState([false]);
  const [showNewData, setShowNewData] = useState<boolean>(false);

  const { patientNoteTable, isError, isFetching, refetch, isRefetching } =
    getPatientNotesData(patient.id);

  const handleRefreshTable = () => {
    refetch();
  };

  const handleEditNoteClose = () => {
    refetch();
    closeModal();
  };

  const handleDeleteNoteClose = () => {
    refetch();
    closeModal();
  };

  const handleChildNotification = (
    showNotification: boolean,
    notificationMessage: string,
    notificationType: "success" | "warning" | "error",
    notificationWidth?: string
  ) => {
    if (showNotification && notificationMessage && notificationType) {
      setNotification({
        show: showNotification,
        message: notificationMessage,
        type: notificationType,
        width: notificationWidth,
      });
      setTimeout(() => {
        setNotification({
          show: false,
          message: "notificationMessage",
          type: "error",
          width: undefined,
        });
      }, 3500);
    }
  };

  const handleEditNote = (noteId: number) => {
    openModal(
      <EditNoteModal
        noteId={noteId}
        setNotification={handleChildNotification}
        onClose={handleEditNoteClose}
      />,
      {
        width: "auto",
        height: "100%",
        showCloseButton: true,
      }
    );
  };

  const parsePatientNotesToTable = useMemo(() => {
    if (!patientNoteTable) return [];

    const options: any[] = ["Delete note", "Edit note"];
    const handleOnChangeDropdownVisibility = () => {
      setIsDropDownOpen(!isDropDownOpen);
    };
    const updateSelectedRow = (noteId: number, isOpen: boolean) => {
      if (isOpen) {
        const newHighlightedRows = patientNoteTable.map((obj) => {
          if (obj.id === noteId) {
            return true;
          }
          return false;
        });
        setHighlightedRows(newHighlightedRows);
      } else {
        const newHighlightedRows = patientNoteTable.map(() => {
          return false;
        });
        setHighlightedRows(newHighlightedRows);
      }
    };
    const handleDeleteNote = (noteId: number) => {
      openModal(
        <DeleteNoteModal
          noteId={noteId}
          setNotification={handleChildNotification}
          onClose={handleDeleteNoteClose}
        />,
        {
          width: "auto",
          height: "100%",
          showCloseButton: true,
        }
      );
    };

    return patientNoteTable.map((patientNote: PatientNotesTableContent) => {
      const initialValue = "";

      return {
        id: patientNote.id,
        notes: patientNote.notes,
        noteType:
          patientNote.noteType.charAt(0).toUpperCase() +
          patientNote.noteType.slice(1),
        time: getLongFormattedDate(patientNote.time),
        reviewedByEmail: patientNote.reviewedByEmail,
        action: (
          <div>
            {!patientNote.editable ? (
              <div>
                <Tooltip
                  title="You can only edit or delete your notes"
                  zIndex={3002}
                  color="#4B4B4B"
                  arrow={false}
                  overlayInnerStyle={{
                    minHeight: "40px",
                    minWidth: "220px",
                    borderRadius: "4px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ActionContainer className="actionContainer">
                    <CustomIconButton>
                      <Dots />
                    </CustomIconButton>
                  </ActionContainer>
                </Tooltip>
              </div>
            ) : (
              <ActionContainer className="actionContainer">
                <Dropdown
                  trigger={
                    <CustomIconButton>
                      <Dots />
                    </CustomIconButton>
                  }
                  value={initialValue}
                  onOpenChange={(val: boolean) => {
                    handleOnChangeDropdownVisibility();
                    updateSelectedRow(patientNote.id, val);
                  }}
                  onValueChange={(val: string) => {
                    if (val === "Delete note") {
                      handleDeleteNote(patientNote.id);
                    } else if (val === "Edit note") {
                      handleEditNote(patientNote.id);
                    }
                  }}
                >
                  {options.map((option) => (
                    <DropdownTextItem key={option} text={option} />
                  ))}
                </Dropdown>
              </ActionContainer>
            )}
          </div>
        ),
      } as PatientNotesTableContent;
    });
  }, [patientNoteTable]);

  useEffect(() => {
    if (
      collapsedSection.value === true &&
      !isFetching &&
      !isRefetching &&
      patientNoteTable.length > 0
    ) {
      const mostRecentNoteDate = dayjs(patientNoteTable[0].time);
      const collapsedDate = dayjs(collapsedSection.collapsedDate);

      if (mostRecentNoteDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  }, [patientNoteTable]);

  const handleCollapseSection = () => {
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
    updateColapsedSections("notesSection");
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Notes</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}
      <div
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <PatientNotesTable
          patientId={patient.id}
          isLoading={isFetching && !isRefetching}
          isError={isError}
          content={parsePatientNotesToTable}
          highlightedRows={highlightedRows}
          refreshTable={handleRefreshTable}
          handleEditNote={handleEditNote}
          isDropDownOpen={isDropDownOpen}
          showAddNoteButton={!collapsedSection.value}
        />
      </div>
      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
        />
      ) : null}
    </>
  );
}
