export function ArrowDown({ ...rest }) {
  return (
    <svg
      {...rest}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons/actions/arrow-down/grey-100">
        <path id="Path" d="M7 10L12 15L17 10H7Z" fill="#666666" />
      </g>
    </svg>
  );
}
