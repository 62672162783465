import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import {
  ISectionProps,
  PatientJournalsTableContent,
} from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { getPatientJournalsData } from "../../../../hooks/queries/journals";
import useIsMobile from "../../../../hooks/useIsMobile";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { PatientJournalsTable } from "../PatientJournalsTable";
import { NewDataCOntainer, TitleContainer } from "./JournalSections.styles";

export function JournalsSection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const { isMobile } = useIsMobile();
  const highlightedRows = [false];

  const [showNewData, setShowNewData] = useState<boolean>(false);

  const {
    patientJournalTable,
    journalsMaxDate,
    isError,
    isFetching,
    refetch,
    isRefetching,
  } = getPatientJournalsData(patient.id);

  const handleRefreshTable = () => {
    refetch();
  };

  const parsePatientJournalsToTable = useMemo(() => {
    if (!patientJournalTable) return [];

    return patientJournalTable.map(
      (patientJournal: PatientJournalsTableContent) => {
        return {
          id: patientJournal.id,
          notes: patientJournal.notes,
          date: patientJournal.date,
          tags: patientJournal.tags,
        };
      }
    );
  }, [patientJournalTable]);

  useEffect(() => {
    if (
      collapsedSection.value === true &&
      !isFetching &&
      !isRefetching &&
      patientJournalTable.length > 0
    ) {
      const mostRecentJournalDate = journalsMaxDate
        ? dayjs(journalsMaxDate)
        : dayjs("2000-01-01");
      const collapsedDate = dayjs(collapsedSection.collapsedDate);

      if (mostRecentJournalDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  }, [patientJournalTable]);

  const handleCollapseSection = () => {
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
    updateColapsedSections("journalsSection");
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <PageTitle>Journal</PageTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}
      <div
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <PatientJournalsTable
          isLoading={isFetching && !isRefetching}
          isError={isError}
          content={parsePatientJournalsToTable}
          highlightedRows={highlightedRows}
          refreshTable={handleRefreshTable}
        />
      </div>
    </>
  );
}
