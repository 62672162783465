export const spirometryMeasurementsValues = [
  "percent_predicted_fev1",
  "percent_predicted_fvc",
  "fev1",
  "fev25_75",
  "fvc",
  "fev1_fvc",
];

export const spirometryMeasurementsValuesFilters = ["fev1", "fvc"];

export const tooltipTitles: Map<string, string> = new Map([
  ["fev1", "Fev 1"],
  ["pulse_ox", "Pulse Ox"],
  ["temperature", "Temperature"],
  ["diarrhoea", "Diarrhoea"],
  ["heart_rate", "Heart Rate"],
  ["height", "Height"],
  ["weight", "Weight"],
  ["fev25_75", "Fev 25/75"],
  ["dyspnea", "Breathlessness"],
  ["bp_systolic", "Systolic"],
  ["bp_diastolic", "Diastolic"],
  ["hemoglobin", "Hemoglobin"],
  ["blood_glucose", "Blood Glucose"],
  ["cni_trough", "CNI Trough"],
  ["barthel_index", "Barthel Index"],
  ["rockwood_index", "Rockwood Index"],
  ["respiratory_rate", "Respiratory Rate"],
  ["oxygen_rate", "Oxygen Rate"],
  ["fvc", "FVC"],
  ["fev1_fvc", "FEV1/FVC"],
  ["creatinine", "Creatinine"],
  ["gfr", "GFR"],
  ["white_cells", "White Cells"],
  ["psa", "PSA"],
  ["phosphate", "Phosphate"],
  ["sputum_volume", "Sputum Volume"],
  ["calculated_predicted_fvc", "Predicted FVC"],
  ["calculated_predicted_fev1", "Predicted FEV1"],
  ["pef", "PEF"],
  ["fev6", "FEV6"],
  ["dry_weight", "Dry Weight"],
  ["crp", "CRP"],
  ["esr", "ESR"],
  ["-", "Value"],
]);

export const fibriCheckColorDictionary = {
  green: "success",
  blue: "info",
  orange: "tertiary",
  red: "error",
  grey: "warning",
};
