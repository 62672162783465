import { useIdleTimer } from "react-idle-timer";

interface IIdleTimeoutHookProps {
  onIdle: any;
  idleTime: number;
}

export function useIdleTimeout({
  onIdle,
  idleTime = 1,
}: IIdleTimeoutHookProps) {
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: () => {
      onIdle();
    },
    debounce: 500,
  });

  return {
    getRemainingTime,
    getLastActiveTime,
  };
}
