import { css, styled } from "@patientmpower/spiro";

// Components
export const MultiSelectContainer = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "20px",

  ".ant-select": {
    height: "42px !important",
  },
});

export const Option = styled("div", {
  position: "relative",
  height: "75px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const HeadersRow = styled("div", {
  height: "50px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const textInputCss = css({
  height: "100%",
  width: "60%",
  marginRight: "10px",
});

export const valueInputCss = css({
  height: "100%",
  width: "75px",
  marginRight: "10px",
});

export const RemoveButtonCss = css({
  height: "42px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    heigth: "42px",
    width: "20px",
    backgroundColor: "rgba(248, 248, 248, 0.8)",

    color: "black",
    borderColor: " transparent",

    "&:hover": {
      backgroundColor: "gray",
      color: "white",
      borderColor: " transparent",
    },
  },
});

export const AddOptionContainer = styled("div", {
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ConditionalHeaders = styled("div", {
  width: "210px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "bottom",
  marginRight: "10px",
});

export const LabelsHeader = styled("div", {
  width: "60%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "bottom",
  marginRight: "10px",
});

export const ValuesHeader = styled("div", {
  width: "75px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginRight: "10px",
});

export const ConditionalSelectContainer = styled("div", {
  height: "100%",
  marginRight: "10px",
});

export const RemoveButtonContainer = styled("div", {
  height: "100%",
});
