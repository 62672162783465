import { Table } from "@patientmpower/spiro";

import { IStaffTableData } from "../../../../../../@types/Staff";
import { TableSkeleton } from "../../../../../../components/TableSkeleton";
import { AddStaffTitle, TableContainer } from "./ManageStaffTable.styles";
import { manageStaffTableColumns } from "./manageStaffTableColumns";

type ManageStaffTableProps = {
  content: IStaffTableData[];
  isLoading: boolean;
  handleAddStaff: () => void;
  handleOnRowClick: (rowContent: IStaffTableData) => void;
};

export function ManageStaffTable({
  content,
  isLoading,
  handleAddStaff,
  handleOnRowClick,
}: ManageStaffTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={7} rowsNumber={4} />;
  }

  return (
    <TableContainer>
      <AddStaffTitle onClick={handleAddStaff}>Add staff</AddStaffTitle>
      <Table
        data={content}
        columns={manageStaffTableColumns}
        fontSize={14}
        onRowClick={handleOnRowClick}
        rowPadding
        rowPaddingRight="10px"
      />
    </TableContainer>
  );
}
