export function Dots({ ...rest }) {
  return (
    <svg
      {...rest}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 13.3332C9.08341 13.3332 8.33341 14.0832 8.33341 14.9998C8.33341 15.9165 9.08341 16.6665 10.0001 16.6665C10.9167 16.6665 11.6667 15.9165 11.6667 14.9998C11.6667 14.0832 10.9167 13.3332 10.0001 13.3332ZM10.0001 11.6665C10.9167 11.6665 11.6667 10.9165 11.6667 9.99984C11.6667 9.08317 10.9167 8.33317 10.0001 8.33317C9.08341 8.33317 8.33341 9.08317 8.33341 9.99984C8.33341 10.9165 9.08341 11.6665 10.0001 11.6665ZM11.6667 4.99984C11.6667 5.9165 10.9167 6.6665 10.0001 6.6665C9.08341 6.6665 8.33341 5.9165 8.33341 4.99984C8.33341 4.08317 9.08341 3.33317 10.0001 3.33317C10.9167 3.33317 11.6667 4.08317 11.6667 4.99984Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
